/* eslint-disable no-unused-vars */
import React, { Component } from "react";

// @material-ui/icons
import { Face, RecordVoiceOver } from "@material-ui/icons";
import classNames from "classnames";

// @material-ui/core components
import {
  withStyles,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import {
  dangerColor,
  successColor,
  defaultFont,
  grayColor
} from "../../../../assets/jss/material-dashboard-pro-react.jsx";
import { counties, states } from "../../../../variables/general";

const style = {
  ...customSelectStyle,
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "1.42857",
    top: 5
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Step2 extends Component {
  state = {
    city: "",
    cityState: "",
    state: "Kentucky",
    stateState: "",
    zip: "",
    zipState: "",
    county: "",
    countyState: "",
    street: "",
    streetState: ""
  };

  sendState() {
    return this.state;
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  verifyPassword = (value, confirmValue) => value === confirmValue;

  change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "confirmPass":
        if (this.verifyPassword(event.target.value, stateNameEqualTo)) {
          this.setState({
            passwordState: "success",
            confirmPasswordState: "success"
          });
        } else {
          this.setState({
            passwordState: "error",
            confirmPasswordState: "error"
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  isValidated = () => {
    const { streetState, countyState } = this.state;

    if (streetState === "success" && countyState === "success") {
      return true;
    } else {
      if (streetState !== "success") {
        this.setState({ streetState: "error" });
      }
      if (countyState !== "success") {
        this.setState({ countyState: "error" });
      }
    }
    return false;
  };

  nextStepHandler = (event) => {
    if (event.key === "Enter") {
      this.props.nextButtonClick();
    }
  };

  render() {
    const {
      streetState,
      cityState,
      zipState,
      county,
      countyState,
      state
    } = this.state;
    const { classes } = this.props;
    const error = countyState !== "success" && countyState !== "";
    const success = countyState === "success";
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let's start with the basic information (with validation)
          </h4>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={streetState === "success"}
            error={streetState === "error"}
            labelText={<span>Street Address</span>}
            id="street"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onKeyDown: this.nextStepHandler,
              onChange: (event) => this.change(event, "street", "length", 2),
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />

          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={12} sm={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose state
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={state}
                  onChange={(event) => this.change(event, "state")}
                  inputProps={{
                    name: "state"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose state
                  </MenuItem>
                  {states.map((state) => {
                    return (
                      <MenuItem
                        key={state}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={state}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.labelRoot + " " + labelClasses}
                >
                  Choose county
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={county}
                  onChange={(event) =>
                    this.change(event, "county", "length", 2)
                  }
                  inputProps={{
                    name: "county"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose county
                  </MenuItem>
                  {counties.map((county) => {
                    return (
                      <MenuItem
                        key={county}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={county}
                      >
                        {county}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={cityState === "success"}
                error={cityState === "error"}
                labelText={<span>City</span>}
                id="city"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) => this.change(event, "city", "length", 2),
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <RecordVoiceOver className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={zipState === "success"}
                error={zipState === "error"}
                labelText={<span>Zip</span>}
                id="zip"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) => this.change(event, "zip", "length", 4)
                }}
              />
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(Step2);
