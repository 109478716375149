/* eslint-disable no-unused-vars */
// @material-ui/core components
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import slideShowStyle from "../../../assets/jss/material-dashboard-pro-react/views/slideShowStyle.jsx";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Carousel from "../../../components/Carousel/Carousel.jsx";
import Slide from "react-reveal/Slide";

const SlideShow = ({ classes, slides }) => {
  const rendSlides =
    slides &&
    slides.map((src, i) => {
      return (
        <Slide key={i}>
          <img className={classes.img} src={src} alt="..." />
        </Slide>
      );
    });

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={10}>
        {slides && slides.length ? (
          <Carousel maxTurns={0}>{rendSlides}</Carousel>
        ) : (
          <div>Slide not found</div>
        )}
      </GridItem>
    </GridContainer>
  );
};

SlideShow.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(slideShowStyle)(SlideShow);
