/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import fetchUser from "../../../store/actions/fetchUser/fetchUser.js";
import setErrorStatus from "../../../store/actions/setErrorStatus/setErrorStatus.js";
import setNoteStatus from "../../../store/actions/setNoteStatus/setNoteStatus";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props.js";

// @material-ui/core components
import {
  withStyles,
  InputAdornment,
  Icon,
  Typography
} from "@material-ui/core";

// @material-ui/icons
import { Error, Email, Done } from "@material-ui/icons";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import SnackBar from "../../../components/Snackbar/Snackbar.jsx";
import AuthNavbar from "../../../components/Navbars/AuthNavbar.jsx";

import loginPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class LoginPage extends Component {
  state = {
    email: "",
    password: "",
    emailState: "",
    passwordState: ""
  };

  changeHandler = ({ target: { value, id } }) => {
    this.setState({ [id]: value });

    switch (id) {
      case "email":
        if (this.verifyEmail(value)) {
          this.setState({ emailState: "success"});
        } else {
          this.setState({ emailState: "error"});
        }
        break;
      case "password":
        if (this.verifyLength(value, 6)) {
          this.setState({ passwordState: "success"});
        } else {
          this.setState({ passwordState: "error"});
        }
        break;
    
      default:
        break;
    }

  };

  closeNote = () => this.props.setErrorStatus(false);

  closeRecoverNote = () => this.props.setNoteStatus(false);

  verifyEmail = value => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  loginHandler = () => {
    const { email, password, emailState, passwordState } = this.state;
    const { fetchUser } = this.props;

    if (!email) {
      this.setState({ emailState: "error"});
    }

    if (!password) {
      this.setState({ passwordState: "error"});
    }

    if (emailState === "success" && passwordState === "success") {
      fetchUser(email, password);
    } else {
      this.props.setErrorStatus(true);
    }
  };

  forgotHandler = () => {
    this.closeNote();
    this.props.history.push("/recover");
  };

  enterLoginHandler = ({ key }) => {
    if (key === "Enter") {
      this.loginHandler();
    }
  };

  responseFacebook = response => {
    console.log(response);
  };

  render() {
    const { classes, isError, history, isNote } = this.props;
    const { email, password, emailState, passwordState } = this.state;
    // const disabled =
    //   !this.verifyEmail(email) || !this.verifyLength(password, 3);

    return (
      <>
        <AuthNavbar brandText="Food Licensing" login />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <SnackBar
              message="Message reset email was send"
              close
              place="bl"
              icon={Done}
              color="success"
              open={isNote.status}
              autoHideDuration = {1800}
              closeNotification={this.closeRecoverNote}
            />
            <SnackBar
              message="Email or password is incorrect"
              open={isError.status}
              close
              autoHideDuration={1800}
              closeNotification={this.closeNote}
              place="bl"
              icon={Error}
              color="danger"
            />
            <GridContainer justify="center" className={classes.cardContainer}>
              <GridItem xs={12} sm={10} md={6} lg={4}>
                <Card login>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <h4 className={classes.cardTitle}>Login</h4>
                    {/*}<div className={classes.socialLine}>
                      <Button
                        color="transparent"
                        justIcon
                        onClick={event => {
                          event.preventDefault();
                          window.open(
                            "https://dd.lcdhd.org/api/public/facebook"
                          );
                        }}
                        className={classes.customButtonClass}
                      >
                        <i className="fab fa-facebook-square" />
                      </Button>

                      {["fab fa-twitter", "fab fa-google"].map((prop, key) => {
                        return (
                          <Button
                            color="transparent"
                            justIcon
                            key={key}
                            className={classes.customButtonClass}
                          >
                            <i className={prop} />
                          </Button>
                        );
                      })}
                    </div>*/}
                  </CardHeader>
                  {/*<div
                    className={`${classes.cardParapgraph} ${
                      classes.textCenter
                    }`}
                  >
                    Or Be Classical{" "}
                  </div>*/}
                  <CardBody>
                    <form>
                      <CustomInput
                        success={emailState === "success"}
                        error={emailState === "error"}
                        labelText="Email address"
                        helpText="Please enter a valid email address"
                        helpTextClass={true}
                        id="email"
                        changeHandler={this.changeHandler}
                        onKeyDown={this.enterLoginHandler}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          type: "email",
                          value: email,
                          onKeyDown: this.enterLoginHandler
                        }}
                      />
                      <CustomInput
                        success={passwordState === "success"}
                        error={passwordState === "error"}
                        helpText = "Your password must be at least 6 characters long."
                        helpTextClass={true}
                        labelText="Password"
                        id="password"
                        changeHandler={this.changeHandler}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          type: "password",
                          value: password,
                          onKeyDown: this.enterLoginHandler
                        }}
                      />
                    </form>
                  </CardBody>
                  <CardFooter className={classes.dirCol}>
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      // disabled={disabled}
                      onClick={this.loginHandler}
                      block
                    >
                      Let's Go
                    </Button>
                    {/*<Button*/}
                    {/*  className={classes.regOnMobile}*/}
                    {/*  color="info"*/}
                    {/*  simple*/}
                    {/*  size="lg"*/}
                    {/*  disabled={disabled}*/}
                    {/*  onClick={() => history.push("/register")}*/}
                    {/*>*/}
                    {/*  Register*/}
                    {/*</Button>*/}
                    <Button size="sm" onClick={this.forgotHandler}>
                      Forgot your password?
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ isError, isNote }) => {
  return {
    isError,
    isNote
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { fetchUser, setErrorStatus, setNoteStatus }
  )(withStyles(loginPageStyle)(LoginPage))
);
