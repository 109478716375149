import { combineReducers } from "redux";
import auth from "../auth/auth";
import licenses from "../licenses/licenses";
import isCollapse from "../isCollapse/isCollapse";
import isError from "../isError/isError";
import adminLicenses from "../adminLicenses/adminLicenses";
import isRregistrationError from "../isRegistrationError/isRregistrationError";
import isNote from "../isNote/isNote";

export default combineReducers({
  auth,
  licenses,
  isCollapse,
  isError,
  adminLicenses,
  isRregistrationError,
  isNote
});
