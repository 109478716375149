import React, { Component } from "react";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import CardText from "../../../components/Card/CardText";
import { VerifiedUser, Warning } from "@material-ui/icons";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import Button from "../../../components/CustomButtons/Button";
import axios from "axios";
import API from "../../../api";
import DashboardCard from "../../Auth/Pills/DashboardPill/DashboardCard/DashboardCard";
import moment from "moment";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";

class Verification extends Component {
  state = {
    licIDState: "",
    licID: "",
    verifyLicenseData: {},
    errMsg: ""
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    if (params.id) {
      this.verifyHandler(params.id);
      this.setState({ licID: params.id, licIDState: "success" });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.errMsg) {
      this.turnOffRedTimeout = setTimeout(() => {
        this.setState(() => ({ errMsg: "" }));
      }, 2500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.turnOffRedTimeout);
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  change = ({ target }) =>
    this.setState({ licID: target.value, licIDState: "success" });

  verifyHandler = async licID => {
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await API.get(`admin/verify/${licID}`, {
        cancelToken: this.cancelTokenSource.token,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      if (data) {
        this.setState({ verifyLicenseData: data });
      } else {
        this.setState({
          errMsg: "Something went wrong. Type a valid id and try again!",
          licIDState: "error",
          verifyLicenseData: {}
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error("(: Individual request cancel :)");
      }
      this.setState({
        errMsg: "Something went wrong. Type a valid id and try again!",
        licIDState: "error",
        verifyLicenseData: {}
      });
    } finally {
      this.cancelTokenSource = null;
    }
  };

  formatDate = date => moment(date).format("MMM DD YYYY");

  submitEnter = event => {
    if (event.key === "Enter") {
      this.verifyHandler(this.state.licID.replace(/\D+/g, ""));
    }
  };

  render() {
    const { licID, verifyLicenseData, errMsg } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon text>
              <CardIcon color="success">
                <VerifiedUser />
              </CardIcon>
              <CardText>License Verification</CardText>
            </CardHeader>
            <CardBody>
              <GridContainer
                style={{ alignItems: "flex-start", minHeight: 500 }}
                justify="space-between"
              >
                <GridItem xs={12} sm={12} md={5} c="searchCont">
                  <InputMask
                    mask="99-99-999"
                    value={licID}
                    onChange={this.change}
                  >
                    {() => (
                      <TextField
                        id="licID"
                        type="text"
                        ref="phone"
                        name="phone"
                        value={licID}
                        inputProps={{
                          onKeyDown: this.submitEnter
                        }}
                        onChange={this.change}
                      />
                    )}
                  </InputMask>
                  {/*<CustomInput*/}
                  {/*  success={licIDState === "success"}*/}
                  {/*  error={licIDState === "error"}*/}
                  {/*  labelText={<span>License ID (15-p1-10Q)</span>}*/}
                  {/*  id="licID"*/}
                  {/*  inputProps={{*/}
                  {/*    onKeyDown: this.submitEnter,*/}
                  {/*    value: licID,*/}
                  {/*    onChange: this.change,*/}
                  {/*    autoComplete: "off"*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Button
                    color="success"
                    onClick={() =>
                      this.verifyHandler(licID.replace(/\D+/g, ""))
                    }
                  >
                    SUBMIT
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  {Object.keys(verifyLicenseData).length ? (
                    <DashboardCard
                      author={`${verifyLicenseData.firstName} ${
                        verifyLicenseData.lastName
                      }`}
                      purchaseDate={this.formatDate(
                        verifyLicenseData.createdAt
                      )}
                      cost={verifyLicenseData.cost}
                      licenseTitle={verifyLicenseData.licenseName}
                      completed
                      progress={100}
                      QRvalue={verifyLicenseData.transactionId}
                      expDate={this.formatDate(verifyLicenseData.expDate)}
                      badgeContent={verifyLicenseData.currentId}
                    />
                  ) : errMsg ? (
                    <SnackbarContent
                      message={errMsg}
                      icon={Warning}
                      color="warning"
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default Verification;
