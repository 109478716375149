const carouselStyles = theme => ({
  container: {
    position: "relative"
  },
  welcomeTitle: {
    textAlign: "center",
    fontSize: 36,
    margin: 0,
    marginBottom: 185,
    [theme.breakpoints.down(992)]: {
      fontSize: 28,
      paddingBottom: 28
    }
  },
  slideBtn: {
    zIndex: 100,
    // position: "absolute",
    bottom: 0,
    // left: "50%",
    cursor: "pointer",
    userSelect: "none",
    [theme.breakpoints.down(992)]: {
      padding: "8px 16px"
    }
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-around"
  },
  leftBtn: {
    // left: "0%"
  },
  rightBtn: {
    // left: "89%"
  },
  topInfTxt: {
    fontSize: 16,
    fontWeight: "bold",
    [theme.breakpoints.down(992)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(578)]: {
      fontSize: 13
    },
    [theme.breakpoints.down(478)]: {
      fontSize: 12
    }
  },
  topInfCont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 600,
    margin: "0 auto"
  },
  startBtn: {
    padding: "0 58px",
    height: 40,
    margin: 0,
    marginTop: 130,
    marginBottom: 40
  },
  currentSlide: {
    width: "100%",
    boxShadow:
      "0 -1px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    height: 400,
    margin: "0 auto",
    display: "block",
    overflow: "hidden",
    maxWidth: 750,
    [theme.breakpoints.down(992)]: {
      height: 320
    },
    [theme.breakpoints.down(578)]: {
      height: 270
    },
    [theme.breakpoints.down(478)]: {
      height: 210
    },
    [theme.breakpoints.down(378)]: {
      height: 170
    }
  },
  logoStart: {
    [theme.breakpoints.down(992)]: {
      width: 50
    },
    [theme.breakpoints.down(768)]: {
      width: 50
    }
  }
});

export default carouselStyles;
