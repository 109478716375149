import API from "../../../api";
import dispatchLicenses from "../dispatchLicenses/dispatchLicenses";

const fetchLicence = (token) => {
    return async dispatch => {
      try {
        const { data } = await API.get(`license/`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        dispatch(dispatchLicenses(data));
      } catch (e) {
        console.error(e);
      }
    }
};

export default fetchLicence;
