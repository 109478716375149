import { SET_ERROR_STATUS } from '../../actions/actionTypes/actionTypes';

const initialState = {
    status: false
};

const isError = (state = initialState, { type, status }) => {
  
    switch (type) {
        case SET_ERROR_STATUS:
            return {
                ...state,
                status
            };
        default:
            return state;
    }
}

export default isError;