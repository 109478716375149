import API from "../../../api";

const deleteLicense = (licenseId, token) => (
    async () => {
      try {
        await API.delete(`license/${licenseId}`, {
            headers: {'Authorization': `Bearer ${token}`}
        });
      } catch (e) {
        console.error(e);
      }
    }
);

export default deleteLicense;
