import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { withStyles, Snackbar as Snack, IconButton } from "@material-ui/core";
import Button from "../CustomButtons/Button";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import snackbarContentStyle from "../../assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.jsx";

const Snackbar = ({ ...props }) => {
  const {
    classes,
    message,
    buttonText,
    autoHideDuration,
    color,
    close,
    icon,
    buttonClick,
    place,
    open,
    closeNotification,
    buttonProps
  } = props;
  let action = [];
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={closeNotification}
      >
        <Close className={classes.close} />
      </IconButton>
    ];
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === "info",
    [classes.successIcon]: color === "success",
    [classes.warningIcon]: color === "warning",
    [classes.dangerIcon]: color === "danger",
    [classes.primaryIcon]: color === "primary",
    [classes.roseIcon]: color === "rose"
  });
  return (
    <Snack
      autoHideDuration={autoHideDuration || null}
      onClose={closeNotification}
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40,
        anchorOriginBottomLeft: classes.snackBtm
      }}
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          place.indexOf("l") !== -1
            ? "left"
            : place.indexOf("c") !== -1
            ? "center"
            : "right"
      }}
      open={open}
      message={
        <div>
          {icon !== undefined ? <props.icon className={iconClasses} /> : null}
          <span className={messageClasses}>{message}</span>
          {buttonText && (
            <div className={classes.buttonContainer}>
              <Button onClick={buttonClick} {...buttonProps} color="info">
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message
        }
      }}
    />
  );
};

Snackbar.defaultProps = {
  color: "info"
};

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "rose"
  ]),
  close: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  icon: PropTypes.func,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool
};

export default withStyles(snackbarContentStyle)(Snackbar);
