import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import GridContainer from "../../../components/Grid/GridContainer";
import ReceiptItem from "./ReceiptItem/ReceiptItem";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import receiptPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/receiptPageStyle";
import ReceiptPopup from "../../../components/ReceiptPopup/ReceiptPopup";


class Receipt extends Component {
  state = {
    rec: [
      {
        idPay: "1529-8695", 
        from: "Lake Cumberland District Health Department",
        paid: 80,
        date: "05/05/2018",
        method: "7889",
        certificate: "Food Employee dsds sfsf sds(Handler)"
      },
      {
        idPay: "1929-8495", 
        from: "Lake Cumberland District Health Department",
        paid: 60,
        date: "12/05/2018",
        method: "7889",
        certificate: "Food Manager"
      },
      {
        idPay: "5529-8995", 
        from: "Lake Cumberland District Health Department",
        paid: 80,
        date: "01/02/2018",
        method: "7889",
        certificate: "Food Employee  sfs  ssdssds (Handler)"
      },
      {
        idPay: "6229-8655", 
        from: "Lake Cumberland District Health Department",
        paid: 20,
        date: "26/08/2017",
        method: "7889",
        certificate: "Food Manager"
      },
      {
        idPay: "1529-8695", 
        from: "Lake Cumberland District Health Department",
        paid: 80,
        date: "08/05/2016",
        method: "7889",
        certificate: "Food Employee (Handler)"
      }
    ],
    isPopupOpen: false,
    viewPaid: {}
  }

  handleOpen = (e, key) => {
    this.setState({
      isPopupOpen: true,
      viewPaid: this.state.rec[key]
    });
  }

  handleClose = () => {
    this.setState({isPopupOpen: false});
  }

  render() {
    const {
      classes
    } = this.props;

    const {
      rec,
      isPopupOpen,
      viewPaid
    } = this.state;
    
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.receiptCard}>
              <h2 className={classes.receiptTitle}>Receipts</h2>
              <GridContainer className={classes.receiptWrapper}>
                {
                  rec.map((prop, key) => {
                    return (
                      <GridItem key={key} xs={12} sm={6} md={4} lg={3} xl={3}>
                        <ReceiptItem 
                          certificateItem={prop} 
                          handleOpenClick = {(e)=>{this.handleOpen(e, key)}} 
                        />
                      </GridItem> 
                    );
                })
                }
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
        <ReceiptPopup 
          open={isPopupOpen} 
          handleCloseClick = {this.handleClose}
          viewPaid={viewPaid}
        />
      </>
    );
  }
}


export default withStyles(receiptPageStyle)(Receipt);