const getLicenseStyle = theme => ({
  card: {
    width: "100%",
    padding: 15,
    display: "flex",
    flexDirection: "column",
    borderTop: "3px solid #4cd9ea",
    boxShadow: "0px 1px 4px rgba(0,0,0,0.14)"
  },
  chkbLabel: {
    alignItems: "flex-end",
    marginTop: 8
  },
  currSlideImg: {
    cursor: "pointer",
    width: "100%",
    height: "100%"
  },
  chkb: {
    paddingBottom: 0,
    paddingTop: 5
  },
  printCont: {
    height: 42,
    margin: "25px 0"
  },
  cardContainer: {
    margin: 0,
    height: "100%"
  },
  getLicenseHead: {
    margin: "20px 0",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 700px)": {
      flexDirection: "column",
      justifyContent: "center",
      "& > *:nth-child(2)": {
        width: "100%"
      }
    }
  },
  licenseRCode: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media (max-width: 700px)": {
      justifyContent: "space-between"
    }
  },
  completedBtn: {
    width: 81,
    color: "#000",
    backgroundColor: "#EEEEEE",
    textTransform: "capitalize",
    "&:hover": {
      color: "#000",
      backgroundColor: "#EEEEEE"
    }
  },
  dwldBtnCont: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 180,
    width: "100%"
  },
  dwldBtn: {
    width: 81,
    textTransform: "capitalize"
  },
  centerElem: {
    display: "flex",
    // width: "100%",
    // height: 30,
    padding: 0
  },
  lcdT: {
    transform: "rotate(45deg)",
    position: "absolute",
    top: 20
  },
  getLCD: {
    marginTop: 10
  },
  qrCode: {
    width: "72px !important",
    height: "72px !important"
  },
  titleL: {
    fontSize: 21,
    fontWeight: "300",
    color: "#3c4858",
    lineHeight: "1",
    textAlign: "left",
    [theme.breakpoints.down(1200)]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      // whiteSpace: "nowrap"
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 14
    }
  },
  dashboardCard: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    [theme.breakpoints.down(567)]: {
      padding: 5
    }
  },
  loader: {
    color: "#00acc1"
  },
  sectionL: {
    fontSize: 13,
    marginRight: 7,
    whiteSpace: "nowrap",
    fontWeight: "300",
    letterSpacing: "initial",
    color: "#3c4858",
    margin: 0,
    [theme.breakpoints.down(1200)]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12,
      margin: "10px 0"
    }
  },
  icons: {
    width: "17px",
    height: "17px"
  },
  normBtn: {
    textTransform: "capitalize"
  },
  getCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: "transparent",
    padding: 70,
    height: "100%",
    textAlign: "center",
    cursor: "pointer",
    border: "1px solid #E1E1E1",
    boxShadow: "0px 1px 4px rgba(0,0,0,0.14)"
  },
  slidesL: {
    fontSize: 14,
    margin: 0,
    color: "#3c4858",
    [theme.breakpoints.down(1200)]: {
      fontSize: 13,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 12
    },
    [theme.breakpoints.down(450)]: {
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  topTxt: {
    fontSize: 14,
    fontFamily: "Roboto Medium",
    color: "#AAAAAA",
    borderBottom: "1px solid #D2D2D2",
    marginBottom: 3,
    [theme.breakpoints.down(600)]: {
      fontSize: 12
    },
    [theme.breakpoints.down(478)]: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  priceALicence: {
    paddingBottom: 3,
    marginBottom: 5,
    borderBottom: "1px solid #D2D2D2",
    width: "75%",
    [theme.breakpoints.down(478)]: {
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  payBtn: {
    margin: 0
  },
  fab: {
    padding: "0 40px"
  },
  licenseTitle: {
    padding: 30,
    color: "#fff"
  },
  cardPrice: {
    paddingBottom: 0,
    paddingTop: 30
  },
  infoTitles: {
    fontSize: 14,
    fontWeight: "500",
    color: "#555555",
    marginBottom: 35,
    [theme.breakpoints.down(600)]: {
      display: "none"
    }
  },
  cAndL: {
    width: "75%"
  },
  amount: {
    width: "20%"
  },
  noBorder: {
    border: "0",
    margin: "0"
  },
  detailT: {
    fontSize: 14,
    fontWeight: "500",
    color: "#555555",
    marginTop: 40,
    [theme.breakpoints.down(960)]: {
      marginTop: 20
    }
  },
  lcardContainer: {
    maxWidth: "50%",
    minHeight: 180,
    direction: "ltr",
    margin: 0,
    padding: "0 15px",
    marginBottom: 20,
    [theme.breakpoints.down(960)]: {
      maxWidth: "100%",
      padding: 0
    }
  }
});

export default getLicenseStyle;
