import API from "../../../api";
import dispatchUser from "../dispatchUser/dispatchUser";

const getUser = () => {
  const { authToken, id } = localStorage;

  return async dispatch => {
    try {
      const { data } = await API.get(`user/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });

      dispatch(dispatchUser(data));
    } catch (error) {
      console.error(error);
    }
  };
};

export default getUser;
