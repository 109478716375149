/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { withStyles } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import SlideShow from "../SlideShow/SlideShow.jsx";
import Quiz from "../Lecture/Quiz.jsx";
import congratIcon from "../../../assets/img/congratIcon.svg";
import registerPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import overviewCourse from "../../../store/actions/overviewCourse/overviewCourse";

class OverviewCourse extends Component {
  state = {
    dropValue: "Download Certificate"
  };

  dropDownHandler = param => {
    switch (param) {
      case "returnDashboard":
        this.handleClick();
        break;
      default:
        break;
    }
  };

  handleClick = () => {
    this.props.overviewCourse(false, {}, false);
  };

  render() {
    const { dropValue } = this.state;
    const { isStartQuiz, currentLicense, isFinish, classes } = this.props;

    const { possibleSlides, name } = currentLicense;

    return (
      <GridItem xs={12} md={12}>
        {!isFinish ? (
          isStartQuiz ? (
            <Quiz />
          ) : (
            <SlideShow slides={possibleSlides} />
          )
        ) : null}
        {isFinish && (
          <div className={classes.congratsContainer}>
            <h2>
              Congrats
              <span
                className="emoji"
                role="img"
                aria-label=""
                aria-hidden="false"
              >
                🎊
              </span>
              <span
                className="emoji"
                role="img"
                aria-label=""
                aria-hidden="false"
              >
                🎉
              </span>
              <span
                className="emoji"
                role="img"
                aria-label=""
                aria-hidden="false"
              >
                🙌
              </span>
            </h2>
            <div className={classes.congratsBottom}>
              <img src={congratIcon} alt="congrats_icon" />
              <h3 className={classes.center}>
                You have finished the {name} and certification{" "}
              </h3>
              <CustomButton
                onClick={this.handleClick}
                className={classes.finshBtn}
                round
                color="transparent"
              >
                FINISHED
              </CustomButton>
            </div>
          </div>
        )}
        {/*
        return (
          <GridItem md={6} c="section" key={index}>
            <h4>
              <CheckCircle style={{ fill: "#66bb6a" }} />
              Section {section.section_number} - {section.sectionName}
            </h4>
            <h5>1. Employee Personal Hygiene</h5>
            <h5>2. Employee cleanliness</h5>
            <h5>3. Proper work attire</h5>
          </GridItem>
        );
      })
    */}
      </GridItem>
    );
  }
}

const mapStateToProps = ({ licenses }) => {
  return {
    currentLicense: licenses.currentLicense,
    isStartQuiz: licenses.isStartQuiz
  };
};

const mapDispatchToProps = {
  overviewCourse
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(OverviewCourse));
