import { GET_USER, USER_REGISTER, COMPLETE_REG_PROGRESS } from "../../actions/actionTypes/actionTypes";

const initialState = {
  user: {},
  isRegister: false,
  completeProgress: false
};

const auth = (state = initialState, action) => {
  const { type, user, isRegister, completeProgress } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        user
      };
    case COMPLETE_REG_PROGRESS:
      return {
        ...state,
        completeProgress
      };
    case USER_REGISTER:
      return {
        ...state,
        isRegister
      };
    default:
      return state;
  }
};

export default auth;
