import React from "react";
import ChartistGraph from "react-chartist";
import Card from "../../../../components/Card/Card.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import CardBody from "../../../../components/Card/CardBody.jsx";
import CardFooter from "../../../../components/Card/CardFooter.jsx";
import CardHeader from "../../../../components/Card/CardHeader.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import { dailySalesChart } from "../../../../variables/charts.jsx";

const GraphCharts = ({ classes, licenseAnalythics, usersRegistered }) => {
  //const { data: licData } = licenseAnalythics;
  //const { data: regData } = usersRegistered;

  return (
    <GridContainer style={{ justifyContent: "center" }}>
      <GridItem xs={12} sm={12} md={6}>
        <Card chart className={classes.cardHover}>
          <CardHeader color="success" className={classes.cardHeaderHover}>
            <ChartistGraph
              className="ct-chart-white-colors"
              data={{
                labels: ["Week 4", "Week 3", "Week 2", "Week 1"],
                series: [
                  [
                    // licData[3].total,
                    // licData[2].total,
                    // licData[1].total,
                    // licData[0].total
                    10,
                    20,
                    30,
                    40,
                  ],
                ],
              }}
              tooltip
              type="Line"
              options={dailySalesChart.options}
            />
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardTitle}>License Sales</h4>
            <p className={classes.cardCategory}>
              Licenses sold in the previous 4 weeks.
            </p>
          </CardBody>
          <CardFooter chart>
            <div className={classes.stats}>
              Automatically updates all data once a week
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card chart className={classes.cardHover}>
          <CardHeader color="info" className={classes.cardHeaderHover}>
            <ChartistGraph
              className="ct-chart-white-colors"
              data={{
                labels: ["Week 4", "Week 3", "Week 2", "Week 1"],
                // series: [[regData[3], regData[2], regData[1], regData[0]]],
                series: [[20, 30, 40, 10]],
              }}
              tooltip
              type="Line"
              options={dailySalesChart.options}
            />
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardTitle}>User Registrations</h4>
            <p className={classes.cardCategory}>
              Users registered in the previous 4 weeks
            </p>
          </CardBody>
          <CardFooter chart>
            <div className={classes.stats}>
              Automatically updates all data once a week
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default GraphCharts;
