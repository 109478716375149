import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
} from "../../material-dashboard-pro-react.jsx";

const cardTextStyle = {
  cardText: {
    float: "none",
    display: "inline-block",
    marginRight: "0",
    borderRadius: "3px",
    backgroundColor: "transparent",
    color: "#555555",
    fontSize: 19,
    paddingLeft: "0 !important",
    padding: "15px"
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
};

export default cardTextStyle;
