import { cardTitle } from "../../material-dashboard-pro-react.jsx";
import customSelectStyle from "../customSelectStyle.jsx";

const adminUserStyle = {
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  licenseItem: {
    display: "flex",
    alignItems: "center",
    margin: 10,
    borderRadius: 20,
    padding: 10,
    "& button,& p": {
      margin: 0,
      padding: 0
    },
    "& button": {
      display: "flex",
      justifyContent: "flex-start"
    }
  },
  tableContainer: {
    width: "100%",
    border: "none",
    "& div": {
      boxShadow: "none !important",
      textAlign: "left !important"
    },
    "& .rt-tbody": {
      "& .rt-tr-group": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.22)"
      },
      "& .rt-tr-group:nth-child(odd)": {
        background: "rgba(0, 0, 0, 0.05)"
      },
      "& .rt-tr-group:nth-child(even)": {
        background: "#FFF"
      }
    },
    "& .rt-tfoot": {
      borderTop: "1px solid rgba(0, 0, 0, 0.05)",
      "& .rt-td": {
        borderRight: "none"
      }
    }
  },
  formControl: {
    display: "flex",
    flexDirection: "row"
  },
  topSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start"
  },
  selectCont: {
    maxWidth: 100,
    margin: "0 5px",
    "&:before": {
      borderBottom: "1px solid #D2D2D2"
    }
  },
  searchCont: {
    maxWidth: 400,
    width: "100%"
  },
  perPageSection: {
    display: "flex",
    maxWidth: 200,
    width: "100%",
    marginRight: 30,
    alignItems: "flex-end",
    "& p": {
      margin: "8px 0",
      color: "#495057"
    }
  },
  loader: {
    marginTop: 50
  },
  statusWrapper: {
    display: "flex",
    alignItems: "center"
  },
  statusText: {
    backgroundColor: "#949494",
    color: "white",
    lineHeight: "15px",
    borderRadius: 5,
    padding: "0 5px"
  },
  actions: {
    display: "flex",
    justifyContent: "center"
  }
  
};

export default adminUserStyle;
