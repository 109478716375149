/* eslint-disable no-unused-vars */
// @material-ui/core components
import { withStyles } from "@material-ui/core";

import { KeyboardArrowLeft } from "@material-ui/icons";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import registerPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import NavPills from "../../../components/NavPills/NavPills.jsx";
import overviewCourse from "../../../store/actions/overviewCourse/overviewCourse";
import setSlideShowStatus from "../../../store/actions/setSlideShowStatus/setSlideShowStatus";
import setPillIndex from "../../../store/actions/setCollapseStatus/setCollapseStatus";
import startQuiz from "../../../store/actions/startQuiz/startQuiz";
import getUser from "../../../store/actions/getUser/getUser";

import OverviewCourse from "../OverviewCourse/OverviewCourse.jsx";

import AccountPill from "../Pills/AccountPill/AccountPill.jsx";
import DashboardPill from "../Pills/DashboardPill/DashboardPill.jsx";
import GetLicensePill from "../Pills/GetLicensePill/GetLicensePill.jsx";

import SlideShow from "../SlideShow/SlideShow.jsx";
import Quiz from "../Lecture/Quiz.jsx";

class SecondDash extends Component {
  // componentWillUnmount() {
  //   const { overviewCourse, setSlideShowStatus, startQuiz } = this.props;
  //   // overviewCourse(false);
  //   // setSlideShowStatus(false);
  //   // startQuiz(false);
  // }

  cancelHandler = () => {
    const { overviewCourse, startQuiz, getUser } = this.props;
    getUser();
    overviewCourse(false);
    startQuiz(false);
  };

  render() {
    const {
      classes,
      isStartGetLicense,
      isConfirmPay,
      isStartSlideShow,
      setSlideShowStatus,
      isOverviewCourse,
      isStartQuiz,
      startQuiz,
      currentLicense,
      isCongrats
    } = this.props;

    return (
      <GridContainer justify="center" className={classes.sdContainer}>
        <GridItem xs={12} sm={12} md={12} c="dashCardCont">
          <Card
            className={`${classes.cardSignup} ${
              isOverviewCourse || isCongrats ? classes.whiteBg : ""
            }`}
          >
            {(isOverviewCourse || isCongrats) && (
              <div className={classes.overviewHeader}>
                {!isStartSlideShow ? (
                  <Button color="github" onClick={this.cancelHandler} simple>
                    <KeyboardArrowLeft className={classes.icons} />
                    Dashboard
                  </Button>
                ) : isStartQuiz ? (
                  <Button onClick={() => startQuiz(false)}>
                    <KeyboardArrowLeft className={classes.icons} /> Back
                  </Button>
                ) : (
                  <h5
                    className={classes.exitTxt}
                    onClick={() => setSlideShowStatus(false)}
                  >
                    Exit slideshow
                  </h5>
                )}
                <div className={classes.titleContainer}>
                  {/*}<h1>
                    {`${currentLicenseName} `}
                    Course
                  </h1>*/}
                  {currentLicense.hasOwnProperty("score") ? (
                    <h4
                      style={{
                        color:
                          parseInt(currentLicense.score) > 5 ? "#000" : "#000"
                          // parseInt(currentLicense.score) > 5 ? "green" : "red"
                      }}
                    >
                      {parseInt(currentLicense.score)}%
                      {/*/{currentLicense.scoreToPass}*/}
                      <br />
                      {currentLicense.message}
                    </h4>
                  ) : null}
                  {/*!isStartSlideShow && (
                    <h4>
                      Here is a general overview and summary of the course
                    </h4>
                  )*/}
                </div>
              </div>
            )}

            <h2 className={classes.cardTitle}>
              {isConfirmPay
                ? isStartGetLicense
                  ? "Payment"
                  : "Confirm Purchase"
                : isOverviewCourse
                ? ""
                : isCongrats
                ? ""
                : "Dashboard"}
            </h2>
            <CardBody className={classes.bodyContainer}>
              <GridContainer justify="center">
                {isOverviewCourse ? (
                  !isStartSlideShow ? (
                    <OverviewCourse sections={currentLicense.sections} />
                  ) : isStartQuiz ? (
                    <Quiz />
                  ) : (
                    <>
                      {/*currentLicense.sections.map((section, i) => (
                           <SlideShow slides={section.slides} key={i} />
                        ))*/}
                      <SlideShow slides={[]} />
                    </>
                  )
                ) : isCongrats ? (
                  <OverviewCourse isFinish />
                ) : (
                  <NavPills
                    color="rose"
                    isDashboard
                    horizontal={{
                      tabsGrid: { xs: 12, sm: 12, md: 3 },
                      contentGrid: { xs: 12, sm: 12, md: 9 }
                    }}
                    tabs={[
                      {
                        tabButton: "Dashboard",
                        tabContent: <DashboardPill />
                      },
                      {
                        tabButton: "Get Licenses",
                        tabContent: <GetLicensePill />
                      },
                      {
                        tabButton: "Account",
                        tabContent: <AccountPill />
                      }
                    ]}
                  />
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

SecondDash.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    licenses: state.licenses.licenses,
    isStartGetLicense: state.licenses.isStartGetLicense,
    isConfirmPay: state.licenses.isConfirmPay,
    isStartSlideShow: state.licenses.isStartSlideShow,
    currentLicense: state.licenses.currentLicense,
    isOverviewCourse: state.licenses.isOverviewCourse,
    isCongrats: state.licenses.isCongrats,
    currentLicenseName: state.licenses.currentLicenseName,
    isStartQuiz: state.licenses.isStartQuiz
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { setSlideShowStatus, overviewCourse, setPillIndex, startQuiz, getUser }
  )(withStyles(registerPageStyle)(SecondDash))
);
