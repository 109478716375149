import React from "react";

import { withStyles, Typography } from "@material-ui/core";
import GridItem from "../../../../components/Grid/GridItem.jsx";

import getLicenseStyle from "../../../../assets/jss/material-dashboard-pro-react/views/getLicenseStyle";

const ConfirmPayment = ({ licenseTitle, licensePrice, classes }) => {
  return (
    <GridItem xs={12} sm={12} c="amountS">
      <GridItem xs={12} sm={9} c="twoInlineCont">
        <Typography
          color="textSecondary"
          align="left"
          className={`${classes.topTxt} ${classes.cAndL}`}
        >
          COURSE AND LICENSE
        </Typography>
        <Typography
          color="textSecondary"
          align="center"
          className={`${classes.topTxt} ${classes.amount}`}
        >
          AMOUNT
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={9} c="twoInlineCont">
        <Typography
          color="textSecondary"
          align="left"
          className={`${classes.sectionL} ${classes.priceALicence}`}
        >
          {licenseTitle}
        </Typography>
        <Typography
          color="textSecondary"
          align="center"
          className={`${classes.sectionL} ${classes.amount} ${
            classes.priceALicence
          }`}
        >
          ${licensePrice}
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={9} c="twoInlineCont">
        <Typography
          color="textSecondary"
          align="left"
          className={classes.slidesL}
        >
          {licenseTitle}
        </Typography>
        <div className={classes.amount}>
          <Typography
            color="textSecondary"
            align="center"
            className={`${classes.topTxt} ${classes.noBorder}`}
          >
            TOTAL
          </Typography>
          <Typography
            color="textSecondary"
            align="center"
            className={`${classes.sectionL}`}
          >
            ${licensePrice}
          </Typography>
        </div>
      </GridItem>
    </GridItem>
  );
};

export default withStyles(getLicenseStyle)(ConfirmPayment);
