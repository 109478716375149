import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import setPillIndex from "../../../../../store/actions/setCollapseStatus/setCollapseStatus";

// import Pdf from "react-to-pdf";
import QRCode from "qrcode.react";
import { Add } from "@material-ui/icons";
import ReactToPrint from "react-to-print";
import { CSSTransition } from "react-transition-group";
import Badge from "../../../../../components/Badge/Badge.jsx";
import GridItem from "../../../../../components/Grid/GridItem.jsx";
import Button from "../../../../../components/CustomButtons/Button.jsx";
import { Card, CardActions, Typography, withStyles } from "@material-ui/core";
import CustomLinearProgress from "../../../../../components/CustomLinearProgress/CustomLinearProgress.jsx";

import getLicenseStyle from "../../../../../assets/jss/material-dashboard-pro-react/views/getLicenseStyle";
import Certificate from "../../../../../components/Certificate/Certificate";
// import Dialog from "@material-ui/core/Dialog";
// import {
//   DialogActions,
//   DialogContent,
// } from "../../../../../components/Dialog/Dialog";
// import Slide from "@material-ui/core/Slide";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

class DashboardCard extends Component {
  state = {
    isBack: false,
    open: false,
    certRef: null
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  flipHandler = () => {
    this.setState(prevState => ({
      isBack: !prevState.isBack
    }));
  };

  render() {
    const { isBack } = this.state;
    const {
      classes,
      click,
      disabled,
      progress,
      badgeContent,
      sections,
      licenseTitle,
      getLicense,
      setPillIndex,
      slides,
      slide,
      purchaseDate,
      cost,
      expDate,
      certDescription,
      user,
      QRvalue,
      completed,
      author
    } = this.props;
    const lineColor = completed ? "success" : "info";
    const name = author ? author : `${user.firstName} ${user.lastName}`;

    return !getLicense ? (
      <>
        <CustomLinearProgress
          variant="determinate"
          color={lineColor}
          value={progress}
        />
        <CSSTransition classNames="flipper" in={isBack} timeout={300}>
          <Card className={classes.dashboardCard + " " + classes.card}>
            <GridItem xs={12} sm={12} c="cHeader">
              <GridItem xs={7} sm={7}>
                <Typography
                  color="textSecondary"
                  align="center"
                  className={classes.titleL}
                  gutterBottom
                >
                  {completed && isBack ? "Details" : licenseTitle}
                </Typography>
                {completed && isBack && (
                  <Typography
                    color="textSecondary"
                    align="left"
                    className={classes.sectionL}
                    gutterBottom
                  >
                    {licenseTitle}
                  </Typography>
                )}
              </GridItem>
              <GridItem xs={5} sm={5} c="startLicense">
                <Badge color={lineColor}>{badgeContent}</Badge>
              </GridItem>
            </GridItem>
            <div className={classes.printCont}>
              {completed && isBack && (
                <GridItem xs={12} sm={7} c="nsItem">
                  <CardActions className={classes.centerElem}>
                    <Typography
                      color="textSecondary"
                      align="center"
                      className={classes.sectionL}
                      gutterBottom
                    >
                      Purchased:
                    </Typography>
                    <Typography
                      color="textSecondary"
                      align="center"
                      className={classes.sectionL}
                      gutterBottom
                    >
                      {`${purchaseDate} - $${cost} `}
                    </Typography>
                  </CardActions>
                  <CardActions className={classes.centerElem}>
                    <Typography
                      color="textSecondary"
                      align="center"
                      className={classes.sectionL}
                      gutterBottom
                    >
                      Transaction ID:
                    </Typography>
                    <Typography
                      color="textSecondary"
                      align="center"
                      className={classes.slidesL}
                      gutterBottom
                    >
                      <b>{QRvalue}</b>
                    </Typography>
                  </CardActions>
                  <CardActions className={classes.centerElem}>
                    <Typography
                      color="textSecondary"
                      align="center"
                      className={classes.sectionL}
                      gutterBottom
                    >
                      Expiration date:
                    </Typography>
                    <Typography
                      color="textSecondary"
                      align="center"
                      className={classes.slidesL}
                      gutterBottom
                    >
                      {expDate}
                    </Typography>
                  </CardActions>
                </GridItem>
              )}
            </div>
            <GridItem xs={12} sm={12} c="inlineFc">
              <GridItem xs={8}  c="dashWithQr">
                  {!isBack && (
                    <GridItem xs={12} sm={4} c="currSlideCont">
                      {completed ? (
                        <QRCode
                          value={`${
                            window.location.origin
                          }/admin/verify/${badgeContent}`}
                          className={classes.qrCode}
                        />
                      ) : (
                        <img
                          onClick={() => click()}
                          className={classes.currSlideImg}
                          src={slide}
                          alt="slide"
                        />
                      )}
                    </GridItem>
                  )}
                  {!isBack && (
                    <GridItem xs={12} sm={8} c="nsItem">
                      <CardActions className={classes.centerElem}>
                        <Typography
                          color="textSecondary"
                          align="center"
                          className={classes.sectionL}
                          gutterBottom
                        >
                          {completed ? name : `Section ${sections}`}
                        </Typography>
                      </CardActions>
                      <CardActions className={classes.centerElem}>
                        <Typography
                          color="textSecondary"
                          align="center"
                          className={classes.slidesL}
                          gutterBottom
                        >
                          {completed ? QRvalue : `Slide #${slides}`}
                        </Typography>
                      </CardActions>
                      <CardActions className={classes.centerElem}>
                        <Typography
                          color="textSecondary"
                          align="center"
                          className={classes.slidesL}
                          gutterBottom
                        >
                          {completed && `Exp: ${expDate}`}
                        </Typography>
                      </CardActions>
                    </GridItem>
                  )}
                </GridItem>
              <GridItem  xs={4}  c="actionContainer">
                <CardActions
                  className={classes.centerElem}
                  style={{
                    width: "100%",
                    justifyContent:
                      completed && isBack ? "space-between" : "flex-end"
                  }}
                >
                  {isBack && (
                    <div className={classes.dwldBtnCont}>
                      <div style={{ display: "none" }}>
                        <Certificate
                          refPass={ref => this.setState({ certRef: ref })}
                          QRvalue={`${window.location.origin}/admin/verify/${badgeContent}`}
                          licenseTitle={licenseTitle}
                          expDate={expDate}
                          certDescription={certDescription}
                          name={name}
                          pageStyle="@page { size: A4 portrait;}"
                          ref={el => (this.componentRef = el)}
                        />
                      </div>

                      <ReactToPrint
                        trigger={() => (
                          <Button
                            color="info"
                            size="sm"
                            className={classes.dwldBtn}
                          >
                            Print
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />

                      {/*<Button*/}
                      {/*  color="info"*/}
                      {/*  className={classes.dwldBtn}*/}
                      {/*  onClick={this.handleOpen}*/}
                      {/*  size="sm"*/}
                      {/*>*/}
                      {/*  Download*/}
                      {/*</Button>*/}

                      {/*<Dialog*/}
                      {/*  open={open}*/}
                      {/*  maxWidth="xl"*/}
                      {/*  TransitionComponent={Transition}*/}
                      {/*  keepMounted*/}
                      {/*  onClose={this.handleClose}*/}
                      {/*  aria-labelledby="alert-dialog-slide-title"*/}
                      {/*  aria-describedby="alert-dialog-slide-description"*/}
                      {/*>*/}
                      {/*  <DialogContent>*/}
                      {/*    <Certificate*/}
                      {/*      refPass={ref => this.setState({ certRef: ref })}*/}
                      {/*      QRvalue={`${window.location.origin}/admin/verify/${badgeContent}`}*/}
                      {/*      licenseTitle={licenseTitle}*/}
                      {/*      name={name}*/}
                      {/*      pageStyle="@page { size: A4 landscape; }"*/}
                      {/*      ref={el => (this.componentRef = el)}*/}
                      {/*    />*/}
                      {/*  </DialogContent>*/}
                      {/*  <DialogActions>*/}
                      {/*    <Button onClick={this.handleClose} color="primary">*/}
                      {/*      Disagree*/}
                      {/*    </Button>*/}
                      {/*    <Button onClick={this.handleClose} color="primary">*/}
                      {/*      Agree*/}
                      {/*    </Button>*/}
                      {/*  </DialogActions>*/}
                      {/*</Dialog>*/}

                      {/*<Pdf*/}
                      {/*  targetRef={certRef ? certRef : null}*/}
                      {/*  filename="certificate.pdf"*/}
                      {/*>*/}
                      {/*  {({ toPdf }) => (*/}
                      {/*    <Button*/}
                      {/*      color="info"*/}
                      {/*      className={classes.dwldBtn}*/}
                      {/*      size="sm"*/}
                      {/*      onClick={toPdf}*/}
                      {/*    >*/}
                      {/*      Download*/}
                      {/*    </Button>*/}
                      {/*  )}*/}
                      {/*</Pdf>*/}
                    </div>
                  )}
                  <Button
                    color={
                      completed
                        ? isBack
                          ? null
                          : "success"
                        : progress
                        ? "primary"
                        : "info"
                    }
                    size="sm"
                    className={
                      completed && isBack
                        ? classes.completedBtn
                        : classes.normBtn
                    }
                    onClick={() => (!completed ? click() : this.flipHandler())}
                    disabled={disabled}
                  >
                    {completed
                      ? isBack
                        ? "Done"
                        : "Details"
                      : progress
                      ? "Resume"
                      : "Start"}
                  </Button>
                </CardActions>
              </GridItem>
            </GridItem>
          </Card>
        </CSSTransition>
      </>
    ) : (
      <Card className={classes.getCard} onClick={() => setPillIndex(1)}>
        <Add color="action" />
        <h2 className={classes.sectionL}>Get new license</h2>
      </Card>
    );
  }
}

DashboardCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default connect(
  mapStateToProps,
  { setPillIndex }
)(withStyles(getLicenseStyle)(DashboardCard));
