import {OVERVIEW_COURSE} from "../actionTypes/actionTypes";

const overviewCourse = (isOverviewCourse, currentLicense, isCongrats = false) => {
    return {
        type: OVERVIEW_COURSE,
        isOverviewCourse,
        isCongrats,
        currentLicense
    };
};

export default overviewCourse;
