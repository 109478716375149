import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import Button from "../CustomButtons/Button";
import Transition from "../Transition/Transition";

const ConfirmationDialog = ({ open, title, handleClose, handleOk }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure that you want to delete {title}?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button size="sm" onClick={handleClose} color="info">
        Cancel
      </Button>
      <Button size="sm" onClick={handleOk} color="danger" autoFocus>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
