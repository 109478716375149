/* eslint-disable no-case-declarations */
import {
  ADD_SECTION,
  ADD_QUESTION,
  ADD_IMAGE,
  ADD_FIELDS,
  DELETE_SLIDE_PROP,
  ADD_ANSWER,
  EDIT_QUESTION,
  EDIT_ANSWER,
  EDIT_SECTION,
  RESET_ADMIN_LICENSE_STATE,
  DELETE_IMAGE,
  DELETE_QUESTION,
  DELETE_ANSWER,
  DELETE_SECTION,
  GET_SEARCH_LICENSE
} from "../../actions/actionTypes/actionTypes";

const initialState = {
  sections: [],
  licenseName: "",
  description: "",
  cost: "",
  org: "",
  version: "",
  expTime: ""
};

const adminLicenses = (state = initialState, action) => {
  const {
    type,
    id,
    quizIndex,
    fieldName,
    fieldValue,
    name,
    value,
    answerIndex,
    answerValue,
    delKey,
    delVal,
    resetedState,
    slideIndex,
    searchedLicense
  } = action;
  switch (type) {
    case GET_SEARCH_LICENSE:
      return {
        ...state,
        ...searchedLicense
      };
    case RESET_ADMIN_LICENSE_STATE:
      return {
        ...state,
        ...resetedState
      };
    case ADD_FIELDS:
      return {
        ...state,
        [fieldName]: fieldValue
      };
    case ADD_SECTION:
      return {
        ...state,
        sections: [
          ...state.sections,
          {
            sectionNumber: state.sections.length + 1,
            quiz: [],
            slides: [],
            section_slides: 0
          }
        ]
      };
    case EDIT_SECTION:
      const newSection = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          newSection.push({
            ...item,
            [fieldName]: fieldValue,
            section_slides: item.slides.length
          });
        } else {
          newSection.push(item);
        }
        return newSection;
      });

      return {
        ...state,
        sections: newSection
      };

    case ADD_IMAGE:
      const imageSection = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          imageSection.push({
            ...item,
            [fieldName]: fieldValue,
            section_slides: item.slides.length
          });
        } else {
          imageSection.push(item);
        }
        return imageSection;
      });

      return {
        ...state,
        sections: imageSection
      };

    case DELETE_IMAGE:
      const filterSections = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          const slides = item.slides.filter(
            (slide, index) => index !== slideIndex
          );
          filterSections.push({
            ...item,
            slides,
            section_slides: slides.length
          });
        } else {
          filterSections.push(item);
        }
        return filterSections;
      });

      return {
        ...state,
        sections: filterSections
      };

    case DELETE_SLIDE_PROP:
      const updSections = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          const slides = item.slides.map((slide, index) => {
            if (slideIndex === index && slide[delKey] === delVal) {
              return {
                ...slide,
                [delKey]: null
              };
            } else {
              return slide;
            }
          });

          updSections.push({
            ...item,
            slides
          });
        } else {
          updSections.push(item);
        }
        return updSections;
      });

      return {
        ...state,
        sections: updSections
      };
    case DELETE_SECTION:
      const filteredSections = state.sections.filter(
        item => item.sectionNumber !== id
      );
      const filterIds = filteredSections.map((item, i) => ({
        ...item,
        sectionNumber: i + 1
      }));

      return {
        ...state,
        sections: filterIds
      };
    case DELETE_QUESTION:
      const updtSects = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          const filtQ = item.quiz
            .filter((q, i) => i !== quizIndex)
            .map((fq, i) => ({
              ...fq,
              id: `${item.sectionNumber}.${i + 1}`
            }));
          updtSects.push({ ...item, quiz: filtQ });
        } else {
          updtSects.push(item);
        }
        return updtSects;
      });

      return {
        ...state,
        sections: updtSects
      };
    case DELETE_ANSWER:
      const upDQuiz = [];
      const upDSections = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          item.quiz.map((q, i) => {
            if (i === quizIndex) {
              const filteredAnswers = q.answers.filter(
                (answer, aIndex) => aIndex !== answerIndex
              );

              upDQuiz.push({ ...q, answers: filteredAnswers });
            } else {
              upDQuiz.push(q);
            }
            return upDQuiz;
          });
          upDSections.push({ ...item, quiz: upDQuiz });
        } else {
          upDSections.push(item);
        }
        return upDSections;
      });

      return {
        ...state,
        sections: upDSections
      };
    case ADD_QUESTION:
      const newSect = state.sections.map(item => {
        if (item.sectionNumber === id) {
          return {
            ...item,
            quiz: [
              ...item.quiz,
              {
                answers: [""],
                id: `${item.sectionNumber}.${item.quiz.length + 1}`
              }
            ]
          };
        }
        return item;
      });

      return {
        ...state,
        sections: newSect
      };
    case EDIT_QUESTION:
      const newQuiz = [];
      const updatedSections = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          item.quiz.map((q, i) => {
            const na = name === "correctAnswer" ? [value] : [];
            return i === quizIndex
              ? newQuiz.push({ ...q, [name]: value, correctAnswer: na })
              : newQuiz.push(q);
          });
          updatedSections.push({ ...item, quiz: newQuiz });
        } else {
          updatedSections.push(item);
        }
        return updatedSections;
      });

      return {
        ...state,
        sections: updatedSections
      };
    case ADD_ANSWER:
      const quiz = [];
      const upSections = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          item.quiz.map((q, i) => {
            return i === quizIndex
              ? quiz.push({ ...q, answers: [...q.answers, ""] })
              : quiz.push(q);
          });
          upSections.push({ ...item, quiz });
        } else {
          upSections.push(item);
        }
        return upSections;
      });

      return {
        ...state,
        sections: upSections
      };

    case EDIT_ANSWER:
      const new_quiz = [];
      const newAnswers = [];
      const updated_Sections = [];

      state.sections.map(item => {
        if (item.sectionNumber === id) {
          item.quiz.map((q, i) => {
            if (i === quizIndex) {
              q.answers.map((answer, aIndex) => {
                return aIndex === answerIndex
                  ? newAnswers.push(answerValue)
                  : newAnswers.push(answer);
              });
              new_quiz.push({ ...q, answers: newAnswers });
            } else {
              new_quiz.push(q);
            }
            return new_quiz;
          });
          updated_Sections.push({ ...item, quiz: new_quiz });
        } else {
          updated_Sections.push(item);
        }
        return updated_Sections;
      });

      return {
        ...state,
        sections: updated_Sections
      };

    default:
      return state;
  }
};

export default adminLicenses;
