import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import certificateStyle from "../../assets/jss/material-dashboard-pro-react/components/certificateStyle";
import QRCode from "qrcode.react";
import Button from "../CustomButtons/Button";
import { switchFunction } from "../SwitchFunction/SwitchFunction";

const ref = React.createRef();

class Certificate extends PureComponent {
  componentDidMount() {
    this.props.refPass(ref);
  }

  render() {
    const {
      classes,
      QRvalue,
      licenseTitle,
      certDescription,
      expDate,
      name
    } = this.props;

    let logoSRC = null;

    logoSRC = switchFunction()

    return (
      <div className={classes.certificateContainer} ref={ref}>
        <div className={classes.certificateTop}>
          <h1>Certificate of Completion</h1>
          <p>of the</p>
          <h2>{licenseTitle} Training</h2>
          <Button color="rose" round size="lg">
            is proudly presented to
          </Button>
        </div>
        <div className={classes.certificateContent}>
          <div>
            <h4>{name}</h4>
            <p>
              {name} .{certDescription}.
            </p>
          </div>
          <div className={classes.certificateBottom}>
            <div className={classes.certBL}>
              <QRCode value={QRvalue} className={classes.qrCode} />
              <img src={logoSRC} alt="logo" className={classes.img} />
            </div>
            <div className={classes.certBT}>
              <h5>License expiration date: {expDate}</h5>
              {/*<img src={sign} alt="sign" className={classes.img}/>*/}
              {/*<h5>Stuart Spillman</h5>*/}
              {/*<p>Enviromental Health Director</p>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(certificateStyle)(Certificate);
