import axios from "axios";

// Need a custom URL? Add it here!
// But do not forget to remove when done using it!!!!
let customURL = "";

let url = "";
if (process.env.NODE_ENV === "production") {
  url = "https://statecert.com/api/";
} else if (customURL) {
  url = customURL;
} else {
  url = "https://dev.statecert.com/api/";
}

export default axios.create({
  baseURL: url

  // baseURL: "http://localhost:3002/api/",
  // baseURL: "https://5472e326.ngrok.io/api/"
});
