import Build from "@material-ui/icons/Build";
// @material-ui/icons
import CardTravel from "@material-ui/icons/CardTravel";
import Extension from "@material-ui/icons/Extension";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import CustomDropdown from "../components/CustomDropdown/CustomDropdown.jsx";
import React from "react";

export const states = [
  // "Delaware",
  // "Pennsylvania",
  // "New Jersey",
  // "Georgia",
  // "Connecticut",
  // "Massachusetts",
  // "Maryland",
  // "South Carolina",
  // "New Hampshire",
  // "Virginia",
  // "New York",
  // "North Carolina",
  // "Rhode Island",
  // "Vermont",
  "Kentucky"
  // "Tennessee",
  // "Ohio",
  // "Louisiana",
  // "Indiana",
  // "Mississippi",
  // "Illinois",
  // "Alabama",
  // "Maine",
  // "Missouri",
  // "Arkansas",
  // "Michigan",
  // "Florida",
  // "Texas",
  // "Iowa",
  // "Wisconsin",
  // "California",
  // "Minnesota",
  // "Oregon",
  // "Kansas",
  // "West Virginia",
  // "Nevada",
  // "Nebraska",
  // "Colorado",
  // "North Dakota",
  // "South Dakota",
  // "Montana",
  // "Washington",
  // "Idaho",
  // "Wyoming",
  // "Utah",
  // "Oklahoma",
  // "New Mexico",
  // "Arizona",
  // "Alaska",
  // "Hawaii",
  // "Puerto Rico"
];

export const counties = [
  "Adair",
  "Allen",
  "Anderson",
  "Ballard",
  "Barren",
  "Bath",
  "Bell",
  "Boone",
  "Bourbon",
  "Boyd",
  "Boyle",
  "Bracken",
  "Breathitt",
  "Breckinridge",
  "Bullitt",
  "Butler",
  "Caldwell",
  "Calloway",
  "Campbell",
  "Carlisle",
  "Carroll",
  "Carter",
  "Casey",
  "Christian",
  "Clark",
  "Clay",
  "Clinton",
  "Crittenden",
  "Cumberland",
  "Daviess",
  "Edmonson",
  "Elliott",
  "Estill",
  "Fayette",
  "Fleming",
  "Floyd",
  "Franklin",
  "Fulton",
  "Gallatin",
  "Garrard",
  "Grant",
  "Graves",
  "Grayson",
  "Green",
  "Greenup",
  "Hancock",
  "Hardin",
  "Harlan",
  "Harrison",
  "Hart",
  "Henderson",
  "Henry",
  "Hickman",
  "Hopkins",
  "Jackson",
  "Jefferson",
  "Jessamine",
  "Johnson",
  "Kenton",
  "Knott",
  "Knox",
  "LaRue",
  "Laurel",
  "Lawrence",
  "Lee",
  "Leslie",
  "Letcher",
  "Lewis",
  "Lincoln",
  "Livingston",
  "Logan",
  "Lyon",
  "McCracken",
  "McCreary",
  "McLean",
  "Madison",
  "Magoffin",
  "Marion",
  "Marshall",
  "Martin",
  "Mason",
  "Meade",
  "Menifee",
  "Mercer",
  "Metcalfe",
  "Monroe",
  "Montgomery",
  "Morgan",
  "Muhlenberg",
  "Nelson",
  "Nicholas",
  "Ohio",
  "Oldham",
  "Owen",
  "Owsley",
  "Pendleton",
  "Perry",
  "Pike",
  "Powell",
  "Pulaski",
  "Robertson",
  "Rockcastle",
  "Rowan",
  "Russell",
  "Scott",
  "Shelby",
  "Simpson",
  "Spencer",
  "Taylor",
  "Todd",
  "Trigg",
  "Trimble",
  "Union",
  "Warren",
  "Washington",
  "Wayne",
  "Webster",
  "Whitley",
  "Wolfe",
  "Woodford"
];

// ##############################
// // // stories for RTLSupport view
// #############################

const rtlStories = [
  {
    // First story
    inverted: true,
    badgeColor: "danger",
    badgeIcon: CardTravel,
    title: "جهة أي",
    titleColor: "danger",
    body: (
      <p>
        قام كل ماذا العصبة اوروبا. أي جورج العالمي أخر, كان تم أطراف القوى
        استبدال. أسر ميناء تكتيكاً الجديدة، كل. جُل اللا التكاليف بـ, عرفها
        النزاع لليابان بـ أضف. انتهت المدن الثالث من وقد.وقبل قادة إحتار عن أخر.
        حين ونتج أخرى قد. بالعمل بالمطالبة فقد قد. عن جنوب ومضى الشتاء.
      </p>
    ),
    footerTitle: "مدن أن هُزم سكان, مكن."
  },
  {
    // Second story
    inverted: true,
    badgeColor: "success",
    badgeIcon: Extension,
    title: "جُل حكومة",
    titleColor: "success",
    body: (
      <p>
        عل فكانت الثقيلة بلا. شيء بخطوط بالرّغم التبرعات عن, يطول بأيدي لم كلّ.
        معقل الغالي واتّجه لم وتم, أن الصفحة بالمحور حول, بال مرمى الصفحات
        قُدُماً و. الأخذ سبتمبر العالم من ذلك. ان يبق شدّت الأبرياء, الى الربيع،
        والمانيا كل. ودول الأهداف التقليدي عل أضف, كلا يقوم الأخذ الآلاف بل.
      </p>
    )
  },
  {
    // Third story
    inverted: true,
    badgeColor: "info",
    badgeIcon: Fingerprint,
    title: "هذا غينيا",
    titleColor: "info",
    body: (
      <p>
        جهة المارق والديون التقليدية في, هو وترك المجتمع بريطانيا ذلك, لمّ ما
        العالم، اليابان،. ٣٠ فقامت أوروبا مشاركة بعد, ٢٠٠٤ الجو مساعدة ما حدى.
        في عليها وبحلول معارضة بعض. عن الأرض وبداية العمليات ولم. الجو جديداً
        الأوروبيّون أم به،. ثم التي نتيجة الآلاف جعل, عن المارق السادس قام. ما
        أخر فقامت الأجل الشرق،, فصل كل وسوء الأرواح. ثم بعد وشعار بأيدي. قبل
        وكسبت الغالي الولايات بل, ٣٠ أمّا أخرى لأداء أضف. هو منتصف معزّزة على.
        بـ أفريقيا التغييرات مما, أثره،.
      </p>
    ),
    footer: (
      <CustomDropdown
        rtlActive
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "ان",
          "إجلاء لفرنسا",
          "أواخر الأرض بل",
          { divider: true },
          "عل اليها"
        ]}
      />
    )
  }
];

// ##############################
// // // stories for Widgets view
// #############################

const widgetStories = [
  {
    // First story
    inverted: true,
    badgeColor: "danger",
    badgeIcon: CardTravel,
    title: "Some Title",
    titleColor: "danger",
    body: (
      <p>
        made the best Father's Day meal ever. So thankful so happy so blessed.
        Thank you for making my family We just had fun with the “future” theme
        !!! It was a fun night all together ... The always rude Kanye Show at
        2am Sold Out Famous viewing @ Figueroa and 12th in downtown.
      </p>
    ),
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "Action",
          "Another action",
          "Something else here",
          { divider: true },
          "Separated link"
        ]}
      />
    )
  },
  {
    // Second story
    inverted: true,
    badgeColor: "success",
    badgeIcon: Extension,
    title: "Another One",
    titleColor: "success",
    body: (
      <p>
        Thank God for the support of my wife and real friends. I also wanted to
        point out that it’s the first album to go number 1 off of streaming!!! I
        love you Ellen and also my number one design rule of anything I do from
        shoes to music to homes is that Kim has to like it....
      </p>
    ),
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "Action",
          "Another action",
          "Something else here",
          { divider: true },
          "Separated link"
        ]}
      />
    )
  },
  {
    // Third story
    inverted: true,
    badgeColor: "info",
    badgeIcon: Fingerprint,
    title: "Another Title",
    titleColor: "info",
    body: (
      <div>
        <p>
          Called I Miss the Old Kanye That’s all it was Kanye And I love you
          like Kanye loves Kanye Famous viewing @ Figueroa and 12th in downtown
          LA 11:10PM
        </p>
        <p>
          What if Kanye made a song about Kanye Royère doesn't make a Polar bear
          bed but the Polar bear couch is my favorite piece of furniture we own
          It wasn’t any Kanyes Set on his goals Kanye
        </p>
      </div>
    ),
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "Action",
          "Another action",
          "Something else here",
          { divider: true },
          "Separated link"
        ]}
      />
    )
  }
];

// ##############################
// // // stories for Timeline view
// #############################

const stories = [
  {
    // First story
    inverted: true,
    badgeColor: "danger",
    badgeIcon: CardTravel,
    title: "Licence Obtained",
    titleColor: "danger",
    body: (
      <div>
        <h3> Manager Certficate #612849</h3>
        <h4>Issued 01/22/2018</h4>
        <h4>Expires 01/22/2020</h4>
      </div>
    ),
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "See Licence",
          "Renew",
          "Something else here",
          { divider: true },
          "Separated link"
        ]}
      />
    )
  },
  {
    // Second story
    inverted: true,
    badgeColor: "success",
    badgeIcon: Extension,
    title: "Licence Obtained",
    titleColor: "success",
    body: (
      <div>
        <h3> Manager Certficate #612849</h3>
        <h4>Issued 01/22/2016</h4>
        <h4>Expires 01/22/2018</h4>
      </div>
    ),
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "Action",
          "Another action",
          "Something else here",
          { divider: true },
          "Separated link"
        ]}
      />
    )
  },
  {
    // Third story
    inverted: true,
    badgeColor: "info",
    badgeIcon: Fingerprint,
    title: "Set to Expire",
    titleColor: "info",
    body: (
      <div>
        <p>
          Called I Miss the Old Kanye That’s all it was Kanye And I love you
          like Kanye loves Kanye Famous viewing @ Figueroa and 12th in downtown
          LA 11:10PM
        </p>
        <p>
          What if Kanye made a song about Kanye Royère doesn't make a Polar bear
          bed but the Polar bear couch is my favorite piece of furniture we own
          It wasn’t any Kanyes Set on his goals Kanye
        </p>
      </div>
    ),
    footer: (
      <CustomDropdown
        buttonIcon={Build}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "info"
        }}
        dropdownList={[
          "Action",
          "Another action",
          "Something else here",
          { divider: true },
          "Separated link"
        ]}
      />
    )
  }
];

// ##############################
// // // data for populating the calendar in Calendar view
// #############################

var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();

const events = [
  {
    title: "All Day Event",
    allDay: true,
    start: new Date(y, m, 1),
    end: new Date(y, m, 1),
    color: "default"
  },
  {
    title: "Meeting",
    start: new Date(y, m, d - 1, 10, 30),
    end: new Date(y, m, d - 1, 11, 30),
    allDay: false,
    color: "green"
  },
  {
    title: "Lunch",
    start: new Date(y, m, d + 7, 12, 0),
    end: new Date(y, m, d + 7, 14, 0),
    allDay: false,
    color: "red"
  },
  {
    title: "Nud-pro Launch",
    start: new Date(y, m, d - 2),
    end: new Date(y, m, d - 2),
    allDay: true,
    color: "azure"
  },
  {
    title: "Birthday Party",
    start: new Date(y, m, d + 1, 19, 0),
    end: new Date(y, m, d + 1, 22, 30),
    allDay: false,
    color: "azure"
  },
  {
    title: "Click for DevDuo",
    start: new Date(y, m, 21),
    end: new Date(y, m, 22),
    color: "orange"
  },
  {
    title: "Click for Google",
    start: new Date(y, m, 21),
    end: new Date(y, m, 22),
    color: "rose"
  }
];

// ##############################
// // // Tasks for TasksCard - see Widget view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  "Create 4 Invisible User Experiences you Never Knew About"
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

// ##############################
// // // Tasks for TasksCard - see RTLSupport view
// #############################

var rtlBugs = [
  "فقد لمحاكم الاندونيسية, بلاده بالتوقيع تم يبق. جعل السبب وفرنسا الصينية أي.",
  "بحث. كل مما ٢٠٠٤ شاسعة العسكري جعل السبب وفرنسا الصينية أي.",
  "تسبب أفريقيا ضرب عن, عن إنطلاق جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي."
];
var rtlWebsite = [
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي."
];
var rtlServer = [
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي.",
  "قدما مليون بين عن, مرجع منتصف الأمريكية جعل السبب وفرنسا الصينية أي."
];

// ##############################
// // // data for datatables.net in DataTables view
// #############################

const dataTable = {
  headerRow: [
    "Name",
    "Organization",
    "License",
    "License Exp",
    "Registered",
    "Age",
    "Status",
    "Actions"
  ],
  footerRow: [
    "Name",
    "Organization",
    "License",
    "License Exp",
    "Registered",
    "Age",
    "Status",
    "Actions"
  ],
  dataRows: [
    [
      "Tiger Nixon",
      "Food Handler-v1",
      "2019/11/28",
      "2019/11/28",
      "61",
      <div className="status" />
    ],
    [
      "Garrett Winters",
      "Food Handler-v1",
      "2019/11/28",
      "2019/11/28",
      "63",
      <div className="status" />
    ],
    [
      "Ashton Cox",
      "Food Handler-v1",
      "",
      "2019/11/28",
      "24",
      <div className="status" />
    ],
    [
      "Cedric Kelly",
      "Food Handler-v1",
      "2007/11/30",
      "2007/11/30",
      "66",
      <div className="status" />
    ],
    [
      "Airi Satou",
      "Food Handler-v2",
      "",
      "2027/11/03",
      "33",
      <div className="status" />
    ],
    [
      "Brielle Williamson",
      "Food Manager",
      "2010/11/12",
      "2019/11/28",
      "61",
      <div className="status" />
    ],
    [
      "Herrod Chandler",
      "Food Manager",
      "",
      "2047/08/30",
      "59",
      <div className="status" />
    ],
    [
      "Rhona Davidson",
      "Food Manager-v5",
      "2017/09/20",
      "2019/11/28",
      "55",
      <div className="status" />
    ],
    [
      "Colleen Hurst",
      "Food Manager",
      "",
      "2019/11/28",
      "39",
      <div className="status" />
    ],
    [
      "Sonya Frost",
      "Food Manager",
      "2009/11/08",
      "2019/11/28",
      "23",
      <div className="status" />
    ],
    [
      "Jena Gaines",
      "Food Manager",
      "",
      "2019/11/28",
      "30",
      <div className="status" />
    ],
    [
      "Quinn Flynn",
      "Food Manager",
      "2019/11/28",
      "2019/11/28",
      "22",
      <div className="status" />
    ],
    [
      "Charde Marshall",
      "Food Manager",
      "",
      "2019/11/28",
      "36",
      <div className="status" />
    ],
    [
      "Haley Kennedy",
      "Food Manager",
      "2019/11/28",
      "2019/11/28",
      "43",
      <div className="status" />
    ],
    [
      "Tatyana Fitzpatrick",
      "Food Manager",
      "2019/11/28",
      "2019/11/28",
      "19",
      <div className="status" />
    ],
    [
      "Michael Silva",
      "Food Manager",
      "2019/11/28",
      "2019/11/28",
      "66",
      <div className="status" />
    ],
    [
      "Paul Byrd",
      "Food Manager",
      "2019/11/28",
      "2019/11/28",
      "64",
      <div className="status" />
    ],
    [
      "Gloria Little",
      "Food Manager",
      "2019/11/28",
      "2019/11/28",
      "59",
      <div className="status" />
    ],
    [
      "Bradley Greer",
      "Food Manager",
      "2019/11/28",
      "2019/11/28",
      "41",
      <div className="status" />
    ]
  ]
};

export {
  // data for React Big Calendar in Calendar view
  events,
  // stories for RTLSupport view
  rtlStories,
  // stories for Widgets view
  widgetStories,
  // stories for Timeline view
  stories,
  // these 3 are used to create the tasks lists in TasksCard - Widget view
  bugs,
  website,
  server,
  // these 3 are used to create the tasks lists in TasksCard - RTLSupport view
  rtlBugs,
  rtlWebsite,
  rtlServer,
  // data for datatables.net in DataTables view
  dataTable
};
