import {
    // container,
    cardTitle,
    blackColor,
    hexToRgb,
    // grayColor
} from "../../material-dashboard-pro-react.jsx";

import dialogTitleImg from "../../../img/dialogTitleImg.png";

const receiptPageStyle = {
    receiptCard: {
        backgroundColor: "#ededed",
        borderRadius: "6px",
        boxShadow:
            "0 -1px 24px 2px rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 6px 30px 5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.2)",
        marginBottom: "10px",
        padding: "40px 0px",
        marginTop: "10vh",
        zIndex: 10
    },
    receiptTitle: {
        ...cardTitle,
        textAlign: "center",
        fontSize: 23,
        padding: 0,
        lineHeight: "1",
        maxWidth: 276,
        width: "100%"
    },
    receiptWrapper: {
        marginTop: 40
    },
    card: {
        width: "100%",
        height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "2% 0"
    },
    receiptCardHead: {
        fontSize: 21,
        fontWeight: "300",
        color: "#3c4858",
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: 10
    },
    receiptCardBody: {
        fontSize: 13,
        fontWeight: "300",
        letterSpacing: "initial"
    },
    dialog: {
        minWidth: 320,
        maxWidth: 500,
        maxHeight: "95%",
        "@media (max-width: 600px)": {
           margin: 5
        }
    },
    dialogContent: {
        padding: 0,
        "&>*:not(.dialogDetails)": {
            paddingLeft: 24,
            paddingRight: 24
        }
    },
    dialogTitle: {
        ...cardTitle,
        color: "#23283E",
        borderBottom: "1px solid #F3F5F7",
        marginBottom: 0,
        paddingTop: 10,
        paddingBottom: 10,
        "& h6": {
            fontSize: 18
        }
    },
    dialogImg: {
        width: "100%",
        height: "30px",
        background: `url(${dialogTitleImg}) no-repeat`,
        backgroundSize: "contain"
    },
    dialogHeadText: {
        color: "#35355F",
        textAlign: "center",
        paddingBottom: 5,
        paddingTop: 0,
        "@media (max-width: 600px)": {
            "& h6": {
                fontSize: 18
            }
        }
    },
    idPay: {
        color: "#9BA9B8",
        textAlign: "center"
    },
    dialogDetails: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 20,
        "&>*": {
            width: "33.3%"
        }
    },
    dialogDetailsHead: {
        color: "#909EAF",
        fontSize: 12,
        fontWeight: 600,
        "@media (max-width: 600px)": {
            fontSize: 9
        }
    },
    dialogDetailsBody: {
        color: "#5D6987",
        fontSize: "14px"
    },
    dialogCertificate: {
        marginTop: 15
    },
    dialogCertificateHead: {
        paddingTop: 8,
        paddingBottom: 8
    },
    dialogCertificateContent: {
        padding: "0 24px",
        background: "#F7F9FC"
    },
    dialogCertificateContentItem: {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 0"
    },
    dialogCertificateName: {
        borderBottom: "1px solid #EBEFF4",
        "& *": {
            color: "#5C6886",
            fontSize: 12
        }
    },
    dialogCertificateAll: {
        "& *": {
            color: "#525F7F",
            fontSize: 12,
            fontWeight: 800
        }
    },
    dialogCertificateInfo: {
        marginTop: 20,
        fontSize: 12,
        borderTop: "1px solid #EBEFF4",
        borderBottom: "1px solid #EBEFF4",
        padding: "30px 0",
        "@media (max-width: 600px)": {
            marginTop: 10,
            padding: "20px 0"
        }
    },
    dialogCertificateFooter: {
        fontSize: 10,
        marginTop: 15
    }
};

export default receiptPageStyle;