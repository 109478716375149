/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";
import userRegister from "../../store/actions/userRegister/userRegister";
import clearIsRregistrationError from "../../store/actions/clearIsRregistrationError/clearIsRregistrationError";

import { KeyboardArrowLeft } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "../CustomButtons/Button.jsx";
import CustomInput from "../CustomInput/CustomInput";
import Card from "../Card/Card.jsx";

import wizardStyle from "../../assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";

class Wizard extends Component {
  constructor(props) {
    super(props);

    let width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }

    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      isVerifyContent: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      changeEmail: false,
      changeEmailState: "success",
      movingTabStyle: {
        transition: "transform 0s"
      },
      allStates: {}
    };

  }

  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
    this.props.userRegister(false);
  }

  updateWidth = () => {
    this.refreshAnimation(this.state.currentStep);
  };

  navigationStepChange = key => {
    const { steps, currentStep } = this.props;

    if (steps) {
      let validationState = true;
      if (key > currentStep) {
        for (let i = currentStep; i < key; i++) {
          if (this[steps[i].stepId].sendState !== undefined) {
            this.setState({
              allStates: {
                ...this.state.allStates,
                [steps[i].stepId]: this[steps[i].stepId].sendState()
              }
            });
          }
          if (
            this[steps[i].stepId].isValidated !== undefined &&
            this[steps[i].stepId].isValidated() === false
          ) {
            validationState = false;
            break;
          }
        }
      }
      if (validationState) {
        this.setState({
          currentStep: key,
          nextButton: steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: steps.length === key + 1 ? true : false
        });
        this.refreshAnimation(key);
      }
    }
  };

  sendUserData = userData => this.setState({ ...userData });

  nextButtonClick = () => {
    const { validate, steps } = this.props;
    const { currentStep, allStates } = this.state;

    if (
      (validate &&
        ((this[steps[currentStep].stepId].isValidated !== undefined &&
          this[steps[currentStep].stepId].isValidated()) ||
          this[steps[currentStep].stepId].isValidated === undefined)) ||
      validate === undefined
    ) {
      if (this[steps[currentStep].stepId].sendState !== undefined) {
        this.setState({
          allStates: {
            ...allStates,
            [steps[currentStep].stepId]: this[
              steps[currentStep].stepId
            ].sendState()
          }
        });
      }
      let key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  };

  previousButtonClick = () => {
    const { steps } = this.props;
    const { currentStep, allStates } = this.state;

    if (this[steps[currentStep].stepId].sendState !== undefined) {
      this.setState({
        allStates: {
          ...allStates,
          [steps[currentStep].stepId]: this[
            steps[currentStep].stepId
          ].sendState()
        }
      });
    }
    let key = currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  };

  finishButtonClick = () => {
    const { finishButtonClick } = this.props;
    
    finishButtonClick(this.state.allStates);
    this.setState({changeEmailState: "error"});
    
  };

  redirectToVerify = () => {
    const { validate, steps } = this.props;
    const { currentStep, allStates } = this.state;

    if (
      (validate === false) ||
      (validate &&
        ((this[steps[currentStep].stepId].isValidated !== undefined &&
          this[steps[currentStep].stepId].isValidated()) ||
          this[steps[currentStep].stepId].isValidated === undefined))
    ) {
      this.setState(
        {
          allStates: {
            ...allStates,
            [steps[currentStep].stepId]: this[
              steps[currentStep].stepId
            ].sendState()
          }
        },
        () => {
          this.setState({ isVerifyContent: true });
        }
      );
    }
    
  };

  refreshAnimation = index => {
    const { steps } = this.props;

    if (this.refs.wizard && this.refs.wizard.children) {
      let total = steps.length;
      let li_width = 100 / total;
      let total_steps = steps.length;
      let move_distance =
        this.refs.wizard.children[0].offsetWidth / total_steps;
      let index_temp = index;
      let vertical_level = 0;

      let mobile_device = window.innerWidth < 600 && total > 3;

      if (mobile_device) {
        move_distance = this.refs.wizard.children[0].offsetWidth / 2;
        index_temp = index % 2;
        li_width = 50;
      }

      this.setState({ width: li_width + "%" });

      let step_width = move_distance;
      move_distance = move_distance * index_temp;

      let current = index + 1;

      if (current === 1 || (mobile_device === true && index % 2 === 0)) {
        move_distance -= 8;
      } else if (
        current === total_steps ||
        (mobile_device === true && index % 2 === 1)
      ) {
        move_distance += 8;
      }

      if (mobile_device) {
        vertical_level = parseInt(index / 2, 10);
        vertical_level = vertical_level * 38;
      }
      let movingTabStyle = {
        width: "30%",
        // width: step_width,
        transform:
          "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
        transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
      };
      this.setState({ movingTabStyle: movingTabStyle });
    }
  };

  changeEmailButtonClick = () => {
    this.setState({changeEmail: true});
  }

  handleChangeEmail = ({ target: { value: email } }) => {
    const { allStates  } = this.state;
    const { clearIsRregistrationError } = this.props;

    this.setState({
      allStates: {
        ...allStates,
        personal: {
          ...allStates.personal,
          email
        }
      }
    });
    
    clearIsRregistrationError();
  };

  verifyEmail = value => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  change = (event, stateName) => {
    const { value } = event.target;

    if (this.verifyEmail(value)) {
      this.setState({
        [stateName + "State"]: "success",
        alreadyExistMsg: ""
      });
    } else {
      this.setState({
        [stateName + "State"]: "error",
        alreadyExistMsg: ""
      });
    }

  };

  render() {
    const {
      classes,
      title,
      subtitle,
      color,
      steps,
      previousButtonClasses,
      nextButtonClasses,
      nextButtonText,
      finishButtonText,
      previousButtonText,
      changeEmailButtonText,
      finishButtonClasses,
      isRegister,
      registerButtonText,
      isLoad
    } = this.props;
    const {
      width,
      movingTabStyle,
      currentStep,
      allStates,
      nextButton,
      finishButton,
      previousButton,
      isVerifyContent,
      changeEmail,
      changeEmailState
    } = this.state;

    return (
      <div className={classes.wizardContainer} ref="wizard">
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>
              {!isRegister ? `${title}` : "Verify your email"}
            </h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          {!isVerifyContent ? (
            <>
              <div className={classes.wizardNavigation}>
                <ul className={classes.nav}>
                  {steps.map((prop, key) => {
                    return (
                      <li className={classes.steps} key={key} style={{ width }}>
                        <p className={classes.stepsAnchor}>{prop.stepName}</p>
                      </li>
                    );
                  })}
                </ul>
                <div
                  className={classes.movingTab + " " + classes[color]}
                  style={movingTabStyle}
                >
                  {steps[currentStep].stepName}
                </div>
              </div>
              <div className={classes.content}>
                {steps.map((prop, key) => {
                  const stepContentClasses = cx({
                    [classes.stepContentActive]: currentStep === key,
                    [classes.stepContent]: currentStep !== key
                  });
                  return (
                    <div className={stepContentClasses} key={key}>
                      <prop.stepComponent
                        innerRef={node => (this[prop.stepId] = node)}
                        nextButtonClick={this.nextButtonClick}
                        allStates={allStates}
                        sendUserData={this.sendUserData}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={classes.footer}>
                <div className={classes.left}>
                  {previousButton ? (
                    <Button
                      color="github"
                      simple
                      className={previousButtonClasses}
                      onClick={() => this.previousButtonClick()}
                    >
                      <KeyboardArrowLeft />
                      {previousButtonText}
                    </Button>
                  ) : null}
                </div>
                <div className={classes.right}>
                  {nextButton ? (
                    <Button
                      color="rose"
                      className={nextButtonClasses}
                      onClick={() => this.nextButtonClick()}
                    >
                      {nextButtonText}
                    </Button>
                  ) : null}
                  {finishButton ? (
                    <Button
                      color="rose"
                      // disabled={!completeRegProgress}
                      className={finishButtonClasses}
                      onClick={this.redirectToVerify}
                    >
                      {finishButtonText}
                    </Button>
                  ) : null}
                </div>
                <div className={classes.clearfix} />
              </div>
            </>
          ) : (
            <div className={`${classes.content} ${classes.verifyContent}`}>
              <div className={classes.verifyHeader}>
                <h3 className={classes.title}>
                  An verification email has been sent to:
                </h3>
                {!changeEmail ? ( 
                  <h4>{allStates.personal.email}</h4> 
                  ) : (
                      <CustomInput 
                        success={changeEmailState === "success"}
                        error={changeEmailState === "error"}
                        labelText={
                          <span>
                            Email <small>(required)</small>
                          </span>
                        }
                        inputProps={{ 
                          onChange: event => { 
                            this.handleChangeEmail(event);
                            this.change(event, "changeEmail");
                          },
                          value: allStates.personal.email
                         }} 
                      />
                  )
                }
              </div>
              <div className={classes.verifyButtonsContent}>   
              {isLoad ? <CircularProgress className={classes.loader} size = {50} />: null}             
                {!changeEmail ? (
                  <Button
                    color="rose"
                    className={nextButtonClasses}
                    onClick={this.changeEmailButtonClick}
                  >
                    {changeEmailButtonText}
                  </Button>
                ) : (
                  null
                )}
                <Button
                  color="success"
                  className={nextButtonClasses}
                  onClick={this.finishButtonClick}
                  disabled = { this.state.changeEmailState === "success" ? false: true }
                >
                  {registerButtonText}
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "rose",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Back",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  finishButtonClasses: "",
  finishButtonText: "Finish",
  changeEmailButtonText: "Not your email?",
  registerButtonText: "Register"
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isRegister: state.auth.isRegister,
    completeRegProgress: state.auth.completeProgress
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { userRegister, clearIsRregistrationError }
  )(withStyles(wizardStyle)(Wizard))
);
