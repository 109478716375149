import { SET_CURRENT_LICENSE_NAME } from '../actionTypes/actionTypes';

const setCurrentLicName = (currentLicenseName, licenseTransactionId) => {
  return {
    type: SET_CURRENT_LICENSE_NAME,
    currentLicenseName,
    licenseTransactionId
  };
};

export default setCurrentLicName;
