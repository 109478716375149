import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Redirect, Route } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import { adminRoutes as routes } from "../constants/routes";

import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import { switchFunction } from "../components/SwitchFunction/SwitchFunction";

import Verification from "../views/Admin/Verification/Verification";
import User from "../views/Admin/Users/User";
var ps;

class Admin extends Component {
  state = {
    mobileOpen: false,
    miniActive: false,
    color: "rose",
    bgColor: "white",
    hasImage: true,
    fixedClasses: "dropdown",
  };

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleColorClick = (color) => {
    this.setState({ color });
  };

  handleBgColorClick = (bgColor) => {
    this.setState({ bgColor });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute = () => this.props.location.pathname !== "/admin/full-screen-maps";

  getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].routeName) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => {
              const { role } = localStorage;

              return Number(role) <= 3 ? (
                <prop.component {...props} />
              ) : (
                <Redirect to={{ pathname: "/login", state: props.location }} />
              );
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  render() {
    const { classes, ...rest } = this.props;
    const { miniActive, mobileOpen, color, bgColor } = this.state;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });

    let logoSRC = null;

    logoSRC = switchFunction();

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText="KHD Food License"
          logo={logoSRC}
          handleDrawerToggle={this.handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize}
            miniActive={miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {this.getRoutes(routes)}
                  <Route exact path="/admin/verify" component={Verification} />
                  <Route exact path="/admin/user/:id" component={User} />
                  <Redirect from="/admin" to="/admin/dashboard" />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withStyles(appStyle)(Admin));
