import {
  container,
  cardTitle,
  whiteColor,
  grayColor
  // blackColor,
  // hexToRgb
} from "../../material-dashboard-pro-react.jsx";

const loginPageStyle = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0"
  },
  regOnMobile: {
    display: "none",
    [theme.breakpoints.down(700)]: {
      display: "flex"
    }
  },
  fullPage: {
    padding: "100px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: whiteColor,
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content!important"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
    "&:before": {
      // backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2"
    }
  },
  cardContainer: {
    zIndex: "999",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20
    }
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  cardParapgraph: {
    ...cardTitle,
    marginTop: 20
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor
  },
  textCenter: {
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px !important",
    padding: "20px !important"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  form: {
    width: "100%"
  },
  dirCol: {
    flexDirection: "column"
  },
  loader: {
    position: "absolute"
  },
  helpTextClass: {
    height: "30px"
  }
});

export default loginPageStyle;
