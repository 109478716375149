import React, { Component } from "react";
import API from "./api";
import axios from "axios";
import LogRocket from "logrocket";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { paths } from "./constants/paths";
import dispatchUser from "./store/actions/dispatchUser/dispatchUser";
import { setStripeError } from "./store/actions/adminLicenseActions/adminLicenseActions";
import setErrorStatus from "./store/actions/setErrorStatus/setErrorStatus";

import { Warning, Done, Error } from "@material-ui/icons";
import SnackBar from "./components/Snackbar/Snackbar";
import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import LoginPage from "./views/Pages/LoginPage/LoginPage.jsx";
import RegisterPage from "./views/Pages/RegisterPage/RegisterPage.jsx";
import RecoverPage from "./views/Pages/RecoverPage/RecoverPage.jsx";
import PricingPage from "./views/Pages/PricingPage/PricingPage.jsx";
import SupportAlert from "./components/SupportAlert/SupportAlert";

class App extends Component {
  state = {
    userStatus: true,
    isVerify: true,
    isSend: false
  };

  componentDidMount() {
    this.checkUserStatus();
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.history.location;
    const { userStatus } = this.state;
    const isAuthLocation =
      pathname !== paths.register &&
      pathname !== paths.login &&
      pathname !== paths.forgot &&
      pathname !== paths.pricing;

    if (prevState.userStatus !== userStatus && isAuthLocation) {
      if (!userStatus) {
        this.logoutUser();
      }
    }
  }

  logoutUser = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    localStorage.removeItem("_lr_id_");

    this.props.history.push("/login");
  };

  checkUserStatus = async () => {
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await API.get("user/current", {
        cancelToken: this.cancelTokenSource.token,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      const { firstName, lastName, email, _id, role } = data;
      localStorage.setItem("role", role);
      this.setState({
        userStatus: Boolean(data._id),
        isVerify: data.isEmailVerified
      });
      LogRocket.identify(_id, {
        name: `${firstName} ${lastName}`,
        email

        // Add your own custom user variables here, ie:
        // subscriptionType: 'pro'
      });

      this.props.dispatchUser(data);
    } catch (err) {
      this.setState({ userStatus: false });

      if (axios.isCancel(err)) {
        console.error("(: Individual request cancel :)");
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };

  resendHandler = async () => {
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const { status }  = await API.post(
        "user/resendEmail",
        {
          email: this.props.userEmail
        },
        {
          cancelToken: this.cancelTokenSource.token,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
    
      if (status === 200) {
        this.setState({ isSend: true });
      }
      
    } catch (e) {
      if (axios.isCancel(e)) {
        console.error("(: Individual request cancel :)");
      }
      this.props.setErrorStatus(true);
      console.error(e);
    } finally {
      this.cancelTokenSource = null;
      this.closeNote();
    }
  };

  closeNote = () => this.setState({ isVerify: true });

  closeSendNote = () => this.setState({ isSend: false });

  closeErrorNote = () => this.props.setErrorStatus(false);

  render() {
    const { getLicenseError, setStripeError, isError } = this.props;
    const { authToken } = localStorage;
  
  
    return (
      <>
        <SupportAlert />
        {authToken && (
          <SnackBar
            close
            place="bl"
            icon={Warning}
            color="warning"
            buttonText="Resend"
            open={!this.state.isVerify}
            buttonClick={this.resendHandler}
            closeNotification={this.closeNote}
            message="Your account has not been activated. If you haven't recieved an activation email please press the Resend button."
          />
        )}
        {authToken && (
          <>
            <SnackBar
              close
              place="bl"
              icon={Done}
              color="success"
              buttonText="Ok"
              buttonProps = {{
                size: "sm"
              }}
              open={this.state.isSend}
              buttonClick={this.closeSendNote}
              autoHideDuration = {6000}
              closeNotification={this.closeSendNote}
              message="Email with activation code sent."
            />
            <SnackBar
              close
              place="bl"
              icon={Error}
              color="danger"
              buttonText="Ok"
              buttonProps = {{
                size: "sm"
              }}
              open={isError.status}
              buttonClick={this.closeErrorNote}
              autoHideDuration = {6000}
              closeNotification={this.closeErrorNote}
              message="An error occurred while sending the activation code"
            />
          </>
        )}
        {getLicenseError.isError && (
          <SnackBar
            place="bl"
            color="danger"
            icon={Warning}
            autoHideDuration={3000}
            message={getLicenseError.msg}
            open={getLicenseError.isError}
            closeNotification={() =>
              setStripeError({ msg: "", isError: false })
            }
          />
        )}
        {getLicenseError.isError && (
          <SnackBar
            message={getLicenseError.msg}
            open={getLicenseError.isError}
            autoHideDuration={3000}
            closeNotification={() =>
              setStripeError({ msg: "", isError: false })
            }
            place="bl"
            icon={Warning}
            color="danger"
          />
        )}
        <Switch>
          <PrivateRoute path={paths.auth} component={AuthLayout} />
          <PrivateRoute path={paths.admin} component={AdminLayout} />

          <Route
            exact
            path={paths.pricing}
            render={() =>
              authToken ? (
                <Redirect to={paths.authDashboard} />
              ) : (
                <PricingPage />
              )
            }
          />
          <Route
            exact
            path={paths.forgot}
            render={() =>
              authToken ? (
                <Redirect to={paths.authDashboard} />
              ) : (
                <RecoverPage />
              )
            }
          />
          <Route
            exact
            path={paths.login}
            render={() =>
              authToken ? <Redirect to={paths.authDashboard} /> : <LoginPage />
            }
          />
          <Route
            exact
            path={paths.register}
            render={() =>
              authToken ? (
                <Redirect to={paths.authDashboard} />
              ) : (
                <RegisterPage />
              )
            }
          />
          <Redirect from="/" to={paths.authDashboard} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = ({ auth, licenses, isError }) => ({
  isVerifyUser: auth.user.isEmailVerified,
  userEmail: auth.user.email,
  getLicenseError: licenses.getLicenseError,
  isError
});

export default withRouter(
  connect(
    mapStateToProps,
    { dispatchUser, setStripeError, setErrorStatus }
  )(App)
);
