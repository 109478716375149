/* eslint-disable no-unused-vars */
import { withStyles } from "@material-ui/core";
import moment from "moment/moment.js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import dashboardStyle from "../../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import deleteLicense from "../../../../store/actions/deleteLicense/deleteLicense";
import getUser from "../../../../store/actions/getUser/getUser";
import setCurrentLicName from "../../../../store/actions/setCurrentLicName/setCurrentLicName";

import overviewCourse from "../../../../store/actions/overviewCourse/overviewCourse";
import setPillIndex from "../../../../store/actions/setCollapseStatus/setCollapseStatus";

import currVideoIcon from "../../../../assets/img/currVideoIcon.svg";
import DashboardCard from "./DashboardCard/DashboardCard";

class DashboardPill extends Component {
  state = {
    licenses: []
  };

  componentDidUpdate(prevProps, prevState) {
    const { getUser, active } = this.props;

    if (prevProps.active !== active && active === 0) {
      getUser();
    }
  }

  getLicense = () => {
    const { setPillIndex } = this.props;

    setPillIndex(1);
  };

  render() {
    const {
      classes,
      overviewCourse,
      setCurrentLicName,
      user
    } = this.props;

    return (
      <GridContainer justify="center" className={classes.dashGridCont}>
        <GridItem xs={12} sm={12} md={12} c="dashCardCont">
          {Object.keys(user).length && user.licenses.length ? (
            <>
              {user.licenses.map((license, i) => {
                const {
                  completed,
                  currentSlide,
                  licenseTransactionId,
                  name,
                  sectionNumber,
                  ID,
                  progress,
                  cost,
                  expDate,
                  certDescription,
                  puchased,
                  possibleSlides
                } = license;
                const expirationDate = moment(expDate).format("MMM DD YYYY");
                const purchaseDate = moment(puchased).format("MMM DD YYYY");

                let a = null;

                possibleSlides.length &&
                  possibleSlides.map((slide, index) =>
                    slide.img === currentSlide.img ? (a = index + 1) : null
                  );
                const badgeContent = ID ? ID : null;

                return (
                  <GridItem xs={12} sm={12} md={6} key={i} c="dashLicenses">
                    <DashboardCard
                      purchaseDate={purchaseDate}
                      cost={cost}
                      licenseTitle={name}
                      slides={a}
                      slide={
                        currentSlide
                          ? currentSlide.video
                            ? currVideoIcon
                            : currentSlide.img
                          : null
                      }
                      sections={sectionNumber}
                      progress={progress}
                      completed={completed}
                      QRvalue={licenseTransactionId}
                      expDate={expirationDate}
                      click={() => {
                        overviewCourse(true, license);
                        setCurrentLicName(
                          license.name,
                          license.licenseTransactionId
                        );
                      }}
                      certDescription={certDescription}
                      badgeContent={completed ? badgeContent : "IN PROGRESS"}
                    />
                  </GridItem>
                );
              })}
            </>
          ) : null}
          <GridItem xs={12} sm={12} md={6} c="dashLicenses">
            <DashboardCard getLicense />
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

DashboardPill.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    licenses: state.licenses,
    active: state.isCollapse.status
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      setPillIndex,
      overviewCourse,
      getUser,
      setCurrentLicName,
      deleteLicense
    }
  )(withStyles(dashboardStyle)(DashboardPill))
);
