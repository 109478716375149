import { GET_USER } from '../actionTypes/actionTypes';

const dispatchUser = user => {
  return {
    type: GET_USER,
    user
  }
}

export default dispatchUser;
