/* eslint-disable no-unused-vars */
//
import React, { Component } from "react";
import API from "../../../../api";
import {
  dangerColor,
  successColor,
  defaultFont,
  grayColor
} from "../../../../assets/jss/material-dashboard-pro-react";

import s3 from "../../../../utils/digitalOcean";
import config from "../../../../utils/digOceanConfig";

// @material-ui/icons
import { Face, RecordVoiceOver, Email, Smartphone } from "@material-ui/icons";

// @material-ui/core components
import {
  withStyles,
  InputAdornment,
  InputLabel,
  CircularProgress
} from "@material-ui/core";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import axios from "axios";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "1.42857",
    top: 5
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  }
};

class Step1 extends Component {
  state = {
    firstname: "",
    firstnameState: "",
    lastname: "",
    lastnameState: "",
    email: "",
    emailState: "",
    alreadyExistMsg: "",
    isAlreadyResp: false,
    password: "",
    passwordState: "",
    confirmPassword: "",
    confirmPasswordState: "",
    avatar: null,
    loadd: false,
    avatarState: "",
    phone: "",
    phoneState: ""
  };

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  sendState() {
    return this.state;
  }

  verifyEmail = (value) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  verifyPhone = (value, length) => {
    if (value.length === length) {
      return true;
    }
    return false;
  };

  // verifyPh = (value, confirmValue) => value === confirmValue;

  // verifyPh = (len, eq1, eq2) => {
  //   return len >= eq1 && len <= eq2;
  // };

  verifyPassword = (value, confirmValue) => value === confirmValue;

  verifyPasswordLength = (len, eq1, eq2) => {
    return len >= eq1 && len <= eq2;
  };

  onBlurMail = async () => {
    const { emailState, email } = this.state;

    if (emailState === "success") {
      this.cancelTokenSource = axios.CancelToken.source();
      this.setState({ isAlreadyResp: true });

      try {
        const { data } = await API.post("public/isUserRegistered", {
          email
        });

        if (data.found) {
          this.setState({
            emailState: "error",
            alreadyExistMsg: data.message,
            isAlreadyResp: false
          });
        } else {
          this.setState({
            emailState: "success",
            alreadyExistMsg: "",
            isAlreadyResp: false
          });
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          console.error("(: Individual request cancel :)");
        }
      } finally {
        this.cancelTokenSource = null;
      }
    }
  };

  change = (event, stateName, type, stateNameEqualTo) => {
    const { value } = event.target;

    switch (type) {
      case "email":
        if (this.verifyEmail(value)) {
          this.setState({
            [stateName + "State"]: "success",
            alreadyExistMsg: ""
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            alreadyExistMsg: ""
          });
        }
        break;
      case "length":
        if (this.verifyLength(value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phoneLength":
        if (this.verifyPhone(value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      // case "phoneLength":
      //   if (
      //     this.verifyPassword(value, stateNameEqualTo) &&
      //     this.verifyPasswordLength(value.length, 6, 40)
      //   ) {
      //     this.setState({ [stateName + "State"]: "success" });
      //   } else {
      //     this.setState({ [stateName + "State"]: "error" });
      //   }
      //   break;
      case "confirmPass":
        if (
          this.verifyPassword(value, stateNameEqualTo) &&
          this.verifyPasswordLength(value.length, 6, 40)
        ) {
          this.setState({
            passwordState: "success",
            confirmPasswordState: "success"
          });
        } else {
          this.setState({
            passwordState: "error",
            confirmPasswordState: "error"
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: value });
  };

  isValidated = () => {
    const {
      firstnameState,
      lastnameState,
      emailState,
      loadd,
      isAlreadyResp,
      passwordState,
      confirmPasswordState,
      avatarState,
      phoneState
    } = this.state;

    if (
      firstnameState === "success" &&
      lastnameState === "success" &&
      passwordState === "success" &&
      phoneState === "success" &&
      confirmPasswordState === "success" &&
      emailState === "success" &&
      !loadd
      // !isAlreadyResp
    ) {
      return true;
    } else {
      if (firstnameState !== "success") {
        this.setState({ firstnameState: "error" });
      }
      if (lastnameState !== "success") {
        this.setState({ lastnameState: "error" });
      }
      if (passwordState !== "success") {
        this.setState({ passwordState: "error" });
      }
      if (confirmPasswordState !== "success") {
        this.setState({ confirmPasswordState: "error" });
      }
      if (emailState !== "success") {
        this.setState({ emailState: "error" });
      }
      if (phoneState !== "success") {
        this.setState({ phoneState: "error" });
      }
    }
    return false;
  };

  getData = (img, id) => {
    const { firstname, email } = this.state;

    if (img) {
      this.setState({ loadd: true });
      let blob = img;
      const params = {
        Body: blob,
        Bucket: `${config.bucketName}`,
        Key: `${email}/${firstname}/${blob.name}`
      };
      s3.putObject(params)
        .on("build", (request) => {
          request.httpRequest.headers.Host = `${config.digitalOceanSpaces}`;
          request.httpRequest.headers["Content-Length"] = blob.size;
          request.httpRequest.headers["Content-Type"] = blob.type;
          request.httpRequest.headers["x-amz-acl"] = "public-read";
        })
        .send((err) => {
          if (err) return console.log(err);
          const imageUrl = `${
            config.digitalOceanSpaces
          }/statecert/assets/img/${email}/${blob.name}`;

          this.setState({
            [id]: imageUrl,
            loadd: false,
            avatarState: "success"
          });
        });
    } else {
      this.setState({
        [id]: null,
        avatarState: "error",
        loadd: false,
        isSuccessUplaod: false
      });
    }
  };

  errMessagePassHandler = (password, confirmPassword) => {
    const validPassLength = this.verifyPasswordLength(password.length, 6, 20);
    const validConfirmLength = this.verifyPasswordLength(
      confirmPassword.length,
      6,
      20
    );
    const validEqual = this.verifyPassword(password, confirmPassword);
    const equalMessage =
      "Your password and confirmation password do not match.";
    const lengthMessage = "Your password must be at least 6 characters long.";
    let passErr = "";

    if (validPassLength && validConfirmLength && !validEqual) {
      passErr = equalMessage;
    } else if (!validPassLength && !validConfirmLength && validEqual) {
      passErr = lengthMessage;
    } else if (validPassLength && validConfirmLength && validEqual) {
      passErr = "";
    } else {
      passErr = `${equalMessage} ${lengthMessage}`;
    }

    return passErr;
  };

  nextStepHandler = (event) => {
    if (event.key === "Enter") {
      this.props.nextButtonClick();
    }
  };

  render() {
    const {
      firstnameState,
      lastnameState,
      password,
      passwordState,
      confirmPassword,
      confirmPasswordState,
      emailState,
      phoneState,
      alreadyExistMsg,
      isAlreadyResp,
      loadd
    } = this.state;
    const { classes } = this.props;
    const passErr = this.errMessagePassHandler(password, confirmPassword);

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let's start with the basic information (with validation)
          </h4>
        </GridItem>
        <GridItem xs={12} sm={4} c="avatrUpload">
          <InputLabel className={classes.labelRoot} htmlFor="avatar">
            <span>Avatar</span>
          </InputLabel>

          <ImageUpload
            id="avatar"
            avatar
            disabled={
              !(
                firstnameState === "success" &&
                emailState === "success" &&
                !isAlreadyResp
              )
            }
            sendData={this.getData}
            addButtonProps={{
              color: "rose"
            }}
            changeButtonProps={{
              color: "rose",
              round: true
            }}
            removeButtonProps={{
              color: "danger",
              round: true
            }}
            uploadText="Upload avatar here"
          />
          {loadd && <CircularProgress className={classes.loader} size={20} />}
          <InputLabel className={classes.labelRoot} htmlFor="avatar">
            Fill first name and email fields to enable add photo
          </InputLabel>
        </GridItem>
        <GridItem xs={12} sm={8}>
          <GridItem xs={12} sm={12} c="twoInlineCont" c1="inpB">
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={firstnameState === "success"}
                error={firstnameState === "error"}
                labelText={<span>First Name</span>}
                helpText="Your first name must be at least 1 characters long."
                id="firstname"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) =>
                    this.change(event, "firstname", "length", 1),
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Face className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  autoComplete: "off"
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={lastnameState === "success"}
                error={lastnameState === "error"}
                labelText={<span>Last Name</span>}
                helpText="Your last name must be at least 3 characters long."
                id="lastname"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) =>
                    this.change(event, "lastname", "length", 3),
                  autoComplete: "off"
                }}
              />
            </GridItem>
          </GridItem>
          <CustomInput
            success={phoneState === "success"}
            error={phoneState === "error"}
            labelText={<span>Phone Number</span>}
            helpText={"Please enter a valid phone number (ex. 2023243000)"}
            id="phone"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onBlur: this.onBlurMail,
              // onKeyDown: this.nextStepHandler,
              onChange: (event) =>
                this.change(event, "phone", "phoneLength", 10),
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <Smartphone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              autoComplete: "tel"
            }}
          />
          <CustomInput
            success={emailState === "success"}
            error={emailState === "error"}
            labelText={<span>Email</span>}
            helpText={
              alreadyExistMsg
                ? alreadyExistMsg
                : "Please enter a valid email address"
            }
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onBlur: this.onBlurMail,
              // onKeyDown: this.nextStepHandler,
              onChange: (event) => this.change(event, "email", "email"),
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              autoComplete: "email"
            }}
          />

          <GridItem xs={12} sm={12} c="twoInlineCont" c1="inpT">
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={passwordState === "success"}
                error={passwordState === "error"}
                labelText={<span>Password</span>}
                helpText={passErr}
                id="password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) =>
                    this.change(
                      event,
                      "password",
                      "confirmPass",
                      confirmPassword
                    ),
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <RecordVoiceOver className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "new-password"
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={confirmPasswordState === "success"}
                error={confirmPasswordState === "error"}
                labelText={<span>Confirm Password</span>}
                id="confirmPassword"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) =>
                    this.change(
                      event,
                      "confirmPassword",
                      "confirmPass",
                      password
                    ),
                  type: "password",
                  autoComplete: "new-password"
                }}
              />
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(Step1);
