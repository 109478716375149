import axios from "axios";
import API from "../../../api";
import React, { Component } from "react";
import { withStyles, CircularProgress } from "@material-ui/core";

import GraphCharts from "./GraphCharts/GraphCharts";
import StatisticCards from "./StatisticCards/StatisticCards";
import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
// import TableMap from "./TableMap/TableMap";

class AdminDashboard extends Component {
  state = {
    usersRegistered: 0,
    licensesSold: 0,
    completedLicenses: 0,
    isLoad: true,
    // isLoadcompletedLicenses: true,
    // isLoadlicenseAnalythics: true,
    // isLoadusersRegistered: true,
  };

  componentDidMount() {
    this.getStatsCardsData();
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  getStatsCardsData = async () => {
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await API.get("admin/userData", {
        cancelToken: this.cancelTokenSource.token,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      this.setState({
        usersRegistered: data.registeredUsers,
        licensesSold: data.completedLicenses + data.uncompletedLicenses,
        completedLicenses: data.completedLicenses,
        isLoad: false,
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.error("(: Individual request cancel :)");
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };

  // getChartData = (type) => {
  //   const { authToken } = localStorage;
  //   this.cancelTokenSource = axios.CancelToken.source();

  //   (async () => {
  //     try {
  //       this.setState({ [`isLoad${type}`]: true });
  //       const { data } = await API.get(`admin/${type}`, {
  //         cancelToken: this.cancelTokenSource.token,
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       });

  //       this.setState({
  //         [type]: data,
  //         [`isLoad${type}`]: false,
  //       });
  //     } catch (e) {
  //       if (axios.isCancel(e)) {
  //         console.error("(: Individual request cancel :)");
  //       }
  //     } finally {
  //       this.cancelTokenSource = null;
  //     }
  //   })();
  // };

  render() {
    const { classes } = this.props;
    const {
      usersRegistered,
      licensesSold,
      completedLicenses,
      isLoad,
      // isLoadusersRegistered,
      // isLoadlicenseAnalythics,
      // isLoadcompletedLicenses,
    } = this.state;

    return !isLoad ? (
      <>
        {/*<TableMap classes={classes} />*/}
        <StatisticCards
          classes={classes}
          users={usersRegistered}
          completedLicenses={completedLicenses}
          soldLicenses={licensesSold}
        />
        <GraphCharts classes={classes} />
      </>
    ) : (
      <CircularProgress size={20} />
    );
  }
}

export default withStyles(dashboardStyle)(AdminDashboard);
