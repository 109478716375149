import { SET_COLLAPSE_STATUS } from "../../actions/actionTypes/actionTypes";

const isCollapse = (state = 0, action) => {
  const { type, status } = action;
  switch (type) {
    case SET_COLLAPSE_STATUS:
      return {
        ...state,
        status
      };
    default:
      return state;
  }
};

export default isCollapse;
