import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";

// @material-ui/core components
import {
  withStyles,
  AppBar,
  Toolbar,
  Hidden,
  List,
  ListItem,
  Drawer,
  ListItemText
} from "@material-ui/core";

// @material-ui/icons
import {
  Menu,
  Dashboard,
  PersonAdd,
  Fingerprint,
  LockOpen,
} from "@material-ui/icons";

import Button from "../../components/CustomButtons/Button";

import { switchFunction } from "../SwitchFunction/SwitchFunction";

import authNavbarStyle from "../../assets/jss/material-dashboard-pro-react/components/authNavbarStyle.jsx";

class AuthNavbar extends Component {
  state = {
    open: false
  };
  componentDidUpdate(prevProps) {
    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      this.setState({ open: false });
    }
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  logoutHandler = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    localStorage.removeItem("_lr_id_");

    this.props.history.push("/login");
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName =>
    this.props.location.pathname.indexOf(routeName) > -1 ? true : false;

  tryRequire = path => {
    try {
      return require(`${path}`);
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  render() {
    let logoSRC = null;

    logoSRC = switchFunction()

    const { authToken } = localStorage;
    const {
      classes,
      color,
      brandText,
      login,
      register,
      userRole,
      pricing
    } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    const list = (
      <List className={classes.list}>
        {(register || pricing) && !authToken && (
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/login"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: this.activeRoute("/login")
              })}
            >
              <Fingerprint className={classes.listItemIcon} />
              <ListItemText
                primary={"Login"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        )}
        {(login || pricing) && !authToken && (
          <ListItem className={`${classes.listItem} ${classes.regListItem}`}>
            <NavLink
              to={"/register"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: this.activeRoute("/register")
              })}
            >
              <PersonAdd className={classes.listItemIcon} />
              <ListItemText
                primary={"Register"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        )}
        {!login && !register && authToken && (
          <>
            <ListItem className={classes.listItem}>
              <NavLink
                to={"/auth/dashboard"}
                className={cx(classes.navLink, {
                  [classes.navLinkActive]: this.activeRoute("/auth/dashboard")
                })}
              >
                <Dashboard className={classes.listItemIcon} />
                <ListItemText
                  primary={"Dashboard"}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
            {/* Receipt button---------------------------------------*/}
            {/*<ListItem className={classes.listItem}>
              <NavLink
                to={"/auth/receipt"}
                className={cx(classes.navLink, {
                  [classes.navLinkActive]: this.activeRoute("/auth/receipt")
                })}
              >
                <MonetizationOn className={classes.listItemIcon} />
                <ListItemText
                  primary={"Receipt"}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>*/}
            {/*}<ListItem className={classes.listItem}>
              <NavLink
                to={"/auth/account"}
                className={cx(classes.navLink, {
                  [classes.navLinkActive]: this.activeRoute("/auth/account")
                })}
              >
                <Fingerprint className={classes.listItemIcon} />
                <ListItemText
                  primary={"Account"}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>*/}
            {+userRole <= 3 && (
              <ListItem className={classes.listItem}>
                <NavLink
                  to={"/admin/dashboard"}
                  className={cx(classes.navLink, {
                    [classes.navLinkActive]: this.activeRoute("/admin")
                  })}
                >
                  <Fingerprint className={classes.listItemIcon} />
                  <ListItemText
                    primary={"Admin Panel"}
                    disableTypography={true}
                    className={classes.listItemText}
                  />
                </NavLink>
              </ListItem>
            )}
            <ListItem className={classes.listItem} onClick={this.logoutHandler}>
              <NavLink
                to={"/auth/logout"}
                className={cx(classes.navLink, {
                  [classes.navLinkActive]: this.activeRoute("/auth/logout")
                })}
              >
                <LockOpen className={classes.listItemIcon} />
                <ListItemText
                  primary={"Logout"}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          </>
        )}
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={classes.flex}>
              <Button className={classes.title} color="transparent">
                <img src={logoSRC} alt="logo" className={classes.img} />
                <span>{brandText}</span>
              </Button>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <Button className={classes.title} color="transparent">
                <img
                  src={logoSRC}
                  alt="logo"
                  className={classes.img + " " + classes.respImg}
                />
                <span>{brandText}</span>
              </Button>
            </div>
          </Hidden>
          <Hidden smDown>{list}</Hidden>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={this.state.open}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}
AuthNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
const mapStateToProps = state => ({
  userRole: state.auth.user.role
});
export default connect(mapStateToProps)(
  withRouter(withStyles(authNavbarStyle)(AuthNavbar))
);
