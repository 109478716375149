/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createUser from "../../../store/actions/createUser/createUser";
import userRegister from "../../../store/actions/userRegister/userRegister";

import { withStyles } from "@material-ui/core";

// core components
import Wizard from "../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import AuthNavbar from "../../../components/Navbars/AuthNavbar.jsx";
import Step1 from "./WizardSteps/Step1.jsx";
import Step2 from "./WizardSteps/Step2.jsx";
import Step3 from "./WizardSteps/Step3.jsx";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { Error, Email } from "@material-ui/icons";

import regWizardStyle from "../../../assets/jss/material-dashboard-pro-react/views/regWizardStyle.jsx";

class WizardView extends Component {
  state = {
    isLoad: false
  };
  registerHandler = ({
    personal: { firstname, lastname, phone, password, email, avatar },
    address: { street, city, state, zip, county },
    workPlace: {
      businessName,
      streetWK,
      cityWK,
      stateWK,
      zipWK,
      aptWK,
      countyWK
    }
  }) => {
    const { createUser, userRegister } = this.props;

    this.setState({ isLoad: true });
    userRegister(true);
    createUser(
      firstname,
      lastname,
      phone,
      password,
      email,
      street,
      city,
      state,
      zip,
      avatar,
      county,
      {
        businessName,
        countyWK: countyWK + " County",
        address: [streetWK, aptWK, cityWK, stateWK, zipWK]
      }
    )
      .then(() => {
        this.props.history.push("/login");
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoad: false });
      });
  };

  render() {
    const { classes, isRregistrationError } = this.props;
    const { isLoad } = this.state;

    return (
      <>
        <AuthNavbar brandText="Food Licensing" register />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            {/* {registrationError?( */}
            <Snackbar
              message="There is registration with this email"
              open={isRregistrationError}
              place="bl"
              icon={Error}
              color="danger"
            />
            {/* ): null} */}
            <GridContainer justify="center" className={classes.wizardContainer}>
              <GridItem xs={12} sm={12} md={9}>
                <Wizard
                  validate
                  steps={[
                    {
                      stepName: "Personal",
                      stepComponent: Step1,
                      stepId: "personal"
                    },
                    {
                      stepName: "Home address",
                      stepComponent: Step2,
                      stepId: "address"
                    },
                    {
                      stepName: "Work Place",
                      stepComponent: Step3,
                      stepId: "workPlace"
                    }
                  ]}
                  title="Register for an Account"
                  subtitle=""
                  nextButtonClasses={classes.nextBtn}
                  previousButtonClasses={classes.nextBtn}
                  finishButtonClasses={classes.nextBtn}
                  finishButtonText="Register"
                  finishButtonClick={this.registerHandler}
                  isLoad={isLoad}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isRregistrationError: state.isRregistrationError.isError
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { createUser, userRegister }
  )(withStyles(regWizardStyle)(WizardView))
);
