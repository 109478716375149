/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import setErrorStatus from "../../../store/actions/setErrorStatus/setErrorStatus";
import setNoteStatus from "../../../store/actions/setNoteStatus/setNoteStatus";
import API from "../../../api";

// @material-ui/core components
import {
  withStyles,
  InputAdornment,
  Icon,
  Typography,
  CircularProgress
} from "@material-ui/core";

// @material-ui/icons
import { Error, Email } from "@material-ui/icons/index.js";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import SnackBar from "../../../components/Snackbar/Snackbar.jsx";
import AuthNavbar from "../../../components/Navbars/AuthNavbar.jsx";

import loginPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class RecoverPage extends Component {
  state = {
    email: "",
    disabledGoLogin: false, 
    isLoad: false
  };

  changeHandler = ({ target: { value, id } }) => this.setState({ [id]: value });

  closeNote = () => {
    this.props.setErrorStatus(false);
    this.setState({disabledGoLogin: false});
  }

  verifyEmail = value => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  recoverHandler = async () => {
    const { email } = this.state;

    this.setState({isLoad: true, disabledGoLogin: true});

    try {
      const { data } = await API.post("public/recover", {
        email
      });
      
      this.setState({isLoad: false});

      if (data.ok) {
        this.props.setNoteStatus(true);
        this.goLogin();
      } else {
        this.props.setErrorStatus(true);
      }
    } catch (e) {
      console.error(e);
    }

  };

  goLogin = () => this.props.history.push("/login");

  enterLoginHandler = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.recoverHandler();
    }
  };

  render() {
    const { classes, isError } = this.props;
    const { email, disabledGoLogin, isLoad } = this.state;
    const disabled = !this.verifyEmail(email);

    return (
      <>
        <AuthNavbar brandText="Food Licensing" login />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <SnackBar
              message="Email is incorrect"
              open={isError.status}
              close
              autoHideDuration = {1800}
              closeNotification={this.closeNote}
              place="bl"
              icon={Error}
              color="danger"
            />
            <GridContainer justify="center" className={classes.cardContainer}>
              <GridItem xs={12} sm={6} md={4}>
                <Card login>
                  <CardBody>
                    {isLoad ? <CircularProgress className={classes.loader} size = {20} />: null}
                    <form className={classes.form}>
                      <CustomInput
                        labelText="Email address"
                        id="email"
                        changeHandler={this.changeHandler}
                        onKeyDown={this.enterLoginHandler}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          type: "email",
                          value: email,
                          onKeyDown: this.enterLoginHandler
                        }}
                      />
                    </form>
                  </CardBody>
                  <CardFooter className={classes.dirCol}>
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      disabled={disabled}
                      onClick={this.recoverHandler}
                      block
                    >
                      Recover password
                    </Button>

                    <Button 
                      size="sm" 
                      disabled={disabledGoLogin}
                      onClick={this.goLogin}
                    >
                      Go to Login
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </>
    );
  }
}

RecoverPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ isError }) => {
  return {
    isError
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { setErrorStatus, setNoteStatus }
  )(withStyles(loginPageStyle)(RecoverPage))
);
