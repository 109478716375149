import {
  BarChart,
  // DateRange,
  LocalOffer,
  Person,
  // Store,
  Update
} from "@material-ui/icons";
import React from "react";
import Card from "../../../../components/Card/Card";
import CardFooter from "../../../../components/Card/CardFooter";
import CardHeader from "../../../../components/Card/CardHeader";
import CardIcon from "../../../../components/Card/CardIcon";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";

const StatisticCards = ({
  classes,
  users,
  soldLicenses,
  completedLicenses
}) => (
  <GridContainer>
    <GridItem xs={12} sm={6} md={6} lg={4}>
      <Card>
        <CardHeader color="warning" stats icon>
          <CardIcon color="rose">
            <BarChart />
          </CardIcon>
          <p className={classes.cardCategory}>Licenses Sold</p>
          <h3 className={classes.cardTitle}>{soldLicenses}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <LocalOffer />
            This is the total amount of licenses sold
          </div>
        </CardFooter>
      </Card>
    </GridItem>
    <GridItem xs={12} sm={6} md={6} lg={4}>
      <Card>
        <CardHeader color="rose" stats icon>
          <CardIcon color="success">
            <BarChart />
          </CardIcon>
          <p className={classes.cardCategory}>Licenses Completed</p>
          <h3 className={classes.cardTitle}>{completedLicenses}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <LocalOffer />
            This is the total completed of licenses sold
          </div>
        </CardFooter>
      </Card>
    </GridItem>
    {/*<GridItem xs={12} sm={6} md={6} lg={6}>*/}
    {/*  <Card>*/}
    {/*    <CardHeader color="success" stats icon>*/}
    {/*      <CardIcon color="success">*/}
    {/*        <Store />*/}
    {/*      </CardIcon>*/}
    {/*      <p className={classes.cardCategory}>Amount Generated</p>*/}
    {/*      <h3 className={classes.cardTitle}>$14,245</h3>*/}
    {/*    </CardHeader>*/}
    {/*    <CardFooter stats>*/}
    {/*      <div className={classes.stats}>*/}
    {/*        <DateRange />*/}
    {/*        Total generated till last payment*/}
    {/*      </div>*/}
    {/*    </CardFooter>*/}
    {/*  </Card>*/}
    {/*</GridItem>*/}
    <GridItem xs={12} sm={6} md={6} lg={4}>
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info">
            <Person />
          </CardIcon>
          <p className={classes.cardCategory}>Users</p>
          <h3 className={classes.cardTitle}>{users}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <Update />
            Just Updated
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  </GridContainer>
);

export default StatisticCards;
