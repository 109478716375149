/* eslint-disable no-unused-vars */
import { Card, CardActions, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import getLicenseStyle from "../../../assets/jss/material-dashboard-pro-react/views/getLicenseStyle";
import Badge from "../../../components/Badge/Badge.jsx";

import Button from "../../../components/CustomButtons/Button.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

const licenseCard = props => {
  const {
    classes,
    title,
    click,
    expTime,
    disabled,
    orgName,
    price,
    sections,
    slides
  } = props;
  return (
    <GridContainer justify="center" className={classes.lcardContainer}>
      <Card className={classes.card}>
        <GridItem xs={12} sm={12} c="getLHeader">
          <GridItem xs={12} sm={8} style={{ justifyContent: "flex-start" }}>
            <Badge color="success">{orgName}</Badge>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Typography
              color="textSecondary"
              align="center"
              className={classes.titleL}
              gutterBottom
            >
              ${price}
            </Typography>
          </GridItem>
        </GridItem>
        <GridItem xs={12} sm={12} c="twoInlineCont">
          <GridItem xs={12} sm={9}>
            <CardActions className={classes.centerElem}>
              <Typography
                color="textSecondary"
                align="center"
                style={{ marginBottom: 30 }}
                className={classes.titleL}
                gutterBottom
              >
                {title}
              </Typography>
            </CardActions>
            <CardActions className={classes.centerElem}>
              <Typography
                color="textSecondary"
                align="center"
                className={classes.slidesL}
                gutterBottom
              >
                License Length: {expTime} years
              </Typography>
            </CardActions>
            <CardActions className={classes.centerElem}>
              <Typography
                color="textSecondary"
                align="center"
                className={classes.sectionL}
                gutterBottom
              >
                There are {sections} sections in this course
              </Typography>
            </CardActions>
          </GridItem>
          <GridItem xs={12} sm={3} c="startLicense">
            <CardActions className={classes.centerElem}>
              <Button
                color="info"
                size="sm"
                onClick={click}
                disabled={disabled}
              >
                Start
              </Button>
            </CardActions>
          </GridItem>
        </GridItem>
      </Card>
    </GridContainer>
  );
};

licenseCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(getLicenseStyle)(licenseCard);
