const supportAlertStyle = theme => ({
  browserLogo: {
    maxWidth: 120,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 100
    }
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center"
  }
});

export default supportAlertStyle;
