/* eslint-disable no-unused-vars */
import { withStyles, TextField  } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { StripeProvider, Elements } from "react-stripe-elements";
import getLicenseStyle from "../../../../assets/jss/material-dashboard-pro-react/views/getLicenseStyle";

import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import confirmPayment from "../../../../store/actions/confirmPayment/confirmPayment";
import createLicence from "../../../../store/actions/createLicence/createLicence";
import fetchLicence from "../../../../store/actions/fetchLicense/fetchLicense";
import fetchUser from "../../../../store/actions/fetchUser/fetchUser";
import setPillIndex from "../../../../store/actions/setCollapseStatus/setCollapseStatus";

import LicenseCard from "../../LicenseCard/LicenseCard";
import PaymentForm from "../../PaymentForm/PaymentForm.jsx";
import Button from "../../../../components/CustomButtons/Button";

class GetLicensePill extends Component {
  state = {
    licenseTitle: "",
    licenseExp: "",
    licensePrice: "",
    licenseDescription: "",
    id: "",
    isRCodeBtnClick: false,
    rCodeText: "",
    isRCode: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { authToken } = localStorage;
    const { fetchLicence, active } = this.props;

    if (prevProps.active !== active && active === 1) {
      fetchLicence(authToken);
    }
  }

  verifyLength = (value, length) => {
    return value.length >= length;
  };

  change = (event, stateName, type, stateNameEqualTo) => {
    if (type === "length") {
      if (this.verifyLength(event.target.value, stateNameEqualTo)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    }
    this.setState({ [stateName]: event.target.value });
  };

  isValidated = () => {
    const { emailState, streetState } = this.state;

    if (streetState === "success" && emailState === "success") {
      return true;
    } else {
      if (streetState !== "success") {
        this.setState({ streetState: "error" });
      }
    }
    return false;
  };

  startGetLicenseHandler = (
    licenseTitle,
    licenseExp,
    licensePrice,
    id,
    licenseDescription
  ) => {
    this.setState({
      licenseTitle,
      licenseExp,
      licensePrice,
      id,
      licenseDescription
    });
    this.props.confirmPayment(true);
  };

  clickRCode = () => this.setState({isRCodeBtnClick: true});

  changeHandlerInp = ({target: {value}}) => {
    this.setState({rCodeText: value});

    if (value.length > 0) {
      this.setState({isRCode: true});
    } else {
      this.setState({isRCode: false});
    }
  }

  render() {
    const {
      classes,
      licenses: { licenses },
      isStartGetLicense,
      isConfirmPay
    } = this.props;
    const {
      licenseTitle,
      licenseExp,
      licensePrice,
      licenseDescription,
      id,
      isRCodeBtnClick,
      isRCode
    } = this.state;

    return (
      <GridContainer
        style={{ height: isConfirmPay || isStartGetLicense ? "100%" : "auto" }}
        className={classes.cardContainer}
        justify="center"
      >
      {/*
        {!isConfirmPay ? (
          <GridContainer className={classes.getLicenseHead} >
            <GridItem md={6}>
              <TextField
                label="Search"
                variant="outlined"
                margin="dense"
              />
            </GridItem>

            <GridItem md={6}>
              <div className={classes.licenseRCode} >
                <div style={{marginRight: 20}}>
                  {isRCodeBtnClick ? (
                    <TextField
                      label="R-Code"
                      variant="outlined"
                      margin="dense"
                      onChange={this.changeHandlerInp}
                    />
                  ):(
                    "Got an r-code code?"
                  )}
                </div>
                { isRCode ? (
                  <Button
                    color="info"
                  >
                    Redeem
                  </ Button>
                ): (
                <Button
                  color="info"
                  onClick={this.clickRCode}
                >
                  R-Code
                </ Button>
                ) }
              </div>
            </GridItem>
          </GridContainer>
        ): null}
        */}
        {!isConfirmPay ? (
          <>
            <GridItem xs={12} sm={12} md={6} c="cardSell">
              {licenses.map((license, i) => {
                const slides = license.sections
                  .map(section => section.slides && section.slides.length)
                  .reduce((partial_sum, a) => partial_sum + a, 0);
                return (
                  <LicenseCard
                    key={i}
                    title={license.licenseName}
                    orgName={license.orgName}
                    price={license.cost}
                    // disabled={managerBtnDisabled}
                    sections={license.sections.length}
                    slides={slides}
                    expTime={license.expTime}
                    badgeContent={license.version}
                    click={() => {
                      this.startGetLicenseHandler(
                        license.licenseName,
                        license.version,
                        license.cost,
                        license.id,
                        license.description
                      );
                    }}
                  />
                );
              })}
            </GridItem>
          </>
        ) : (
          <StripeProvider
            apiKey={
              process.env.NODE_ENV === "development"
                ? "pk_test_3CldeXJPHnFy5lke0FMTu4wi00zukuwYW3"
                : "pk_live_t5wGnN44gChTcLrTYqnBTLEi00vB5GA6Ev"
            }
          >
            <Elements>
              <PaymentForm
                id={id}
                licenseDescription={licenseDescription}
                licenseExp={licenseExp}
                licensePrice={licensePrice}
                licenseTitle={licenseTitle}
              />
            </Elements>
          </StripeProvider>
        )}
      </GridContainer>
    );
  }
}

GetLicensePill.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth,
    licenses: state.licenses,
    isConfirmPay: state.licenses.isConfirmPay,
    isStartGetLicense: state.licenses.isStartGetLicense,
    active: state.isCollapse.status
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      createLicence,
      fetchLicence,
      setPillIndex,
      confirmPayment,
      fetchUser
    }
  )(withStyles(getLicenseStyle)(GetLicensePill))
);
