import {
  ADD_IMAGE,
  ADD_ANSWER,
  ADD_FIELDS,
  ADD_SECTION,
  ADD_QUESTION,
  EDIT_QUESTION,
  DELETE_SLIDE_PROP,
  EDIT_ANSWER,
  EDIT_SECTION,
  RESET_ADMIN_LICENSE_STATE,
  DELETE_IMAGE,
  DELETE_QUESTION,
  DELETE_ANSWER,
  DELETE_SECTION,
  SET_GET_LICENSE_ERROR,
  GET_SEARCH_LICENSE
} from "../../actions/actionTypes/actionTypes";

export const getSearchLicense = searchedLicense => {
  return {
    type: GET_SEARCH_LICENSE,
    searchedLicense
  };
};

export const setStripeError = getLicenseError => {
  return {
    type: SET_GET_LICENSE_ERROR,
    getLicenseError
  };
};

export const addSection = () => {
  return {
    type: ADD_SECTION
  };
};

export const addQuestion = (id, questionID) => {
  return {
    type: ADD_QUESTION,
    id,
    questionID
  };
};

export const addField = (fieldName, fieldValue) => {
  return {
    type: ADD_FIELDS,
    fieldName,
    fieldValue
  };
};

export const addAnswer = (id, quizIndex) => {
  return {
    type: ADD_ANSWER,
    id,
    quizIndex
  };
};

export const addImages = (id, fieldName, fieldValue) => {
  return {
    type: ADD_IMAGE,
    id,
    fieldName,
    fieldValue
  };
};

export const deleteImage = (id, slideIndex) => {
  return {
    type: DELETE_IMAGE,
    id,
    slideIndex
  };
};

export const deleteSlideProp = (id, slideIndex, delKey, delVal) => {
  return {
    type: DELETE_SLIDE_PROP,
    id,
    slideIndex,
    delKey,
    delVal
  };
};

export const deleteSection = id => {
  return {
    type: DELETE_SECTION,
    id
  };
};

export const deleteQuestion = (id, quizIndex) => {
  return {
    type: DELETE_QUESTION,
    id,
    quizIndex
  };
};

export const deleteAnswer = (id, quizIndex, answerIndex) => {
  return {
    type: DELETE_ANSWER,
    id,
    quizIndex,
    answerIndex
  };
};

export const editQuestion = (id, quizIndex, name, value) => {
  return {
    type: EDIT_QUESTION,
    id,
    quizIndex,
    name,
    value
  };
};

export const editAnswer = (id, quizIndex, answerIndex, answerValue) => {
  return {
    type: EDIT_ANSWER,
    id,
    quizIndex,
    answerIndex,
    answerValue
  };
};

export const editSection = (id, fieldName, fieldValue) => {
  return {
    type: EDIT_SECTION,
    id,
    fieldName,
    fieldValue
  };
};

export const resetState = resetedState => {
  return {
    type: RESET_ADMIN_LICENSE_STATE,
    resetedState
  };
};
