import {
  GET_LICENCES,
  START_GET_LICENSE,
  CONFIRM_PAYMENT,
  START_SLIDE_SHOW,
  OVERVIEW_COURSE,
  START_QUIZ,
  SET_CURRENT_LICENSE_NAME,
  SET_GET_LICENSE_ERROR,
  SET_CURRENT_SLIDE_POSITION
} from "../../actions/actionTypes/actionTypes";

const initialState = {
  licenses: [],
  currentLicense: {},
  currentSlidePosition: 0,
  licenseTransactionId: "",
  currentLicenseName: "",
  isStartGetLicense: false,
  isConfirmPay: false,
  isStartSlideShow: false,
  getLicenseError: { msg: "", isError: false },
  isStartQuiz: false,
  isOverviewCourse: false,
  isCongrats: false
};

const licenses = (state = initialState, action) => {
  const {
    type,
    licenses,
    isStartGetLicense,
    isConfirmPay,
    isStartSlideShow,
    isOverviewCourse,
    isCongrats,
    currentLicense,
    isStartQuiz,
    currentLicenseName,
    currentSlidePosition,
    getLicenseError,
    licenseTransactionId
  } = action;
  switch (type) {
    case SET_GET_LICENSE_ERROR:
      return {
        ...state,
        getLicenseError
      };
    case GET_LICENCES:
      return {
        ...state,
        licenses
      };
    case SET_CURRENT_LICENSE_NAME:
      return {
        ...state,
        currentLicenseName,
        licenseTransactionId
      };
    case SET_CURRENT_SLIDE_POSITION:
      return {
        ...state,
        currentSlidePosition
      };
    case START_GET_LICENSE:
      return {
        ...state,
        isStartGetLicense
      };
    case START_QUIZ:
      return {
        ...state,
        isStartQuiz
      };
    case CONFIRM_PAYMENT:
      return {
        ...state,
        isConfirmPay
      };
    case START_SLIDE_SHOW:
      return {
        ...state,
        isStartSlideShow
      };
    case OVERVIEW_COURSE:
      return {
        ...state,
        isOverviewCourse,
        isCongrats,
        currentLicense
      };
    default:
      return state;
  }
};

export default licenses;
