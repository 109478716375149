import React, { Component } from "react";
import API from "../../../api";
import {
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import axios from "axios";
import moment from "moment";
import { Warning, Payment as PayIcon } from "@material-ui/icons";
//import {Close, Dvr} from "@material-ui/icons"
import GridContainer from "../../../components/Grid/GridContainer";
import SnackBar from "../../../components/Snackbar/Snackbar";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import CardBody from "../../../components/Card/CardBody";
//import CustomInput from "../../../components/CustomInput/CustomInput";
//import Button from "../../../components/CustomButtons/Button";

// Bootstrap component for creating dynamic tables
import Table from "react-bootstrap/Table";
import Pagination from "../../../components/newPagination/Pagination";
import loadingComponent from "../../../components/LoadingComponent/LoadingComponent";

import Button from "react-bootstrap/Button";

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.pageChange = this.pageChange.bind(this);
  }

  state = {
    transactions: [],
    data: [],
    list: [],
    searchVal: "",
    errMessage: "",
    successMsg: "",
    selectVal: 20,
    page: 1,
    sizePerPage: 10,
    totalRecords: null,
    totalPages: null,
    isErrOpen: false,
    isSuccOpen: false,
    isLoad: true,
    testLoad: false,
  };

  componentDidMount() {
    this.getTransactions();
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  getTransactions = async (
    page = this.state.page,
    sizePerPage = this.state.sizePerPage
  ) => {
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      //this.setState({ isLoad: true });
      const { data } = await API.get(
        "admin/transactions/purchased/" + sizePerPage + "/" + page,
        {
          cancelToken: this.cancelTokenSource.token,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const list = data.paginated.map((item) => {
        const { cost, email, licenseName, org, purchasedOn } = item;
        const purchasedOnFormatted = moment(purchasedOn).format("YYYY-MM-DD");

        return {
          licenseName,
          purchasedOn: purchasedOnFormatted,
          cost,
          email,
          // name: `${firstName} ${lastName}`,
          organization: org,
          // actions: (
          //   <div className="actions-center">
          //     <Button
          //       justIcon
          //       round
          //       simple
          //       // onClick={() => this.rowEditHandler(_id)}
          //       color="warning"
          //       className="edit"
          //     >
          //       <Dvr />
          //     </Button>

          //     <Button
          //       justIcon
          //       round
          //       simple
          //       // onClick={() => this.deleteHandler(_id)}
          //       color="danger"
          //       className="remove"
          //     >
          //       <Close />
          //     </Button>
          //   </div>
          //),
        };
      });
      this.setState({
        list,
        isLoad: false,
        testLoad: false,
        selectVal: list.length ? list.length : 10,
        sizePerPage,
        page,
        totalPages: data.pages,
        totalRecords: data.totalLicenses,
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        console.error("(: Individual request cancel :)");
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };

  getTransactionsBySearch = async () => {
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();
    const page = this.state.page;
    const sizePerPage = this.state.sizePerPage;
    const search = this.state.searchVal;
    let url = "";

    try {
      if (search === "") {
        this.getTransactions();
        return;
      } else {
        const { data } = await API.get(
          "admin/transactions/search/purchased/" +
            sizePerPage +
            "/" +
            page +
            "/" +
            search,
          {
            cancelToken: this.cancelTokenSource.token,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const list = data.paginated.map((item) => {
          const { cost, email, licenseName, org, purchasedOn } = item;
          const purchasedOnFormatted = moment(purchasedOn).format("YYYY-MM-DD");

          return {
            licenseName,
            purchasedOn: purchasedOnFormatted,
            cost,
            email,
            // name: `${firstName} ${lastName}`,
            organization: org,
            // actions: (
            //   <div className="actions-center">
            //     <Button
            //       justIcon
            //       round
            //       simple
            //       // onClick={() => this.rowEditHandler(_id)}
            //       color="warning"
            //       className="edit"
            //     >
            //       <Dvr />
            //     </Button>

            //     <Button
            //       justIcon
            //       round
            //       simple
            //       // onClick={() => this.deleteHandler(_id)}
            //       color="danger"
            //       className="remove"
            //     >
            //       <Close />
            //     </Button>
            //   </div>
            //),
          };
        });
        this.setState({
          list,
          isLoad: false,
          testLoad: false,
          selectVal: list.length ? list.length : 10,
          sizePerPage,
          page,
          totalPages: data.pages,
          totalRecords: data.totalLicenses,
        });
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        console.error("(: Individual request cancel :)");
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };

  pageChange(data) {
    const { currentPage, pageLimit } = data;
    this.setState({ testLoad: true });
    if (this.state.searchVal != "") {
      this.getTransactionsBySearch();
    } else {
      this.getTransactions(currentPage, pageLimit);
    }
  }

  changeHandler = ({ target }) => {
    console.log(target.value);
    this.setState({ searchVal: target.value, isLoad: true }, () => {
      this.getTransactionsBySearch();
    });
  };

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.getTransactions(1)
    );
  };

  closeNote = () => this.setState({ isErrOpen: false, isSuccOpen: false });

  filterableData = (item) => {
    const { searchVal } = this.state;
    const { email, licenseName, organization } = item;

    return (
      email.toLowerCase().includes(searchVal) ||
      licenseName.toLowerCase().includes(searchVal) ||
      organization.toLowerCase().includes(searchVal)
    );
  };

  render() {
    const { classes } = this.props;
    const {
      searchVal,
      isLoad,
      list,
      errMessage,
      isErrOpen,
      successMsg,
      isSuccOpen,
    } = this.state;

    return (
      <GridContainer>
        <SnackBar
          message={errMessage}
          open={isErrOpen}
          close
          closeNotification={this.closeNote}
          place="br"
          icon={Warning}
          color="warning"
        />
        <SnackBar
          message={successMsg}
          open={isSuccOpen}
          close
          closeNotification={this.closeNote}
          place="br"
          icon={Warning}
          color="success"
        />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <PayIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Transactions</h4>
            </CardHeader>

            <CardBody>
              <div className={classes.topSection}>
                <FormControl fullWidth className={classes.formControl}>
                  <div className={classes.perPageSection}>
                    <p>Show</p>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      fullWidth
                      className={classes.selectCont}
                      value={this.state.sizePerPage}
                      onChange={this.handleSimple}
                      inputProps={{
                        name: "sizePerPage",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={10}
                      >
                        10
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={20}
                      >
                        20
                      </MenuItem>
                    </Select>
                    <p>entries</p>
                  </div>
                  {/* <CustomInput
                    labelText="Search..."
                    id="search"
                    changeHandler={this.changeHandler}
                    formControlProps={{
                      className: classes.searchCont,
                    }}
                    inputProps={{
                      value: searchVal,
                    }}
                  /> */}
                  <Form.Control
                    type="email"
                    placeholder="Search Email"
                    size="sm"
                    className={classes.searchCont}
                    value={searchVal}
                    onChange={(e) => {
                      this.changeHandler(e);
                    }}
                  />
                </FormControl>
              </div>
              {!isLoad ? (
                <React.Fragment>
                  <Table
                    responsive
                    hover
                    bordered
                    style={{ marginTop: "20px" }}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Purchased</th>
                        <th>Cost</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((obj, index) => (
                        <tr key={index}>
                          <td>{obj.licenseName}</td>
                          <td>{obj.purchasedOn}</td>
                          <td>{obj.cost}</td>
                          <td>{obj.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div style={{ position: "relative" }}>
                    {this.state.testLoad && loadingComponent}
                    <div style={{ marginTop: "20px" }}>
                      <Pagination
                        totalRecords={this.state.totalRecords}
                        pageLimit={this.state.sizePerPage}
                        pageNeighbours={1}
                        currentPage={this.state.page}
                        totalPages={this.state.totalPages}
                        onPageChanged={this.pageChange}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <CircularProgress className={classes.loader} size={20} />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Transactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Transactions);
