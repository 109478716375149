import React from "react";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from "../../../../components/CustomButtons/Button";
import receiptPageStyle from "../../../../assets/jss/material-dashboard-pro-react/views/receiptPageStyle";


const ReceiptItem = ({ certificateItem, classes, handleOpenClick }) => {
    const {
      idPay, 
      from,
      certificate
    } = certificateItem;
    
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.receiptCardHead}>
            {certificate}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.receiptCardBody}>
            {from} {idPay}
          </Typography>
        </CardContent>
        <CardActions>
          <Button 
            onClick = {event => {handleOpenClick(event)}} 
            size="sm" 
            color="info"
          >
            View
          </Button>
        </CardActions>
      </Card>
    );
}

export default withStyles(receiptPageStyle)(ReceiptItem);