import {
  cardTitle
} from "../../material-dashboard-pro-react.jsx";
import checkboxStyles from "../customCheckboxRadioSwitch.jsx";

const quizStyles = theme => ({
  ...checkboxStyles,
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
});

export default quizStyles;
