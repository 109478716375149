export const paths = {
  login: "/login",
  register: "/register",
  pricing: "/pricing-page",
  admin: "/admin",
  auth: "/auth",
  forgot: "/recover",
  adminDashboard: "/admin/dashboard",
  authDashboard: "/auth/dashboard",
  getLicense: "get-license"
};
