// @material-ui/core components
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
// @material-ui/icons
import { Assignment, Close, Dvr, Warning } from "@material-ui/icons";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// Bootstrap component for creating dynamic tables
import Table from "react-bootstrap/Table";
import { Button as BootstrapButton } from "react-bootstrap";
import API from "../../../api";
import adminUsersStyle from "../../../assets/jss/material-dashboard-pro-react/views/adminUsersStyle";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Pagination from "../../../components/newPagination/Pagination";
import SnackBar from "../../../components/Snackbar/Snackbar";
import loadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import Form from "react-bootstrap/Form";

import { ListGroup, Row, Col } from "react-bootstrap";

class User extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    data: undefined,

    errMessage: "",
    successMsg: "",

    isErrOpen: false,
    isSuccOpen: false,
    isLoad: true,
  };

  componentDidMount() {
    this.getUser(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  getUser = async (user_id) => {
    const { classes } = this.props;
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await API.get("admin/users/" + user_id, {
        cancelToken: this.cancelTokenSource.token,
        headers: { Authorization: `Bearer ${authToken}` },
      });
      console.log(data);
      this.setState({ data, isLoad: false });
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error("(: Individual request cancel :)");
      } else {
        console.error(err);
        throw err;
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };

  closeNote = () => this.setState({ isErrOpen: false, isSuccOpen: false });

  render() {
    const { classes } = this.props;
    const {
      data,
      isLoad,
      isErrOpen,
      errMessage,
      successMsg,
      isSuccOpen,
    } = this.state;

    return (
      <GridContainer>
        <SnackBar
          message={errMessage}
          open={isErrOpen}
          close
          autoHideDuration={1800}
          closeNotification={this.closeNote}
          place="br"
          icon={Warning}
          color="warning"
        />
        <SnackBar
          message={successMsg}
          open={isSuccOpen}
          close
          autoHideDuration={1800}
          closeNotification={this.closeNote}
          place="br"
          icon={Warning}
          color="success"
        />

        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {data == undefined
                  ? "User"
                  : `${data.firstName + " " + data.lastName}`}
              </h4>
            </CardHeader>

            <CardBody>
              {!isLoad ? (
                <React.Fragment>
                  <ListGroup variant="flush" style={{ width: "100%" }}>
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>First Name</b>
                        </Col>
                        <Col>
                          <p>{data.firstName}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Last Name</b>
                        </Col>
                        <Col>
                          <p>{data.lastName}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Email</b>
                        </Col>
                        <Col>
                          <p>{data.email}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>County</b>
                        </Col>
                        <Col>
                          <p>{data.county}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>City</b>
                        </Col>
                        <Col>
                          <p>{data.city}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>State</b>
                        </Col>
                        <Col>
                          <p>{data.state}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Zip Code</b>
                        </Col>
                        <Col>
                          <p>{data.zip}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Created Date</b>
                        </Col>
                        <Col>
                          <p>{moment(data.createdDate).format("YYYY/MM/DD")}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Email Verified</b>
                        </Col>
                        <Col>
                          <p>{data.isEmailVerified ? "Yes" : "No"}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Id Verified</b>
                        </Col>
                        <Col>
                          <p>{data.isIdVerified ? "Yes" : "No"}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Business Name</b>
                        </Col>
                        <Col>
                          <p>{data.workPlace.businessName}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Country Working</b>
                        </Col>
                        <Col>
                          <p>{data.workPlace.countyWK}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <b>Address</b>
                        </Col>
                        <Col>
                          <p>{data.workPlace.address.toString()}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </React.Fragment>
              ) : (
                <CircularProgress className={classes.loader} size={20} />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withRouter(withStyles(adminUsersStyle)(User));
