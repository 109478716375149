/* eslint-disable no-unused-vars */
import React, { Component } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "../../components/CustomButtons/Button.jsx";

import defaultImage from "../../assets/img/image_placeholder.jpg";
import defaultAvatar from "../../assets/img/default-avatar.png";

class ImageUpload extends Component {
  state = {
    file: null,
    imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
  };

  handleImageChange = e => {
    e.preventDefault();
    const { files, id } = e.target;
    const { sendData } = this.props;

    let reader = new FileReader();
    let file = files[0];
    if (file) {
      if (file.size > 2e6) {
        alert(`${file.size} is soo big!`);
      } else {
        reader.onloadend = () => {
          sendData(file, id);

          this.setState({
            file,
            imagePreviewUrl: reader.result
          });
        };
      }

      reader.readAsDataURL(file);
    }
  };

  handleClick = () => {
    this.refs.fileInput.click();
  };

  handleRemove = id => {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.props.sendData(null, id);
    this.refs.fileInput.value = null;
  };

  render() {
    const {
      avatar,
      addButtonProps,
      disabled,
      changeButtonProps,
      removeButtonProps,
      uploadText,
      className,
      imagePreviewUrl,
      id
    } = this.props;
    const srcc = imagePreviewUrl ? imagePreviewUrl : this.state.imagePreviewUrl;
    return (
      <div className="fileinput text-center">
        <input
          type="file"
          id={id}
          onChange={this.handleImageChange}
          accept="image/*"
          ref="fileInput"
        />
        <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img src={srcc} className={className ? className : ""} alt="..." />
        </div>
        <div>
          {this.state.file === null ? (
            <Button
              disabled={disabled}
              {...addButtonProps}
              onClick={() => this.handleClick()}
            >
              {avatar ? "Add Photo" : uploadText}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                Change
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                id={id}
                onClick={() => this.handleRemove(id)}
              >
                <i className="fas fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default ImageUpload;
