/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import API from "../../../../api";

import s3 from "../../../../utils/digitalOcean";
import config from "../../../../utils/digOceanConfig";

// @material-ui/core components
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from "@material-ui/core";

import classNames from "classnames";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import { counties, states } from "../../../../variables/general";
import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import {
  dangerColor,
  defaultFont,
  grayColor,
  successColor
} from "../../../../assets/jss/material-dashboard-pro-react";
import axios from "axios";
import dispatchUser from "../../../../store/actions/dispatchUser/dispatchUser";

const style = theme => ({
  ...customSelectStyle,
  selectFormControl: {
    margin: 0
  },
  select: {
    textTransform: "none",
    fontSize: 14
  },
  accContainer: {
    margin: "0 !important",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down(992)]: {
      flexDirection: "column",
      flexWrap: "nowrap"
    }
  },
  changePassBtn: {
    textTransform: "capitalize",
    margin: 0
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "1.42857",
    top: 5
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  }
});

class AccountPage extends Component {
  state = {
    firstName: "",
    firstNameState: "",
    lastName: "",
    lastNameState: "",
    email: "",
    emailState: "",
    city: "",
    cityState: "",
    state: "",
    stateState: "",
    county: "",
    countyState: "",
    zip: "",
    zipState: "",
    street: "",
    streetState: "",
    apt: "",
    aptState: "",
    password: "",
    passwordState: "",
    confirmPassword: "",
    confirmPasswordState: "",
    avatar: null,
    avatarState: "",
    loadd: false,
    isFinishUp: false,
    isFill: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { active, user } = this.props;
    if (
      (active === 2 && !this.state.isFill && prevProps.user !== user) ||
      prevProps.active !== active
    ) {
      this.accountAutoFill();
    }
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  accountAutoFill = () => {
    const {
      firstName,
      lastName,
      avatar,
      street,
      city,
      state,
      zip,
      email,
      county
    } = this.props.user;

    const streetAndApt = street.split(" ");
    const apt = streetAndApt[streetAndApt.length - 1];
    let str = "";
    streetAndApt.forEach((item, i) => {
      if (i === streetAndApt.length - 1) return;
      str += ` ${item}`;
    });

    this.setState({
      isFill: true,
      firstName,
      lastName,
      street: str.replace(/^\s+/g, ""),
      apt,
      city,
      state,
      avatar,
      email,
      zip,
      county,
      emailState: "success",
      firstNameState: "success",
      lastNameState: "success",
      cityState: "success",
      stateState: "success",
      zipState: "success",
      streetState: "success",
      countyState: "success"
    });
  };

  verifyEmail = value => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  verifyPasswordLength = (len, eq1, eq2) => {
    return len >= eq1 && len <= eq2;
  };

  verifyPassword = (value, confirmValue) => value === confirmValue;

  change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "confirmPass":
        if (
          this.verifyPassword(event.target.value, stateNameEqualTo) &&
          this.verifyPasswordLength(event.target.value.length, 6, 40)
        ) {
          this.setState({
            passwordState: "success",
            confirmPasswordState: "success"
          });
        } else {
          this.setState({
            passwordState: "error",
            confirmPasswordState: "error"
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  isValidated = () => {
    const {
      cityState,
      countyState,
      emailState,
      firstNameState,
      lastNameState,
      stateState,
      streetState,
      zipState
    } = this.state;

    if (
      cityState === "success" &&
      countyState === "success" &&
      firstNameState === "success" &&
      lastNameState === "success" &&
      stateState === "success" &&
      zipState === "success" &&
      streetState === "success" &&
      emailState === "success"
    ) {
      return true;
    } else {
      if (streetState !== "success") {
        this.setState({ streetState: "error" });
      }
      if (emailState !== "success") {
        this.setState({ emailState: "error" });
      }
      if (cityState !== "success") {
        this.setState({ cityState: "error" });
      }
      if (zipState !== "success") {
        this.setState({ zipState: "error" });
      }
      if (lastNameState !== "success") {
        this.setState({ lastNameState: "error" });
      }
      if (firstNameState !== "success") {
        this.setState({ firstNameState: "error" });
      }
      if (stateState !== "success") {
        this.setState({ stateState: "error" });
      }
      if (countyState !== "success") {
        this.setState({ countyState: "error" });
      }
    }
    return false;
  };

  getData = (img, id) => {
    const { firstName, email } = this.props.user;

    if (img) {
      this.setState({ loadd: true });
      let blob = img;
      const params = {
        Body: blob,
        Bucket: `${config.bucketName}`,
        Key: `${email}/${firstName}/${blob.name}`
      };
      s3.putObject(params)
        .on("build", request => {
          request.httpRequest.headers.Host = `${config.digitalOceanSpaces}`;
          request.httpRequest.headers["Content-Length"] = blob.size;
          request.httpRequest.headers["Content-Type"] = blob.type;
          request.httpRequest.headers["x-amz-acl"] = "public-read";
        })
        .send(err => {
          if (err) return console.log(err);
          const imageUrl = `${
            config.digitalOceanSpaces
          }/${email}/${firstName}/${blob.name}`;

          this.setState({
            [id]: imageUrl,
            loadd: false,
            avatarState: "success"
          });
        });
    } else {
      this.setState({
        [id]: null,
        avatarState: "error",
        loadd: false,
        isSuccessUplaod: false
      });
    }
  };

  updateAccountHandler = async () => {
    const isValidated = this.isValidated();
    this.cancelTokenSource = axios.CancelToken.source();

    if (isValidated) {
      const { id, authToken } = localStorage;
      const {
        email,
        password,
        passwordState,
        confirmPasswordState,
        avatar,
        zip,
        street,
        city,
        county,
        firstName,
        lastName,
        apt,
        state
      } = this.state;
      const updatedData = {
        email,
        avatar,
        zip,
        street: `${street}  ${apt}`,
        city,
        county,
        firstName,
        lastName,
        state
      };

      if (passwordState === "success" && confirmPasswordState === "success") {
        updatedData.password = password;
      }

      try {
        this.setState({ isFinishUp: true });
        const { data } = await API.put(
          `user/id/${id}`,
          { ...updatedData },
          {
            cancelToken: this.cancelTokenSource.token,
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );

        this.props.dispatchUser(data);
        this.setState({
          isFinishUp: false
        });

        if (passwordState === "success" && confirmPasswordState === "success") {
          this.setState({
            password: "",
            passwordState: "",
            confirmPassword: "",
            confirmPasswordState: ""
          });
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          console.error("(: Individual request cancel :)");
        }
      } finally {
        this.cancelTokenSource = null;
      }
    }
  };

  render() {
    const {
      streetState,
      cityState,
      stateState,
      isFinishUp,
      zipState,
      emailState,
      firstNameState,
      lastNameState,
      passwordState,
      confirmPasswordState,
      firstName,
      lastName,
      avatar,
      confirmPassword,
      password,
      street,
      loadd,
      apt,
      isFill,
      county,
      countyState,
      city,
      state,
      zip,
      email
    } = this.state;
    const { classes } = this.props;

    const error = countyState !== "success" && countyState !== "";
    const success = countyState === "success";
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    const stateLabelClasses = classNames({
      [" " + classes.labelRootError]:
        stateState !== "success" && stateState !== "",
      [" " + classes.labelRootSuccess]:
        stateState === "success" &&
        !(stateState !== "success" && stateState !== "")
    });

    return (
      <GridContainer justify="center" className={classes.accContainer}>
        <GridItem xs={12} sm={3} c="imageSectCont">
          <ImageUpload
            imagePreviewUrl={avatar === "null" ? null : avatar}
            sendData={this.getData}
            id="avatar"
            avatar
            addButtonProps={{
              round: true,
              size: "sm"
            }}
            changeButtonProps={{
              round: true,
              size: "sm"
            }}
            removeButtonProps={{
              color: "danger",
              round: true,
              size: "sm"
            }}
          />
          {/*<Button size="sm" color="rose" className={classes.changePassBtn}>*/}
          {/*  Change Password*/}
          {/*</Button>*/}
        </GridItem>
        <GridItem xs={12} sm={12} md={9} c="updInpCont">
          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={firstNameState === "success"}
                error={firstNameState === "error"}
                id="firstName"
                labelText={<span>First name</span>}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                    this.change(event, "firstName", "length", 3),
                  value: firstName,
                  placeholder: "First name"
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={lastNameState === "success"}
                error={lastNameState === "error"}
                id="lastName"
                labelText={<span>Last name</span>}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                    this.change(event, "lastName", "length", 3),
                  placeholder: "Last name",
                  value: lastName
                }}
              />
            </GridItem>
          </GridItem>

          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={12} sm={6} c="fEnd">
              <CustomInput
                // success={streetState === "success"}
                error={streetState === "error"}
                id="street"
                labelText={<span>Street</span>}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "street", "length", 3),
                  placeholder: "Address 1",
                  value: street
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={2} c="fEnd">
              <CustomInput
                // success={streetState === "success"}
                // error={streetState === "error"}
                id="apt"
                labelText={<span>APT</span>}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "apt"),
                  placeholder: "APT B",
                  value: apt
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} c="fEnd">
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.labelRoot + " " + labelClasses}
                >
                  County
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={county}
                  onChange={event => this.change(event, "county", "length", 2)}
                  inputProps={{
                    name: "county"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    County
                  </MenuItem>
                  {counties.map(county => {
                    return (
                      <MenuItem
                        key={county}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={county}
                      >
                        {county}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
          </GridItem>

          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={12} sm={6} c="fEnd">
              <CustomInput
                success={cityState === "success"}
                error={cityState === "error"}
                id="city"
                labelText={<span>City</span>}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "city", "length", 3),
                  placeholder: "City",
                  value: city
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3} c="fEnd">
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.labelRoot + " " + stateLabelClasses}
                >
                  State
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={state}
                  onChange={event => this.change(event, "state")}
                  inputProps={{
                    name: "state"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose state
                  </MenuItem>
                  {states.map(state => {
                    return (
                      <MenuItem
                        key={state}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={state}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={3} c="fEnd">
              <CustomInput
                success={zipState === "success"}
                error={zipState === "error"}
                labelText={<span>Zip</span>}
                id="zip"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "zip", "length", 4),
                  placeholder: "Zip",
                  value: zip
                }}
              />
            </GridItem>
          </GridItem>
          <CustomInput
            success={emailState === "success"}
            error={emailState === "error"}
            id="s2email"
            labelText={<span>Email</span>}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "email", "email"),
              autoComplete: "new-email",
              placeholder: "Email",
              value: email
            }}
          />
          <GridItem xs={12} sm={12} c="twoInlineCont" c1="inpT">
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={passwordState === "success"}
                error={passwordState === "error"}
                labelText={<span>New password</span>}
                helpText="Leave blank if you don't want to change your password"
                id="password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: password,
                  onChange: event =>
                    this.change(
                      event,
                      "password",
                      "confirmPass",
                      confirmPassword
                    ),
                  type: "password",
                  autoComplete: "new-password"
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={confirmPasswordState === "success"}
                error={confirmPasswordState === "error"}
                labelText={<span>Confirm new password</span>}
                id="confirmPassword"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: confirmPassword,
                  onChange: event =>
                    this.change(
                      event,
                      "confirmPassword",
                      "confirmPass",
                      password
                    ),
                  type: "password",
                  autoComplete: "new-password"
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12} c="updateCont">
            <Button
              onClick={this.updateAccountHandler}
              disabled={!isFill && loadd}
              color="success"
            >
              Update
            </Button>
            {isFinishUp && <CircularProgress size={20} />}
            <Button
              onClick={this.accountAutoFill}
              disabled={!isFill && !isFinishUp}
              color="info"
              size="sm"
            >
              Reset changes
            </Button>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    active: state.isCollapse.status
  };
};

export default connect(
  mapStateToProps,
  { dispatchUser }
)(withStyles(style)(AccountPage));
