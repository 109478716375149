// @material-ui/core components
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
// @material-ui/icons
import { Assignment, Close, Dvr, Warning } from "@material-ui/icons";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
// Bootstrap component for creating dynamic tables
import Table from "react-bootstrap/Table";
import { Button as BootstrapButton } from "react-bootstrap";
import API from "../../../api";
import adminUsersStyle from "../../../assets/jss/material-dashboard-pro-react/views/adminUsersStyle";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Pagination from "../../../components/newPagination/Pagination";
import SnackBar from "../../../components/Snackbar/Snackbar";
import loadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import Form from "react-bootstrap/Form";
class Users extends Component {
  constructor(props) {
    super(props);
    this.pageChange = this.pageChange.bind(this);
  }
  state = {
    data: [],
    list: [],
    searchVal: "",
    errMessage: "",
    successMsg: "",
    selectVal: 10,
    page: 1,
    sizePerPage: 10,
    totalRecords: null,
    totalPages: null,
    isErrOpen: false,
    isSuccOpen: false,
    isLoad: true,
    testLoad: false,
    currentDeletionData: {
      id: null,
      title: "",
      isFilled: false,
      isUserDel: false,
      transactionId: null,
    },
  };

  componentDidMount() {
    this.getUserList();
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  getUser = async (user_id) => {
    const { classes } = this.props;
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await API.get("admin/users/" + user_id, {
        cancelToken: this.cancelTokenSource.token,
        headers: { Authorization: `Bearer ${authToken}` },
      });

      console.log(data);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error("(: Individual request cancel :)");
      } else {
        console.error(err);
        throw err;
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };
  getUserList = async (
    page = this.state.page,
    sizePerPage = this.state.sizePerPage
  ) => {
    const { classes } = this.props;
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await API.get(
        "admin/users/sorted/createdDate/" + sizePerPage + "/" + page,
        {
          cancelToken: this.cancelTokenSource.token,
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const list = data.paginated.map((item) => {
        const {
          _id,
          firstName,
          lastName,
          createdDate,
          isEmailVerified,
          licenses,
          workName,
        } = item;
        const registered = moment(createdDate).format("YYYY/MM/DD");
        const NoOfLicenses = licenses.length;
        console.error();
        return {
          id: _id,
          name: `${firstName} ${lastName}`,
          // organization: "LCDHD",
          license: NoOfLicenses,
          workPlace: workName ? workName[0].businessName : "No Listed Business",
          workZip: workName ? workName[0].address[4] : "No Listed Business",
          registered,
          status: (
            <div className={classes.statusWrapper}>
              <div
                style={{
                  background: isEmailVerified ? "green" : "red",
                }}
                className="status"
              />
              <div className={classes.statusText}>
                {isEmailVerified ? "User Activated" : "User Unactivated"}
              </div>
            </div>
          ),
          actions: (
            <div className={classes.actions}>
              <Button
                justIcon
                round
                simple
                onClick={() => this.rowEditHandler(_id)}
                color="warning"
              >
                <Dvr />
              </Button>

              <Button
                justIcon
                round
                simple
                onClick={() =>
                  this.setCurrentDeletionData(
                    true,
                    _id,
                    null,
                    `${firstName} ${lastName}`,
                    true
                  )
                }
                // onClick={() => this.deleteHandler(_id)}
                color="danger"
                className="remove"
              >
                <Close />
              </Button>
            </div>
          ),
        };
      });
      this.setState({
        list,
        isLoad: false,
        testLoad: false,
        selectVal: list.length,
        sizePerPage,
        page,
        totalPages: data.pages,
        totalRecords: data.totalUsers,
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error("(: Individual request cancel :)");
      } else {
        console.error(err);
        throw err;
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };

  pageChange(data) {
    const { currentPage, pageLimit } = data;
    this.setState({ testLoad: true });
    if (this.state.searchVal != "") {
      this.searchUsers();
    } else {
      this.getUserList(currentPage, pageLimit);
    }
  }

  setCurrentDeletionData = (isFilled, id, transactionId, title, isUserDel) => {
    this.setState({
      currentDeletionData: { isFilled, id, transactionId, title, isUserDel },
    });
  };

  deleteLicense = async () => {
    const {
      currentDeletionData: { id: userId, transactionId: licenseTransactionId },
    } = this.state;
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      await API.patch(
        // "user/licenses",
        {
          userId,
          licenseTransactionId,
        },
        {
          cancelToken: this.cancelTokenSource.token,
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      this.getUserList();

      this.setState({
        isSuccOpen: true,
        isErrOpen: false,
        successMsg: "License successfully deleted",
      });
    } catch (e) {
      this.setState({
        errMessage: "NOT ALLOWED",
        isErrOpen: true,
        isSuccOpen: false,
      });
      if (axios.isCancel(e)) {
        console.error("(: Individual request cancel :)");
      }
      console.error(e);
    } finally {
      this.cancelTokenSource = null;
      this.setCurrentDeletionData(false, null, null, "", false);
    }
  };

  // rowEditHandler = id => {
  //   let obj = this.state.list.find(o => o.id === id);
  //   alert(
  //     "You've clicked EDIT button on \n{ \nName: " +
  //       obj.name +
  //       ", \nOrganization: " +
  //       obj.organization +
  //       ", \nLicense: " +
  //       obj.license +
  //       ", \nRegistered: " +
  //       obj.registered +
  //       ", \nage: " +
  //       obj.status +
  //       ", \nStatus: " +
  //       "\n}."
  //   );
  // };

  deleteHandler = async () => {
    const {
      currentDeletionData: { id },
    } = this.state;
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      await API.delete(`user/${id}`, {
        cancelToken: this.cancelTokenSource.token,
        headers: { Authorization: `Bearer ${authToken}` },
      });

      this.getUserList();
      // const updList = list.filter(item => item.id !== data.id);

      this.setState({
        isSuccOpen: true,
        isErrOpen: false,
        successMsg: "User successfully deleted",
      });
    } catch (e) {
      this.setState({
        errMessage: e.response.data,
        isErrOpen: true,
        isSuccOpen: false,
      });

      if (axios.isCancel(e)) {
        console.error("(: Individual request cancel :)");
      }
      console.error(e);
    } finally {
      this.setCurrentDeletionData(false, null, null, "", false);
      this.cancelTokenSource = null;
    }
  };

  changeHandler = ({ target }) => {
    console.log(target.value);
    this.setState({ searchVal: target.value, isLoad: true }, () => {
      this.searchUsers();
    });
  };

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.getUserList(1)
    );
  };

  closeNote = () => this.setState({ isErrOpen: false, isSuccOpen: false });

  // filterableData = (item) => {
  //   const { searchVal } = this.state;
  //   const { name, organization, license, registered, licenseExp, age } = item;

  //   // const { name, registered } = item;
  //   // const licenseNames = licenses
  //   //   .map(({ name }) => name.toLowerCase())
  //   //   .join(" ");

  //   return (
  //     name.toLowerCase().includes(searchVal) ||
  //     // licenseNames.includes(searchVal) ||
  //     registered.includes(searchVal)
  //     // licenseExp.includes(searchVal) ||
  //     // age.includes(searchVal) ||
  //     // organization.toLowerCase().includes(searchVal)
  //   );
  // };

  searchUsers = async () => {
    const { classes } = this.props;
    const { authToken } = localStorage;
    this.cancelTokenSource = axios.CancelToken.source();
    const page = this.state.page;
    const sizePerPage = this.state.sizePerPage;
    const search = this.state.searchVal;
    let url = "";
    console.log(search);
    try {
      if (search === "") {
        this.getUserList();
        return;
      } else {
        url =
          "admin/users/search/createdDate/" +
          sizePerPage +
          "/" +
          page +
          "/" +
          search;
      }
      const { data } = await API.get(
        url,
        {
          cancelToken: this.cancelTokenSource.token,
          headers: { Authorization: `Bearer ${authToken}` },
        },
        {
          params: {},
        }
      );
      const list = data.paginated.map((item) => {
        const {
          _id,
          firstName,
          lastName,
          createdDate,
          isEmailVerified,
          licenses,
          workName,
        } = item;
        const registered = moment(createdDate).format("YYYY/MM/DD");
        const NoOfLicenses = licenses.length;
        console.error();
        return {
          id: _id,
          name: `${firstName} ${lastName}`,
          // organization: "LCDHD",
          license: NoOfLicenses,
          workPlace: workName ? workName[0].businessName : "No Listed Business",
          workZip: workName ? workName[0].address[4] : "No Listed Business",
          registered,
          status: (
            <div className={classes.statusWrapper}>
              <div
                style={{
                  background: isEmailVerified ? "green" : "red",
                }}
                className="status"
              />
              <div className={classes.statusText}>
                {isEmailVerified ? "User Activated" : "User Unactivated"}
              </div>
            </div>
          ),
          actions: (
            <div className={classes.actions}>
              <Button
                justIcon
                round
                simple
                onClick={() => this.rowEditHandler(_id)}
                color="warning"
              >
                <Dvr />
              </Button>

              <Button
                justIcon
                round
                simple
                onClick={() =>
                  this.setCurrentDeletionData(
                    true,
                    _id,
                    null,
                    `${firstName} ${lastName}`,
                    true
                  )
                }
                // onClick={() => this.deleteHandler(_id)}
                color="danger"
                className="remove"
              >
                <Close />
              </Button>
            </div>
          ),
        };
      });
      this.setState({
        list,
        isLoad: false,
        testLoad: false,
        selectVal: list.length,
        sizePerPage,
        page,
        totalPages: data.pages,
        totalRecords: data.totalUsers,
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error("(: Individual request cancel :)");
      } else {
        console.error(err);
        throw err;
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };
  render() {
    const { classes } = this.props;
    const {
      list,
      isLoad,
      searchVal,
      isErrOpen,
      errMessage,
      successMsg,
      isSuccOpen,
      currentDeletionData,
    } = this.state;

    return (
      <GridContainer>
        <SnackBar
          message={errMessage}
          open={isErrOpen}
          close
          autoHideDuration={1800}
          closeNotification={this.closeNote}
          place="br"
          icon={Warning}
          color="warning"
        />
        <SnackBar
          message={successMsg}
          open={isSuccOpen}
          close
          autoHideDuration={1800}
          closeNotification={this.closeNote}
          place="br"
          icon={Warning}
          color="success"
        />
        <ConfirmationDialog
          open={currentDeletionData.isFilled}
          title={currentDeletionData.title}
          handleClose={() => this.setCurrentDeletionData(false, null, null, "")}
          handleOk={() =>
            currentDeletionData.isUserDel
              ? this.deleteHandler()
              : this.deleteLicense()
          }
        />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users</h4>
            </CardHeader>

            <CardBody>
              <div className={classes.topSection}>
                <FormControl fullWidth className={classes.formControl}>
                  <div className={classes.perPageSection}>
                    <p>Show</p>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      fullWidth
                      className={classes.selectCont}
                      value={this.state.sizePerPage}
                      onChange={this.handleSimple}
                      inputProps={{
                        name: "sizePerPage",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={10}
                      >
                        10
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={20}
                      >
                        20
                      </MenuItem>
                    </Select>
                    <p>entries</p>
                  </div>
                  {/* <CustomInput
                    labelText="Search..."
                    id="search"
                    changeHandler={this.changeHandler}
                    formControlProps={{
                      className: classes.searchCont,
                    }}
                    inputProps={{
                      value: searchVal,
                    }}
                  /> */}
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    size="sm"
                    className={classes.searchCont}
                    value={searchVal}
                    onChange={(e) => {
                      this.changeHandler(e);
                    }}
                  />
                  {/* <BootstrapButton
                    variant="primary"
                    size="sm"
                    style={{ marginLeft: "10px", height: "31px" }}
                  >
                    Search
                  </BootstrapButton> */}
                </FormControl>
              </div>
              {!isLoad ? (
                <React.Fragment>
                  <Table
                    responsive
                    hover
                    bordered
                    style={{ marginTop: "20px" }}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>No of Licenses</th>
                        <th>Work Place</th>
                        <th>Work Zip</th>
                        <th>Registered</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((obj, index) => (
                        <tr
                          key={index}
                          // onClick={() => {
                          //   this.getUser(obj.id);
                          // }}
                        >
                          <td>
                            {" "}
                            <Link to={`/admin/user/${obj.id}`}>
                              {obj.name}
                            </Link>
                          </td>
                          <td>{obj.license}</td>
                          <td>{obj.workPlace}</td>
                          <td>{obj.workZip}</td>
                          <td>{obj.registered}</td>
                          <td>{obj.status}</td>
                          <td>{obj.actions}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div style={{ position: "relative" }}>
                    {this.state.testLoad && loadingComponent}{" "}
                    <Pagination
                      totalRecords={this.state.totalRecords}
                      pageLimit={this.state.sizePerPage}
                      pageNeighbours={1}
                      currentPage={this.state.page}
                      totalPages={this.state.totalPages}
                      onPageChanged={this.pageChange}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <CircularProgress className={classes.loader} size={20} />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withRouter(withStyles(adminUsersStyle)(Users));
