import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor
} from "../../material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "../../material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const registerPageStyle = theme => ({
  ...customCheckboxRadioSwitch,
  congratsContainer: {
    display: "flex",
    flexDirection: "column",
    height: 800,
    alignItems: "center"
  },
  finshBtn: {
    boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(grayColor[0]) +
        ", 0.12)",
    "&:hover,&:focus": {
      boxShadow:
          "0 14px 26px -12px rgba(" +
          hexToRgb(grayColor[0]) +
          ", 0.42), 0 4px 23px 0px rgba(" +
          hexToRgb(blackColor) +
          ", 0.12), 0 8px 10px -5px rgba(" +
          hexToRgb(grayColor[0]) +
          ", 0.2)"
    },
  },
  dropBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  congratsBottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    fontSize: 23,
    padding: 0,
    lineHeight: "1",
    maxWidth: 276,
    width: "100%"
  },
  exitTxt: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#404040"
  },
  sdContainer: {
    ...container,
    position: "relative",
    zIndex: "3",
    margin: "0 auto !important",
    padding: "0 !important",
    [theme.breakpoints.down(1200)]: {
      // width: "945px !important"
    }
  },
  whiteBg: {
    backgroundColor: "#fff !important",
    padding: "20px !important"
  },
  cardSignup: {
    borderRadius: "6px",
    backgroundColor: "#ededed",
    boxShadow:
      "0 -1px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    padding: "40px 30px 10px 10px !important",
    paddingBottom: "45px !important",
    marginTop: 40,
    marginBottom: "0 !important",
    [theme.breakpoints.down(992)]: {
      marginTop: 80,
      paddingLeft: "0 !important",
      paddingRight: "0 !important"
    }
  },
  bodyContainer: {
    // padding: "0 !important"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  overviewHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  titleContainer: {
    width: "100%",
    textAlign: "center",
    "& h1": {
      margin: 0,
      fontSize: 26,
      color: "#000000",
      fontFamily: "Arial"
    },
    "& h4": {
      fontSize: 18,
      display: "flex",
      justifyContent: "flex-end",
      color: "#000000"
    }
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)"
  }
});

export default registerPageStyle;
