import borderIMG from "../../../img/border.png";

const certificateStyle= theme => ({
  certificateContainer: {
    maxWidth: 1758,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${borderIMG})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left top",
    padding: 54,
    paddingTop: 53,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  certificateTop: {
    backgroundColor: "#383D50",
    fontSize: 38,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& h1": {
      fontSize: 76,
      marginBottom: "25px !important",
      marginTop: "28px !important",
      lineHeight: "0.7",
      color: "#ff991a"
    },
    "& p": {
      margin: "0 !important",
      fontSize: 25
    },
    "& h2": {
      fontSize: 40,
      marginTop: "28px !important",
      marginBottom: "20px !important",
      lineHeight: "0.7",
      color: "#fff"
    },
    "& button": {
      textTransform: "lowercase",
      marginBottom: "-30px !important",
      maxWidth: 300,
      fontSize: 20,
      lineHeight: "0.7",
      height: 60,
      width: "100%"
    }
  },
  certificateContent: {
    display: "flex",
    width: "100%",
    paddingTop: 50,
    height: "100%",
    marginTop: 40,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& h4": {
      fontSize: 76,
      color: "#0e193c",
      lineHeight: "0.7",
      fontFamily: "Brush Script Std",
      textAlign: "center",
      paddingBottom: "0",
      paddingTop: 0
    },
    "& p": {
      maxWidth: 480,
      marginTop: 40,
      fontFamily: "Open Sans",
      textAlign: "center",
      color: "#000000",
      width: "100%",
      margin: "0 auto",
      lineHeight: "1.5",
      fontSize: 14
    }
  },
  certBT: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& h5": {
      lineHeight: "0.7 !important",
      padding: "0 !important",
      margin: "0 !important",
      fontWeight: "bold"
    },
    "& p": {
      lineHeight: "0.7 !important",
      padding: "0 !important",
      marginTop: "20px !important",
      fontSize: 18,
    }
  },
  certificateBottom: {
    width: "100%",
    height: "100%",
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  qrCode: {
    width: "100px !important",
    height: "100px !important",
    marginRight: 20,
  },
  certBL: {
    display: "flex",
    alignItems: "center"
  },
  img: {
    width: 50,
    verticalAlign: "middle",
    border: "0",
  }
});

export default certificateStyle;
