export const GET_USER = "GET_USER";
export const USER_REGISTER = "USER_REGISTER";

export const CREATE_LICENCE = "CREATE_LICENCE";
export const GET_LICENCES = "GET_LICENCES";
export const COMPLETE_REG_PROGRESS = "COMPLETE_REG_PROGRESS";

export const SET_COLLAPSE_STATUS = "SET_COLLAPSE_STATUS";
export const SET_ERROR_STATUS = "SET_ERROR_STATUS";
export const START_GET_LICENSE = "START_GET_LICENSE";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const START_SLIDE_SHOW = "START_SLIDE_SHOW";
export const OVERVIEW_COURSE = "OVERVIEW_COURSE";
export const SET_GET_LICENSE_ERROR = "SET_GET_LICENSE_ERROR";

export const ADD_SECTION = "ADD_SECTION";
export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_FIELDS = "ADD_FIELDS";
export const ADD_ANSWER = "ADD_ANSWER";
export const ADD_IMAGE = "ADD_IMAGE";
export const DELETE_SLIDE_PROP = "DELETE_SLIDE_PROP";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_ANSWER = "DELETE_ANSWER";

export const EDIT_QUESTION = "EDIT_QUESTION";
export const EDIT_ANSWER = "EDIT_ANSWER";
export const EDIT_SECTION = "EDIT_SECTION";
export const RESET_ADMIN_LICENSE_STATE = "RESET_ADMIN_LICENSE_STATE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const DELETE_SECTION = "DELETE_SECTION";
export const GET_SEARCH_LICENSE = "GET_SEARCH_LICENSE";
export const START_QUIZ = "START_QUIZ";
export const SET_CURRENT_SLIDE_POSITION = "SET_CURRENT_SLIDE_POSITION";
export const SET_CURRENT_LICENSE_NAME = "SET_CURRENT_LICENSE_NAME";
export const IS_REGISTRATION_ERROR = "IS_REGISTRATION_ERROR";
export const CLEAR_IS_REGISTRATION_ERROR = "CLEAR_IS_REGISTRATION_ERROR";
export const SET_NOTE_STATUS = "SET_NOTE_STATUS";

