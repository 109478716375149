import { 
    IS_REGISTRATION_ERROR,
    CLEAR_IS_REGISTRATION_ERROR 
} from "../../actions/actionTypes/actionTypes";

const initialState = {
    isError: false
  };

const isRregistrationError = (state = initialState, { type, payload }) => {
    
    switch (type) {
        case IS_REGISTRATION_ERROR:
            if (payload === 400) {
                return {
                    ...state,
                    isError: true
                };
            }
            return {
                ...state,
                isError: false
            };
        case CLEAR_IS_REGISTRATION_ERROR:
            return {
                ...state,
                isError: false
            };
        default:
            return state;
    }
}

export default isRregistrationError;