import { START_QUIZ } from "../actionTypes/actionTypes";

const startQuiz = isStartQuiz => {
  return {
    type: START_QUIZ,
    isStartQuiz
  };
};

export default startQuiz;
