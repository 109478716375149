/* eslint-disable no-unused-vars */
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import setPillIndex from "../../store/actions/setCollapseStatus/setCollapseStatus";
import startGetLicense from "../../store/actions/startGetLicense/startGetLicense";
import confirmPayment from "../../store/actions/confirmPayment/confirmPayment";

// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// core components
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";

import navPillsStyle from "../../assets/jss/material-dashboard-pro-react/components/navPillsStyle.jsx";

class NavPills extends Component {
    _isMounted = false;

    componentDidMount() {
        const {history, setPillIndex, isDashboard} = this.props;
        this._isMounted = true;

        if (isDashboard && this._isMounted) {
            switch (history.location.pathname) {
                case "/auth/dashboard":
                    setPillIndex(0);
                    break;
                case "/auth/dashboard/licenses":
                    setPillIndex(1);
                    break;
                case "/auth/dashboard/account":
                    setPillIndex(2);
                    break;
                default:
                    break;
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {isDashboard} = this.props;

        if (prevProps.active !== this.props.active && isDashboard) {
            this.redirectHandler();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    redirectHandler = () => {
        const {history, active} = this.props;

        switch (active) {
            case 0:
                history.push("/auth/dashboard");
                break;
            case 1:
                history.push("/auth/dashboard/licenses");
                break;
            case 2:
                history.push("/auth/dashboard/account");
                break;
            default:
                history.push("/auth/dashboard");
        }
    };

    handleChange = (event, active) => {
        const {startGetLicense, setPillIndex, confirmPayment} = this.props;

        if (this._isMounted) {
            setPillIndex(active);
            startGetLicense(false);
            confirmPayment(false);
        }
    };

    handleChangeIndex = (active, lIndex, { reason }) => {
        const {startGetLicense, setPillIndex, confirmPayment} = this.props;

        if (this._isMounted && reason !== "focus" && reason !== "swipe") {
            setPillIndex(active);
            startGetLicense(false);
            confirmPayment(false);
        }
    };

    render() {
        const {
            classes,
            tabs,
            direction,
            color,
            horizontal,
            alignCenter,
            isConfirmPay,
            license,
            active
        } = this.props;
        const flexContainerClasses = classNames({
            [classes.flexContainer]: true,
            [classes.horizontalDisplay]: horizontal !== undefined
        });

        const tabButtons = (
            <Tabs
                classes={{
                    root: classes.root,
                    fixed: classes.fixed,
                    flexContainer: flexContainerClasses,
                    indicator: classes.displayNone
                }}
                value={+active}
                onChange={this.handleChange}
                centered={alignCenter}
            >
                {tabs.map((prop, key) => {
                    const pillsClasses = classNames({
                        [classes.pills]: true,
                        [classes.horizontalPills]: horizontal !== undefined,
                        [classes.pillsWithIcons]: prop.tabIcon !== undefined
                    });

                    return (
                        <Tab
                            label={prop.tabButton}
                            key={key}
                            classes={{
                                root: pillsClasses,
                                wrapped: classes.labelContainer,
                                wrapper: classes.label,
                                selected: classes[color]
                            }}
                        />
                    );
                })}
            </Tabs>
        );
        const tabContent = (
            <div className={classes.contentWrapper}>
                <SwipeableViews
                    containerStyle={{
                        height: 430,
                        WebkitOverflowScrolling: "touch" // iOS momentum scrolling
                    }}
                    enableMouseEvents={false}
                    // containerStyle={classes.pillContainer}
                    axis={direction === "rtl" ? "x-reverse" : "x"}
                    index={+active}
                    onChangeIndex={this.handleChangeIndex}
                    style={{overflowY: "hidden"}}
                >
                    {tabs.map((prop, key) => {
                        return (
                            <div
                                className={classes.tabContent}
                                style={{height: "100%"}}
                                key={key}
                            >
                                {prop.tabContent}
                            </div>
                        );
                    })}
                </SwipeableViews>
            </div>
        );
        return horizontal !== undefined ? (
            <GridContainer className={classes.cont}>
                <GridItem {...horizontal.tabsGrid} c="tabBtns">
                    {tabButtons}
                </GridItem>
                <GridItem
                    {...horizontal.contentGrid}
                    c={`${
                        (active === 1 && !isConfirmPay) || active === 0 || license
                            ? "tabContent"
                            : "tabContainer"
                        }`}
                >
                    {tabContent}
                </GridItem>
            </GridContainer>
        ) : (
            <>
                {tabButtons}
                {tabContent}
            </>
        );
    }
}

NavPills.defaultProps = {
    active: 0,
    color: "primary"
};

NavPills.propTypes = {
    classes: PropTypes.object.isRequired,
    // index of the default active pill
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabButton: PropTypes.string,
            tabIcon: PropTypes.func,
            tabContent: PropTypes.node
        })
    ).isRequired,
    color: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose"
    ]),
    direction: PropTypes.string,
    horizontal: PropTypes.shape({
        tabsGrid: PropTypes.object,
        contentGrid: PropTypes.object
    }),
    alignCenter: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        active: state.isCollapse.status,
        isConfirmPay: state.licenses.isConfirmPay,
        licenses: state.licenses
    };
};

export default connect(
    mapStateToProps,
    {
        setPillIndex,
        startGetLicense,
        confirmPayment
    }
)(withRouter(withStyles(navPillsStyle)(NavPills)));
