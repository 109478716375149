import React, { Component } from "react";
import { connect } from "react-redux";
import completeRegProgress from "../../../../store/actions/completeRegProgress/completeRegProgress";
//Delete this comment
// @material-ui/core components
//
import {
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

// @material-ui/icons
import classNames from "classnames";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";

import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import {
  dangerColor,
  successColor,
  defaultFont,
  grayColor
} from "../../../../assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { counties, states } from "../../../../variables/general";

const style = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "1.42857",
    top: 5
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  verifyImg: {
    minWidth: 300,
    minHeight: 150
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: 20
  },
  selectFormControl: {
    margin: 0
  },
  selectLabel: {
    color: "#495057",
    lineHeight: "2.7"
  }
};

class Step3 extends Component {
  state = {
    cityWK: "",
    cityWKState: "",
    aptWK: "",
    aptWKState: "",
    stateWK: "Kentucky",
    stateWKState: "",
    zipWK: "",
    zipWKState: "",
    streetWK: "",
    streetWKState: "",
    businessName: "",
    businessNameState: "",
    countyWK: "",
    countyWKState: ""
  };

  sendState() {
    return this.state;
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  nextStepHandler = (event) => {
    if (event.key === "Enter") {
      this.props.nextButtonClick();
    }
  };

  isValidated = () => {
    const {
      cityWKState,
      // stateWKState,
      businessNameState,
      streetWKState,
      zipWKState,
      countyWKState
    } = this.state;

    if (
      cityWKState === "success" &&
      // stateWKState === "success" &&
      businessNameState === "success" &&
      streetWKState === "success" &&
      zipWKState === "success" &&
      countyWKState === "success"
    ) {
      return true;
    } else {
      if (cityWKState !== "success") {
        this.setState({ cityWKState: "error" });
      }
      // if (stateWKState !== "success") {
      //   this.setState({ stateWKState: "error" });
      // }
      if (businessNameState !== "success") {
        this.setState({ businessNameState: "error" });
      }
      if (streetWKState !== "success") {
        this.setState({ streetWKState: "error" });
      }
      if (zipWKState !== "success") {
        this.setState({ zipWKState: "error" });
      }
      if (countyWKState !== "success") {
        this.setState({ countyWKState: "error" });
      }
    }
    return false;
  };

  render() {
    const {
      cityWKState,
      businessNameState,
      streetWKState,
      aptWKState,
      stateWK,
      zipWKState,
      countyWK,
      countyWKState
    } = this.state;

    const { classes } = this.props;
    const error = countyWKState !== "success" && countyWKState !== "";
    const success = countyWKState === "success";
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Where will you be working?</h4>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={businessNameState === "success"}
            error={businessNameState === "error"}
            labelText={<span>Business Name</span>}
            id="businessName"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onKeyDown: this.nextStepHandler,
              onChange: (event) =>
                this.change(event, "businessName", "length", 2)
            }}
          />

          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={8} sm={8}>
              <CustomInput
                success={streetWKState === "success"}
                error={streetWKState === "error"}
                labelText={<span>Work Street Address</span>}
                id="streetWK"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) =>
                    this.change(event, "streetWK", "length", 2)
                }}
              />
            </GridItem>
            <GridItem xs={4} sm={4}>
              <CustomInput
                success={aptWKState === "success"}
                error={aptWKState === "error"}
                labelText={<span>APT</span>}
                id="aptWK"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) => this.change(event, "aptWK")
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={cityWKState === "success"}
                error={cityWKState === "error"}
                labelText={<span>Work City</span>}
                id="cityWK"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) => this.change(event, "cityWK", "length", 2)
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose state
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={stateWK}
                  onChange={(event) => this.change(event, "stateWK")}
                  inputProps={{
                    name: "stateWK"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose state
                  </MenuItem>
                  {states.map((state) => {
                    return (
                      <MenuItem
                        key={state}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={state}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                success={zipWKState === "success"}
                error={zipWKState === "error"}
                labelText={<span>Work Zip</span>}
                maxLength="5"
                id="zipWK"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyDown: this.nextStepHandler,
                  onChange: (event) => this.change(event, "zipWK", "length", 4)
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12} c="twoInlineCont">
            <GridItem xs={12} sm={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  // success={countyWKState === "success"}
                  // error={countyWKState === "error"}
                  htmlFor="simple-select"
                  className={classes.labelRoot + " " + labelClasses}
                >
                  Work County
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={countyWK}
                  onChange={(event) =>
                    this.change(event, "countyWK", "length", 2)
                  }
                  inputProps={{
                    name: "countyWK"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose county
                  </MenuItem>
                  {counties.map((county) => {
                    return (
                      <MenuItem
                        key={county}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={county}
                      >
                        {county}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

export default connect(
  null,
  { completeRegProgress }
)(withStyles(style)(Step3));
