/* eslint-disable no-unused-vars */
import React from "react";

// @material-ui/core components
import { withStyles, Grid } from "@material-ui/core";
import style from "../../assets/jss/material-dashboard-pro-react/components/gridStyles.jsx";

const GridItem = ({ ...props }) => {
  const { classes, children, classN, c, c1 = "", ...rest } = props;

  return (
    <Grid
      item
      {...rest}
      className={`${classes.grid} ${classes[c] ? classes[c] : ""} ${
        classes[c1]
      }`}
    >
      {children}
    </Grid>
  );
};

export default withStyles(style)(GridItem);
