import stateCert from "../../assets/img/StateCert.png";
//
// import lcLogo from "../../assets/img/ky-lcdhd.png";
import ky_trdhd from "../../assets/img/ky-trdhd.png";
import ky_ncdhd from "../../assets/img/ky-ncdhd.png";
import ky_lcdhd from "../../assets/img/ky-lcdhd.png";
import ky_wedco from "../../assets/img/ky-wedco.png";
import ky_muhchd from "../../assets/img/ky-muhchd.png";

// nov 8 addition
import ky_ltdhd from "../../assets/img/ky-ltdhd.png";
import ky_brdhd from "../../assets/img/ky-brdhd.png";
import ky_marchd from "../../assets/img/ky-marchd.png";
import ky_ochd from "../../assets/img/ky-ochd.png";
import ky_lawhd from "../../assets/img/ky-lawhd.png";

// Nov 26 addition
import ky_grdhd from "../../assets/img/ky-grdhd.png";

// Dec 11 addition
import ky_pendhd from "../../assets/img/ky-pendhd.png";
import ky_purdhd from "../../assets/img/ky-purdhd.png";
import ky_cchd from "../../assets/img/ky-cchd.png";
import ky_pchd from "../../assets/img/ky-pchd.png";
import ky_alnchd from "../../assets/img/ky-alnchd.png";
import ky_mchd from "../../assets/img/ky-mchd.png";
import ky_achd from "../../assets/img/ky-achd.png";
import ky_abchd from "../../assets/img/ky-abchd.png";
import ky_bchd from "../../assets/img/ky-bchd.png";
import ky_wchd from "../../assets/img/ky-wchd.png";

// Feb 17 addition
import ky_lchd from "../../assets/img/ky-lchd.png";
import ky_krdhd from "../../assets/img/ky-krdhd.png";
import ky_cvdhd from "../../assets/img/ky-cvdhd.png";
import ky_mgry from "../../assets/img/ky-mgry.png";
import ky_byle from "../../assets/img/ky-byle.png";

// tues june 22 addition
import ky_jchd from "../../assets/img/ky-jchd.png";

// Sept 28 addition
import ky_gchc from "../../assets/img/ky-gchc.png";

// July 12, 2022
import ky_madisonchd from "../../assets/img/ky-madisonchd.png";

const subDomain = window.location.host.split(".")[0];

export const switchFunction = () => {
  if (window.location.hostname === "localhost") {
    return stateCert;
  } else {
    switch (subDomain) {
      case "ky-trdhd":
        return ky_trdhd;
      case "ky-ncdhd":
        return ky_ncdhd;
      case "ky-lcdhd":
        return ky_lcdhd;
      case "ky-wedco":
        return ky_wedco;
      case "ky-muhchd":
        return ky_muhchd;
      // Lincoln Trail
      case "ky-ltdhd":
        return ky_ltdhd;
      // Barron River
      case "ky-brdhd":
        return ky_brdhd;
      // Marshall
      case "ky-marchd":
        return ky_marchd;
      // Oldham
      case "ky-ochd":
        return ky_ochd;
      // Lawrence County
      case "ky-lawhd":
        return ky_lawhd;
      // Green River
      case "ky-grdhd":
        return ky_grdhd;
      // Pennyrile
      case "ky-pendhd":
        return ky_pendhd;
      // Purchase
      case "ky-purdhd":
        return ky_purdhd;
      // Christian
      case "ky-cchd":
        return ky_cchd;
      // Pike County
      case "ky-pchd":
        return ky_pchd;
      // Allen County
      case "ky-alnchd":
        return ky_alnchd;
      // Monroe County
      case "ky-mchd":
        return ky_mchd;
      // Anderson County
      case "ky-achd":
        return ky_achd;
      // Boyd County
      case "ky-abchd":
        return ky_abchd;
      // Breathitt County
      case "ky-bchd":
        return ky_bchd;
      // Anderson County
      case "ky-wchd":
        return ky_wchd;
      // Lincoln County
      case "ky-lchd":
        return ky_lchd;
      // Kentucky River District
      case "ky-krdhd":
        return ky_krdhd;
      // Cumberland Valley District
      case "ky-cvdhd":
        return ky_cvdhd;
      // Montgomery County
      case "ky-mgry":
        return ky_mgry;
      // Boyle County
      case "ky-byle":
        return ky_byle;
      case "ky-jchd":
        return ky_jchd;
      case "ky-gchc":
        return ky_gchc;
      case "ky-madisonchd":
        return ky_madisonchd;
      default:
        return stateCert;
    }
  }
};
