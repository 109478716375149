import setErrorStatus from "../setErrorStatus/setErrorStatus";
import API from "../../../api";

const fetchUser = (email, password) => {
  return async dispatch => {
    try {
      const { data } = await API.post("authenticate", { email, password });
      const { token, _id: id } = data;

      dispatch(setErrorStatus(false));
      localStorage.setItem("authToken", token);
      localStorage.setItem("id", id);
      let url = window.location.origin.substr(8);
      url = `https://${data.subdomain}.${url}`;

      window.location.replace(url);
      window.location.reload();
    } catch (error) {
      dispatch(setErrorStatus(true));
    }
  };
};

export default fetchUser;
