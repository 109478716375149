import { SET_NOTE_STATUS } from "../../actions/actionTypes/actionTypes";

const initialState = {
    status: false
};

const isNote = (state = initialState, { type, status }) => {

    switch (type) {
        case SET_NOTE_STATUS:
            return {
                ...state,
                status
            };
    
        default:
            return state;
    }
}

export default isNote;