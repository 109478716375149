import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import API from "../../../api.js";

// @material-ui/core components
import { withStyles, CircularProgress } from "@material-ui/core";

// @material-ui/icons
import { Home } from "@material-ui/icons";

// core components
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import AuthNavbar from "../../../components/Navbars/AuthNavbar.jsx";

import pricingPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/pricingPageStyle.jsx";

class PricingPage extends Component {
  state = {
    licenses: [],
    isLoad: false
  };

  componentDidMount() {
    this.publicLicenses();
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  publicLicenses = async () => {
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      this.setState({ isLoad: true });
      const { data: licenses } = await API.get("public/license", {
        cancelToken: this.cancelTokenSource.token
      });

      this.setState({ licenses, isLoad: false });
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error("(: Individual request cancel :)");
      } else {
        this.setState({ isLoad: false });

        console.error(err);
        throw err;
      }
    } finally {
      this.cancelTokenSource = null;
    }
  };

  getLicenseHandler = () => {
    const { authToken } = localStorage;
    const redirectPath = authToken ? "/auth/dashboard/licenses" : "/login";

    this.props.history.push(redirectPath);
  };

  render() {
    const { licenses, isLoad } = this.state;
    const { classes } = this.props;

    return (
      <>
        <AuthNavbar brandText="Food Licensing" pricing />
        <div className={classes.container}>
          {licenses.length ? (
            <>
              <GridContainer justify="center">
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    marginTop: 90,
                    marginBottom: 30
                  }}
                >
                  <h2 className={classes.title}>
                    Pick the best license for you
                  </h2>
                  {/*<h5 className={classes.description}>
                    Start your future today
                  </h5>*/}
                </GridItem>
              </GridContainer>
              <GridContainer style={{ margin: 0 }}>
                {licenses.map(license => {
                  const { licenseName, cost, description } = license;

                  return (
                    <GridItem xs={12} sm={12} md={4} key={license.id}>
                      <Card pricing raised>
                        <CardBody pricing>
                          <h6 className={classes.cardCategory}>
                            {licenseName}
                          </h6>
                          <div className={classes.icon}>
                            <Home className={classes.iconRose} />
                          </div>
                          <h3
                            className={`${classes.cardTitle} ${
                              classes.marginTop30
                            }`}
                          >
                            ${cost}
                          </h3>
                          <p className={classes.cardDescription}>
                            {description
                              ? description
                              : "Learn the foundational a manager must know"}
                          </p>
                          <Button
                            round
                            color="rose"
                            onClick={this.getLicenseHandler}
                          >
                            Get Licence
                          </Button>
                        </CardBody>
                      </Card>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </>
          ) : (
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={12}
                md={6}
                style={{
                  marginTop: 170,
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: 30
                }}
              >
                {isLoad ? (
                  <CircularProgress size={20} className={classes.title} />
                ) : (
                  <h2 className={classes.title}>License Not Found</h2>
                )}
              </GridItem>
            </GridContainer>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(pricingPageStyle)(PricingPage));
