import React, { Component } from "react";
import { connect } from "react-redux";
import API from "../../../api";
import overviewCourse from "../../../store/actions/overviewCourse/overviewCourse";
import startQuiz from "../../../store/actions/startQuiz/startQuiz";
import getUser from "../../../store/actions/getUser/getUser";

// @material-ui/core components
import { withStyles, FormControlLabel, Radio } from "@material-ui/core";

// @material-ui/icons
import { FiberManualRecord } from "@material-ui/icons";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";

import successMp3 from "../../../assets/Success-sound-effect.mp3";
import quizStyles from "../../../assets/jss/material-dashboard-pro-react/views/quizStyles";
import axios from "axios";

class Quiz extends Component {
  state = {
    answers: [],
    disabled: false,
    isPlay: false
  };

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  answerQuizHandler = async () => {
    this.cancelTokenSource = axios.CancelToken.source();
    this.setState({ disabled: true });

    try {
      const { answers } = this.state;
      const { authToken } = localStorage;
      const {
        currentLicense,
        licenseTransactionId,
        overviewCourse,
        getUser,
        startQuiz
      } = this.props;

      const { data } = await API.post(
        "quiz/answers",
        {
          userId: currentLicense.userId,
          licenseId: currentLicense.licenseId,
          sectionNumber: Number(currentLicense.sectionNumber),
          licenseTransactionId,
          answers
        },
        {
          cancelToken: this.cancelTokenSource.token,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      const { completed } = data;
      const isCongrats = completed;

      this.setState({ disabled: false });
      startQuiz(false);
      getUser();
      overviewCourse(!completed, data, isCongrats);
    } catch (e) {
      if (axios.isCancel(e)) {
        console.error("(: Individual request cancel :)");
      }

      this.setState({ disabled: true });
      console.error(e);
    } finally {
      this.cancelTokenSource = null;
    }
  };

  handleChangeEnabled = ({ target }, index) => {
    const { answers } = this.state;
    const answer = { id: target.id, userInput: [index] };

    this.setState(
      {
        [target.id]: target.value,
        answers: [...answers, answer]
      },
      () => {
        if (answers.length) {
          answers.map(ans => {
            if (ans.id === answer.id) {
              const filtredAnswers = answers.filter(item => item.id !== ans.id);
              this.setState({
                answers: [...filtredAnswers, answer]
              });
            }
            return true;
          });
        }
      }
    );
  };

  render() {
    const { classes, currentLicense } = this.props;
    const { isPlay, answers, disabled } = this.state;
    const submitDisabled =
      answers.length !== currentLicense.quiz.length || disabled;

    return (
      <GridContainer>
        <Card style={{ marginTop: 0 }}>
          <CardHeader>
            <h2 className={classes.cardTitle}>Quiz</h2>
            {isPlay && <audio autoPlay src={successMp3} />}
          </CardHeader>
        </Card>

        {currentLicense.quiz.map((obj, index) => {
          return (
            <GridContainer key={index} justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader>
                    <h4 className={classes.cardTitle}>Question: {obj.id}</h4>
                  </CardHeader>
                  <CardBody style={{ alignItems: "flex-start" }}>
                    <div>
                      <h6>{obj.question}</h6>
                      <GridContainer>
                        <GridItem xs={12} sm={12}>
                          {obj.answers.map((opt, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  classes.checkboxAndRadio +
                                  " " +
                                  classes.checkboxAndRadioHorizontal
                                }
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={this.state[obj.id] === opt}
                                      onChange={e =>
                                        this.handleChangeEnabled(e, index)
                                      }
                                      value={opt}
                                      id={String(obj.id)}
                                      name="radio button enabled"
                                      aria-label="A"
                                      icon={
                                        <FiberManualRecord
                                          className={classes.radioUnchecked}
                                        />
                                      }
                                      checkedIcon={
                                        <FiberManualRecord
                                          className={classes.radioChecked}
                                        />
                                      }
                                      classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                      }}
                                    />
                                  }
                                  classes={{
                                    label: classes.label
                                  }}
                                  label={opt}
                                />
                              </div>
                            );
                          })}
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          );
        })}

        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={12} md={9}>
            <Button
              color="rose"
              disabled={submitDisabled}
              onClick={this.answerQuizHandler}
            >
              Submit
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  currentLicense: state.licenses.currentLicense,
  licenseTransactionId: state.licenses.licenseTransactionId
});

const mapDispatchToProps = {
  overviewCourse,
  getUser,
  startQuiz
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(quizStyles)(Quiz));
