import LogRocket from 'logrocket';
import thunk from 'redux-thunk';
import {createStore, applyMiddleware, compose} from 'redux';
import reducers from './reducers/rootReducer/rootReducer';

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const store = createStore(reducers,composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware())));

export default store;
