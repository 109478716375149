import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import API from "../../../api";
import axios from "axios";
import startGetLicense from "../../../store/actions/startGetLicense/startGetLicense";
import setPillIndex from "../../../store/actions/setCollapseStatus/setCollapseStatus";
import confirmPayment from "../../../store/actions/confirmPayment/confirmPayment";
import createLicence from "../../../store/actions/createLicence/createLicence";

import {
  Typography,
  withStyles,
  CircularProgress,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";

import ReadMore from "../../../components/ReadMore/ReadMore";
import Badge from "../../../components/Badge/Badge.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import ConfirmPayment from "./ConfirmPayment/ConfirmPayment.jsx";
import {
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  injectStripe
} from "react-stripe-elements";

import getLicenseStyle from "../../../assets/jss/material-dashboard-pro-react/views/getLicenseStyle";
import { setStripeError } from "../../../store/actions/adminLicenseActions/adminLicenseActions";

class PayementForm extends Component {
  state = {
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
    cardNumberError: true,
    cardExpiryError: true,
    cardCvcError: true,
    cardNumFocus: false,
    cardDateFocus: false,
    cardCVCFocus: false,
    cardExpiryState: "",
    cardNumberState: "",
    cardCvcState: "",
    firstname: "",
    firstnameState: "",
    lastname: "",
    lastnameState: "",
    city: "",
    cityState: "",
    state: "",
    stateState: "",
    zip: "",
    zipState: "",
    street: "",
    streetState: "",
    disabled: false,
    isPay: false,
    useProfDetails: false
  };

  componentDidMount() {
    this.profDetailToggler({ target: { checked: true } });
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  handleBlur = () => {
    this.setState({
      cardNumFocus: false,
      cardDateFocus: false,
      cardCVCFocus: false
    });
  };

  handleChange = change => {
    this.setState({
      [change.elementType]: change,
      [`${change.elementType}State`]: change.complete ? "success" : "error"
    });
  };

  handleClick = e => {
    e.preventDefault();
    const { authToken } = localStorage;
    const {
      stripe,
      id: licenseId,
      user,
      setPillIndex,
      setStripeError
    } = this.props;
    const isValidated = this.isValidated();
    const {
      cardNumber: { complete: completeNum },
      cardExpiry: { complete: completeExpiry },
      cardCvc: { complete: completeCvc }
    } = this.state;

    if (stripe) {
      if (completeNum && completeExpiry && completeCvc && isValidated) {
        this.setState({ disabled: true, isErrorText: false }, () => {
          try {
            stripe.createToken().then(async ({ token, error }) => {
              this.cancelTokenSource = axios.CancelToken.source();

              if (error) {
                setStripeError({ msg: error.message, isError: true });
              } else {
                const { id } = token;
                this.setState({ isPay: true });

                try {
                  const { status } = await API.post(
                    "purchase/",
                    {
                      stripeToken: id,
                      stripeEmail: user.email,
                      licenseId
                    },
                    {
                      cancelToken: this.cancelTokenSource.token,
                      headers: { Authorization: `Bearer ${authToken}` }
                    }
                  );

                  if (status === 200) {
                    this.cancelHandler();
                    setPillIndex(0);
                  }
                } catch (e) {
                  this.setState({ isPay: false });
                  setStripeError({ msg: e.response.data, isError: true });
                }
              }
            });
          } catch (e) {
            this.setState({ isPay: false });
            if (axios.isCancel(e)) {
              console.error("(: Individual request cancel :)");
            }
            console.error(e);
          } finally {
            this.cancelTokenSource = null;
          }
        });
      } else {
        this.setState({ isPay: false });
      }
    } else {
      this.setState({ isPay: false });
      console.error("Stripe.js hasn't loaded yet.");
    }

    this.setState({ disabled: false });
  };

  enterSubmHandler = event => {
    if (event.key === "Enter") {
      this.handleClick(event);
    }
  };

  handleFocus = inputName => {
    switch (inputName) {
      case "cardNum":
        this.setState({ cardNumFocus: true });
        break;
      case "cardDate":
        this.setState({ cardDateFocus: true });
        break;
      case "cardCVC":
        this.setState({ cardCVCFocus: true });
        break;
      default:
        break;
    }
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  isValidated = () => {
    const {
      zipState,
      cardNumberState,
      cardCvcState,
      cardExpiryState
    } = this.state;

    if (
      zipState === "success" &&
      cardCvcState === "success" &&
      cardExpiryState === "success" &&
      cardNumberState === "success"
    ) {
      return true;
    } else {
      this.setState({ isErrorText: true });

      if (zipState !== "success") {
        this.setState({ zipState: "error" });
      }
      if (cardCvcState !== "success") {
        this.setState({ cardCvcState: "error" });
      }
      if (cardExpiryState !== "success") {
        this.setState({ cardExpiryState: "error" });
      }
      if (cardNumberState !== "success") {
        this.setState({ cardNumberState: "error" });
      }
    }
    return false;
  };

  createLicenseHandler = (type, price) => {
    const { createLicence, user, setPillIndex } = this.props;

    createLicence(type, price, user);
    setPillIndex(0);
  };

  checkoutHandler = (isConfirmPay, type, price) => {
    const { startGetLicense, confirmPayment } = this.props;
    if (isConfirmPay) {
      startGetLicense(true);
    } else {
      startGetLicense(false);
      confirmPayment(false);
      this.createLicenseHandler(type, price);
    }
  };

  cancelHandler = () => {
    const { confirmPayment, startGetLicense } = this.props;
    confirmPayment(false);
    startGetLicense(false);
    // setPillIndex(0);
  };

  createOptions = () => {
    return {
      style: {
        base: {
          color: "#495057",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.42)"
          }
        }
      }
    };
  };

  profDetailToggler = ({ target }) => {
    const { firstName, lastName, street, city, state, zip } = this.props.user;

    if (target.checked) {
      this.setState({
        firstname: firstName,
        lastname: lastName,
        street,
        city,
        state,
        zip,
        firstnameState: "success",
        lastnameState: "success",
        cityState: "success",
        stateState: "success",
        zipState: "success",
        streetState: "success"
      });
    } else {
      this.setState({
        firstname: "",
        lastname: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        firstnameState: "",
        lastnameState: "",
        cityState: "",
        stateState: "",
        zipState: "error",
        streetState: ""
      });
    }
    this.setState({ useProfDetails: target.checked });
  };

  render() {
    const {
      classes,
      licenseTitle,
      licenseDescription,
      licenseExp,
      licenseType,
      licensePrice,
      isConfirmPay,
      isStartGetLicense
    } = this.props;
    const {
      streetState,
      cityState,
      stateState,
      aptState,
      zipState,
      firstnameState,
      lastnameState,
      firstname,
      lastname,
      street,
      apt,
      city,
      state,
      zip,
      cardExpiryState,
      cardNumberState,
      cardCvcState,
      cardNumFocus,
      cardDateFocus,
      cardCVCFocus,
      disabled,
      useProfDetails,
      isPay
    } = this.state;

    return (
      <>
        <GridItem xs={12} sm={12} c={isConfirmPay ? "cConfirm" : "cHeader"}>
          <GridItem xs={12} sm={12} c="cancelCont">
            <Button onClick={this.cancelHandler}>
              <KeyboardArrowLeft className={classes.icons} /> Cancel
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Typography
              color="textSecondary"
              align="center"
              className={classes.titleL}
            >
              {licenseTitle}
            </Typography>
          </GridItem>

          <GridItem xs={12} sm={12} c="startLicense">
            {/*<Typography
              color="textSecondary"
              align="right"
              className={`${classes.slidesL} ${classes.lcdT} ${classes.getLCD}`}
            >
              LCDHD
            </Typography>*/}
            <Badge color="success" className={classes.lcdT}>
              {licenseExp}
            </Badge>
          </GridItem>
        </GridItem>
        {!isStartGetLicense && (
          <GridItem xs={12} sm={12} c="licenseDescCont">
            <Typography
              color="textSecondary"
              align="center"
              className={classes.sectionL}
            >
              <ReadMore
                ref={this.ReadMore}
                className="read-more-content"
                charLimit={150}
                readMoreText="Read more"
                readLessText="Read less"
              >
                {licenseDescription}
              </ReadMore>
            </Typography>
          </GridItem>
        )}

        <GridItem xs={12} sm={2} c="infoCard">
          <Typography
            align="center"
            className={
              !isStartGetLicense ? classes.detailT : classes.infoTitles
            }
          >
            {!isStartGetLicense ? "Details" : "Card info"}
          </Typography>
          {/*<Typography*/}
          {/*  align="center"*/}
          {/*  style={{ marginBottom: isStartGetLicense ? 44 : 65 }}*/}
          {/*  className={classes.infoTitles}*/}
          {/*>*/}
          {/*  R-Code*/}
          {/*</Typography>*/}
        </GridItem>

        <GridItem xs={12} sm={9}>
          {isStartGetLicense ? (
            <>
              <GridItem xs={12} sm={12} c="cardSection">
                <GridItem xs={12} sm={7}>
                  <small
                    style={{
                      color:
                        cardNumberState === "error"
                          ? "#f44336"
                          : cardNumberState === ""
                          ? "#000"
                          : "#4caf50"
                    }}
                  >
                    (required)
                  </small>
                  <div
                    style={{
                      height: 28,
                      paddingTop: 6,
                      borderBottomWidth: cardNumFocus ? 2 : 1,
                      borderBottomColor: cardNumFocus ? "#9c27b0" : "#D2D2D2",
                      borderBottomStyle: "solid"
                    }}
                  >
                    <CardNumberElement
                      placeholder="Card number"
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                      onFocus={() => this.handleFocus("cardNum")}
                      {...this.createOptions()}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <small
                    style={{
                      color:
                        cardExpiryState === "error"
                          ? "#f44336"
                          : cardExpiryState === ""
                          ? "#000"
                          : "#4caf50"
                    }}
                  >
                    (required)
                  </small>
                  <div
                    style={{
                      height: 28,
                      paddingTop: 6,
                      borderBottomWidth: cardDateFocus ? 2 : 1,
                      borderBottomColor: cardDateFocus ? "#9c27b0" : "#D2D2D2",
                      borderBottomStyle: "solid"
                    }}
                  >
                    <CardExpiryElement
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                      onFocus={() => this.handleFocus("cardDate")}
                      {...this.createOptions()}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <small
                    style={{
                      color:
                        cardCvcState === "error"
                          ? "#f44336"
                          : cardCvcState === ""
                          ? "#000"
                          : "#4caf50"
                    }}
                  >
                    (required)
                  </small>
                  <div
                    style={{
                      height: 28,
                      paddingTop: 6,
                      borderBottomWidth: cardCVCFocus ? 2 : 1,
                      borderBottomColor: cardCVCFocus ? "#9c27b0" : "#D2D2D2",
                      borderBottomStyle: "solid"
                    }}
                  >
                    <CardCVCElement
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                      onFocus={() => this.handleFocus("cardCVC")}
                      {...this.createOptions()}
                    />
                  </div>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      className={classes.chkbLabel}
                      value="end"
                      control={
                        <Checkbox
                          checked={useProfDetails}
                          onChange={this.profDetailToggler}
                          color="primary"
                          className={classes.chkb}
                        />
                      }
                      label="Use profile address for billing address"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} c="twoInlineCont">
                <GridItem xs={12} sm={12}>
                  <CustomInput
                    success={firstnameState === "success"}
                    error={firstnameState === "error"}
                    id="firstname"
                    labelText={<span>First Name</span>}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onKeyDown: this.enterSubmHandler,
                      value: firstname,
                      onChange: event =>
                        this.change(event, "firstname", "length", 3)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <CustomInput
                    success={lastnameState === "success"}
                    error={lastnameState === "error"}
                    id="lastname"
                    labelText={<span>Last Name</span>}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onKeyDown: this.enterSubmHandler,
                      value: lastname,
                      onChange: event =>
                        this.change(event, "lastname", "length", 3)
                    }}
                  />
                </GridItem>
              </GridItem>

              <GridItem xs={12} sm={12} c="twoInlineCont">
                <GridItem xs={12} sm={9}>
                  <CustomInput
                    success={streetState === "success"}
                    error={streetState === "error"}
                    id="street"
                    labelText={<span>Street Address</span>}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onKeyDown: this.enterSubmHandler,
                      value: street,
                      onChange: event =>
                        this.change(event, "street", "length", 3)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    success={aptState === "success"}
                    error={aptState === "error"}
                    id="apt"
                    labelText={<span>APT</span>}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onKeyDown: this.enterSubmHandler,
                      value: apt,
                      onChange: event => this.change(event, "apt", "length", 0)
                    }}
                  />
                </GridItem>
              </GridItem>

              <GridItem xs={12} sm={12} c="twoInlineCont">
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    success={cityState === "success"}
                    error={cityState === "error"}
                    id="city"
                    labelText={<span>City</span>}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onKeyDown: this.enterSubmHandler,
                      value: city,
                      onChange: event => this.change(event, "city", "length", 3)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    success={stateState === "success"}
                    error={stateState === "error"}
                    id="state"
                    labelText={<span>State</span>}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onKeyDown: this.enterSubmHandler,
                      value: state,
                      onChange: event =>
                        this.change(event, "state", "length", 1)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    success={zipState === "success"}
                    error={zipState === "error"}
                    id="zip"
                    labelText={
                      <span>
                        Zip <small>(required)</small>
                      </span>
                    }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onKeyDown: this.enterSubmHandler,
                      value: zip,
                      onChange: event => this.change(event, "zip", "length", 2)
                    }}
                  />
                </GridItem>
              </GridItem>
            </>
          ) : (
            <ConfirmPayment
              licenseTitle={licenseTitle}
              licensePrice={licensePrice}
            />
          )}

          <GridItem xs={12} sm={12} c="paySection">
            {/*<GridItem xs={12} sm={3}>*/}
            {/*  <CustomInput*/}
            {/*    success={stateState === "success"}*/}
            {/*    error={stateState === "error"}*/}
            {/*    id="rCode"*/}
            {/*    formControlProps={{*/}
            {/*      fullWidth: true*/}
            {/*    }}*/}
            {/*    inputProps={{*/}
            {/*      onChange: event => this.change(event, "rCode", "length", 3),*/}
            {/*      placeholder: "R-Code"*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</GridItem>*/}
            <GridItem xs={12} sm={7} c="startLicense">
              <Typography align="center" className={classes.sectionL}>
                {isStartGetLicense
                  ? `You are being charged $${licensePrice}`
                  : `You are being charged for $${licensePrice}`}
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={3} c="payBtn">
              {isPay ? (
                <CircularProgress className={classes.loader} size={20} />
              ) : (
                <Button
                  color="info"
                  size="sm"
                  className={classes.payBtn}
                  disabled={disabled}
                  onClick={e =>
                    !isStartGetLicense
                      ? this.checkoutHandler(
                          !isStartGetLicense,
                          licenseType,
                          licensePrice,
                          "r-01928"
                        )
                      : this.handleClick(e)
                  }
                >
                  {!isStartGetLicense ? "Checkout" : "PAY"}
                </Button>
              )}
            </GridItem>
          </GridItem>
        </GridItem>
      </>
    );
  }
}

PayementForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isConfirmPay: state.licenses.isConfirmPay,
    isStartGetLicense: state.licenses.isStartGetLicense,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, {
  startGetLicense,
  confirmPayment,
  setPillIndex,
  createLicence,
  setStripeError
})(withStyles(getLicenseStyle)(injectStripe(PayementForm)));
