import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { paths } from "../../constants/paths";

// @material-ui/core components
import {
  withStyles
} from "@material-ui/core";

// core components
import Button from "../../components/CustomButtons/Button";

import adminNavbarLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends Component {

  render() {
    const { classes, rtlActive, history } = this.props;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });

    return (
      <div className={wrapper}>
        <Button color="info" onClick={() => history.push(paths.auth)}>
          Go User Demo
        </Button>
        <Button
          onClick={() => {
            localStorage.clear();
            history.push("/login");
            // window.location.reload();
          }}
        >
          Logout
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withRouter(withStyles(adminNavbarLinksStyle)(HeaderLinks));
