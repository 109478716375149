import API from "../../../api";
import moment from "moment";

const createLicence = (type, amountPaid, user) => {
  const expirationDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");
  const { authToken, id: userUid } = localStorage;

  return async () => {
    try {
      await API.post(
        "license/create",
        {
          type,
          expirationDate,
          amountPaid,
          // rCode,
          userUid
        },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export default createLicence;
