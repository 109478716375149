import { START_SLIDE_SHOW } from "../actionTypes/actionTypes";

const setSlideShowStatus = isStartSlideShow => {
  return {
    type: START_SLIDE_SHOW,
    isStartSlideShow
  };
};

export default setSlideShowStatus;
