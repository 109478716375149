import { SET_COLLAPSE_STATUS } from "../actionTypes/actionTypes";

const setPillIndex = status => {
  return {
    type: SET_COLLAPSE_STATUS,
    status
  };
};

export default setPillIndex;
