// @material-ui/icons
import {
  // CalendarToday,
  Dashboard as DashboardIcon,
  Payment as PayIcon,
  // ShowChart,
  SupervisorAccount,
  VerifiedUser,
} from "@material-ui/icons";
import AdminDashboard from "../views/Admin/Dashboard/Dashboard.jsx";
// import Licenses from "../views/Admin/Licenses/Licenses.jsx";
// import Payment from "../views/Admin/Payment/Payment.jsx";
import Users from "../views/Admin/Users/Users.jsx";
import User from "../views/Admin/Users/User.jsx";
import AuthDashboard from "../views/Auth/Dashboard/Dashboard.jsx";

import QuizExample from "../views/Auth/Lecture/Quiz.jsx";
import SlideShow from "../views/Auth/SlideShow/SlideShow.jsx";
import Verification from "../views/Admin/Verification/Verification";
import Transactions from "../views/Admin/Transactions/Transactions";
// import UserProfile from "../views/Auth/UserProfile/UserProfile.jsx";
import Receipt from "../views/Auth/Receipt/Receipt";

const dashRoutes = [
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: AuthDashboard,
    layout: "/auth",
  },
  {
    path: "/slide",
    exact: true,
    name: "Slide",
    rtlName: "لوحة القيادة",
    mini: "SD",
    component: SlideShow,
    layout: "/auth",
  },
  {
    path: "/receipt",
    exact: true,
    name: "Receipt",
    icon: DashboardIcon,
    component: Receipt,
    layout: "/auth",
  },
  // {
  //   name: "Account",
  //   rtlName: "لوحة القيادة",
  //   mini: "AC",
  //   path: "/account",
  //   component: UserProfile,
  //   exact: true,
  //   layout: "/auth"
  // }
];

export const adminRoutes = [
  {
    path: "/dashboard",
    link: "/dashboard",
    exact: true,
    routeName: "dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: AdminDashboard,
    layout: "/admin",
  },
  {
    path: "/transactions",
    link: "/transactions",
    exact: true,
    routeName: "transactions",
    name: "Transactions",
    rtlName: "لوحة القيادة",
    icon: PayIcon,
    component: Transactions,
    layout: "/admin",
  },
  // {
  //   path: "/licenses",
  //   link: "/licenses",
  //   exact: true,
  //   routeName: "licenses",
  //   name: "Licenses",
  //   rtlName: "لوحة القيادة",
  //   icon: ShowChart,
  //   component: Licenses,
  //   layout: "/admin"
  // },
  {
    path: "/users",
    link: "/users",
    exact: true,
    routeName: "users",
    name: "Users",
    rtlName: "لوحة القيادة",
    icon: SupervisorAccount,
    component: Users,
    layout: "/admin",
  },

  {
    path: "/verify/:id",
    link: "/verify",
    exact: true,
    routeName: "verify",
    name: "Verification",
    icon: VerifiedUser,
    component: Verification,
    layout: "/admin",
  },

  {
    path: "/user/id",
    component: User,
    layout: "/admin",
    showOnNavigationBar: false,
  },
  // {
  //   path: "/slides",
  //   exact: true,
  //   name: "Slides",
  //   rtlName: "لوحة القيادة",
  //   icon: CalendarToday,
  //   component: SlideShow,
  //   layout: "/admin"
  // },
  // {
  //   path: "/quiz",
  //   exact: true,
  //   name: "Quizes",
  //   rtlName: "لوحة القيادة",
  //   icon: CalendarToday,
  //   component: QuizExample,
  //   layout: "/admin"
  // }
];

export const quizRoutes = [
  {
    path: "/lecture1",
    component: QuizExample,
    layout: "/quiz",
    showOnNavigationBar: false,
  },
];

export default dashRoutes;
