import { CONFIRM_PAYMENT } from "../actionTypes/actionTypes";

const confirmPayment = isConfirmPay => {
  return {
    type: CONFIRM_PAYMENT,
    isConfirmPay
  };
};

export default confirmPayment;
