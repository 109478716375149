/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import getUser from "../store/actions/getUser/getUser";
import fetchLicence from "../store/actions/fetchLicense/fetchLicense";
import logo from "../assets/img/lc-statecert.png";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AuthNavbar from "../components/Navbars/AuthNavbar.jsx";
import { PrivateRoute } from "../components/PrivateRoute/PrivateRoute";
// import Footer from "../components/Footer/Footer";

import routes from "../constants/routes";

import pagesStyle from "../assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";

class Auth extends Component {
  componentWillMount() {
    const { authToken } = localStorage;
    const { fetchLicence, getUser } = this.props;

    getUser();
    fetchLicence(authToken);
  }

  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <AuthNavbar
          brandText="Food License Course"
          // logoText="KHD Food License"
          // logo={logoSRC}
          {...rest}
        />

        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="/auth" to="/auth/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Auth.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  null,
  { getUser, fetchLicence }
)(withStyles(pagesStyle)(Auth));
