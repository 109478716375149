import React, { Component } from "react";
import {
  Dialog,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import safariLogo from "../../assets/img/safari.png";
import firefoxLogo from "../../assets/img/firefox.png";
import edge from "../../assets/img/edge.png";
import internetExplorer from "../../assets/img/internetExplorer.png";
import browser from "../../assets/img/Browser.png";
import supportAlertStyle from "../../assets/jss/material-dashboard-pro-react/components/supportAlertStyle";
import Button from "../CustomButtons/Button";

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== "undefined";
// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

class SupportAlert extends Component {
  state = {
    open: isFirefox || isSafari || isEdge || isIE
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    let browserLogo = browser;
    let browserName = "This";

    if (isSafari) {
      browserName = "Safari";
      browserLogo = safariLogo;
    } else if (isFirefox) {
      browserName = "Firefox";
      browserLogo = firefoxLogo;
    } else if (isEdge) {
      browserName = "Microsoft Edge";
      browserLogo = edge;
    } else if (isIE) {
      browserName = "Internet Explorer";
      browserLogo = internetExplorer;
    }

    return (
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle className={classes.flexCenter}>
          <img
            alt="browser"
            src={browserLogo}
            className={classes.browserLogo}
          />
        </DialogTitle>
        <DialogContent className={classes.flexCenter}>
          <DialogContentText id="alert-dialog-description">
            {browserName} is currently not supported
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Continue at your own risk
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.flexCenter}>
          <Button size="sm" onClick={this.handleClose} color="info">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(supportAlertStyle)(SupportAlert);
