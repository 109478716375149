import React from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core";
import receiptPageStyle from "../../assets/jss/material-dashboard-pro-react/views/receiptPageStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReceiptPopup(props) {
  const {
    open,
    handleCloseClick,
    classes,
    viewPaid
  } = props;


  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseClick}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          View receipt
        </DialogTitle>
        <div className={classes.dialogImg}></div>
        <DialogContent className={classes.dialogContent}>
          <DialogTitle className={classes.dialogHeadText}>
            Receipt from {viewPaid.from}
          </DialogTitle>
          <Typography variant="body2" className={classes.idPay}>
            Receipt #{viewPaid.idPay}
          </Typography>
          <div className={classes.dialogDetails}>
            <div >
              <Typography className={classes.dialogDetailsHead} >
                AMOUNT PAID
              </Typography>
              <Typography variant="body1" className={classes.dialogDetailsBody}>
                ${viewPaid.paid}
              </Typography>
            </div>
            <div>
              <Typography className={classes.dialogDetailsHead} >
                DATE PAID
              </Typography>
              <Typography variant="body1" className={classes.dialogDetailsBody}>
                {viewPaid.date}
              </Typography>
            </div>
            <div>
              <Typography className={classes.dialogDetailsHead} >
                PAYMENT METHOD
              </Typography>
              <Typography variant="body1" className={classes.dialogDetailsBody}>
                VISA - {viewPaid.method}
              </Typography>
            </div>
          </div>
          <div className={classes.dialogCertificate}>
            <Typography className={`${classes.dialogDetailsHead} ${classes.dialogCertificateHead}`} >
              SUMMARY
            </Typography>
          </div>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
          <div className={classes.dialogCertificateContent}>
            <div className={`${classes.dialogCertificateContentItem} ${classes.dialogCertificateName}`}>
              <Typography>
                {viewPaid.certificate}
              </Typography>
              <Typography>
                ${viewPaid.paid}
              </Typography>
            </div>
            <div className={`${classes.dialogCertificateContentItem} ${classes.dialogCertificateAll}`}>
              <Typography>
                Amount paid
              </Typography>
              <Typography>
                ${viewPaid.paid}
              </Typography>
            </div>
          </div>
          <Typography className={classes.dialogCertificateInfo}>
            If you have any questions, contact us at 
            <Link to=""> ronald.cimala@lcdhd.org </Link> 
            or call at 
            <Link to=""> +1 606-548-2960</Link>.
          </Typography>
          <Typography className={classes.dialogCertificateFooter}>
            Something wrong with the email?
            <Link to="">View it in your browser.</Link>
          </Typography>
          <Typography className={classes.dialogCertificateFooter}>
            You're receiving this email because you made a purchase at
            <Link to=""> Lake Cumberland District Health Department</Link>
            , which partners with 
            <Link to=""> Stripe </Link>
            to provide invoicing and payments processing.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(receiptPageStyle)(ReceiptPopup);