import API from "../../../api";
import { IS_REGISTRATION_ERROR } from "../actionTypes/actionTypes";

const createUser = (
  firstName,
  lastName,
  phone,
  password,
  email,
  street,
  city,
  state,
  zip,
  avatar,
  county,
  workPlace
) => {
  const formData = new FormData();
  console.log(workPlace);
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("phone", phone);
  formData.append("password", password);
  formData.append("email", email);
  formData.append("street", street);
  formData.append("county", county);
  formData.append("city", city);
  formData.append("state", state);
  formData.append("zip", zip);
  formData.append("avatar", avatar);
  formData.append("workPlace", JSON.stringify(workPlace));

  // for (const key in rest) {
  //   if (rest.hasOwnProperty(key)) {
  //     formData.append(value, rest[key]);
  //   }
  // }

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        var a = await API.post("register", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
        console.log(a);
        resolve();
      } catch (e) {
        console.log(e.response);
        reject(e);
        dispatch(isRegistrationError(e.response.status));
      }
    });
};

const isRegistrationError = (error) => ({
  type: IS_REGISTRATION_ERROR,
  payload: error
});

export default createUser;
