import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import API from "../../api";
import overviewCourse from "../../store/actions/overviewCourse/overviewCourse";
import startQuiz from "../../store/actions/startQuiz/startQuiz";
import getUser from "../../store/actions/getUser/getUser";
import makeCarousel from "react-reveal/makeCarousel";

import Button from "../CustomButtons/Button.jsx";
import ReactPlayer from "react-player";
import { withStyles, CircularProgress } from "@material-ui/core";

import { switchFunction } from "../SwitchFunction/SwitchFunction";

import welcomeLayer from "../../assets/img/welcomeLayer.png";
import styles from "../../assets/jss/material-dashboard-pro-react/components/carouselStyles.jsx";

class CarouselUI extends Component {
	state = {
		position: this.props.position,
		isPlay: false,
		disabled: false,
		isWelcome: true,
	};

	componentDidMount() {
		const { possibleSlides, currentSlide, sectionNumber } = this.props;

		const checkForWelcomeMsg =
			sectionNumber === 0 && currentSlide.img === possibleSlides[0].img;
		this.setState({ isWelcome: checkForWelcomeMsg });
		this.setCurrentPosition();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.sectionNumber !== this.props.sectionNumber) {
			this.setState({ position: 1 });
		}
	}

	componentWillUnmount() {
		this.cancelTokenSource && this.cancelTokenSource.cancel();
	}

	setCurrentPosition = () => {
		const { currentSlide, possibleSlides } = this.props;

		possibleSlides.map((slide, index) => {
			if (currentSlide.img && slide.img === currentSlide.img) {
				this.setState({ position: index + 1, isPlay: true });
				return true;
			} else if (currentSlide.video && slide.video === currentSlide.video) {
				this.setState({ position: index + 1, isPlay: true });
				return true;
			}
			return slide;
		});
	};

	nextHandler = async (position) => {
		this.cancelTokenSource = axios.CancelToken.source();
		const {
			possibleSlides,
			overviewCourse,
			licenseTransactionId,
			startQuiz,
		} = this.props;
		const { authToken } = localStorage;
		const currentSlide = possibleSlides[position - 1];
		const isPlay = this.state.isPlay ? true : false;
		this.setState({ isPlay, disabled: true });

		try {
			const { data } = await API.put(
				"user/licenses",
				{
					licenseTransactionId,
					currentSlide,
				},
				{
					cancelToken: this.cancelTokenSource.token,
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				},
			);
			this.setState({ disabled: false });

			overviewCourse(true, data);

			if (data.quiz) {
				startQuiz(true);
			}
		} catch (e) {
			if (axios.isCancel(e)) {
				console.error("(: Individual request cancel :)");
			}
			// this.logoutUser();
		} finally {
			this.cancelTokenSource = null;
		}
	};

	logoutUser = () => {
		localStorage.removeItem("authToken");
		localStorage.removeItem("id");
		localStorage.removeItem("role");
		localStorage.removeItem("_lr_id_");

		this.props.history.push("/login");
	};

	handleClick = (event, isNext) => {
		const { position, isWelcome } = this.state;

		if (isWelcome && isNext) {
			this.setState({ isWelcome: false });
		} else {
			if (!isNext && position - 1 === 0) {
				this.cancelHandler();
			} else {
				const nP = isNext ? position + 1 : position - 1;

				this.setState({ position: nP }, () => {
					const currPs = isNext || position === 2 ? position : position - 2;
					isNext && this.nextHandler(currPs);
				});
			}
		}
	};

	cancelHandler = () => {
		const { overviewCourse, startQuiz, getUser } = this.props;
		getUser();
		overviewCourse(false);
		startQuiz(false);
	};

	render() {
		const { position, isPlay, disabled, isWelcome } = this.state;
		const {
			classes,
			total,
			name,
			possibleSlides,
			completed,
			sectionNumber,
			sectionName,
		} = this.props;
		const currentSlide = possibleSlides[position - 1]
			? possibleSlides[position - 1]
			: possibleSlides[possibleSlides.length - 1];
		const checkForWelcomeMsg =
			sectionNumber === 0 && currentSlide.img === possibleSlides[0].img;
		const btnDisabled = !completed
			? disabled
			: possibleSlides[possibleSlides.length - 1].img === currentSlide.img;
		const backTxt =
			possibleSlides[0] === possibleSlides[position - 1]
				? "Back to dashboard"
				: "Back";

		let logoSRC = null;

		logoSRC = switchFunction()

		return (
			<div
				className={classes.container}
				style={{
					backgroundRepeat: "no-repeat",
					backgroundPosition: "bottom",
					backgroundImage:
						checkForWelcomeMsg && isWelcome ? `url(${welcomeLayer})` : "none",
				}}
			>
				<div>
					{checkForWelcomeMsg && isWelcome ? (
						<div>
							<h2 className={classes.welcomeTitle}>
								Welcome to the {name} Course
							</h2>
							<img className={classes.logoStart} src={logoSRC} alt="logo" />
						</div>
					) : (
							<>
								{currentSlide.video ? (
									<div className="player-wrapper">
										<ReactPlayer
											className="react-player"
											autoPlay
											controls
											playing
											config={{
												youtube: {
													playerVars: { showinfo: 1 },
												},
											}}
											onEnded={(e) =>
												completed &&
													possibleSlides[possibleSlides.length - 1].img ===
													currentSlide.img
													? null
													: this.handleClick(e, true)
											}
											url={currentSlide.video}
											width="100%"
											height="100%"
										/>
									</div>
								) : (
										/*<Video
													autoPlay
													onEnded={e =>
														completed &&
														possibleSlides[possibleSlides.length - 1].img ===
															currentSlide.img
															? null
															: this.handleClick(e, true)
													}
													// poster="https://raw.githubusercontent.com/mderrick/react-html5video/master/demo/assets/poster-sintel-trailer.png"
												>
													<source src={currentSlide.video} type="video/youtube" />
												</Video>*/
										<img
											className={classes.currentSlide}
											src={currentSlide.img}
											alt="current-slide"
										/>
									)}
								{isPlay && currentSlide.audio && (
									<audio
										onEnded={(e) =>
											completed &&
												possibleSlides[possibleSlides.length - 1].img ===
												currentSlide.img
												? null
												: this.handleClick(e, true)
										}
										autoPlay
										src={currentSlide.audio}
									/>
								)}
								<div className={classes.topInfCont}>
									<h4 className={classes.topInfTxt}>{sectionName}</h4>
									{btnDisabled &&
										(!completed &&
											possibleSlides[possibleSlides.length - 1].img !==
											currentSlide.img) && (
											<CircularProgress className={classes.loader} size={20} />
										)}
									<h4 className={classes.topInfTxt}>
										Slide {possibleSlides[position - 1] ? position : total} of{" "}
										{total}
									</h4>
								</div>
							</>
						)}
				</div>

				<div className={classes.btnContainer}>
					{checkForWelcomeMsg && isWelcome ? null : (
						<Button
							className={`${classes.slideBtn} ${classes.leftBtn}`}
							color="rose"
							onClick={(e) => this.handleClick(e, false)}
							data-position={position - 1}
						>
							{backTxt}
						</Button>
					)}

					<Button
						color={checkForWelcomeMsg && isWelcome ? "white" : "rose"}
						className={`${classes.slideBtn} ${classes.rightBtn} ${
							checkForWelcomeMsg && isWelcome ? classes.startBtn : ""
							}`}
						onClick={(e) => this.handleClick(e, true)}
						disabled={btnDisabled}
						round={checkForWelcomeMsg && isWelcome}
						data-position={position + 1}
					>
						{checkForWelcomeMsg && isWelcome ? "Start" : "Next"}
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ licenses }) => {
	const {
		possibleSlides,
		licenseTransactionId,
		currentSlide,
		name,
		sectionNumber,
		sectionName,
		completed,
	} = licenses.currentLicense;

	return {
		possibleSlides,
		sectionName,
		completed,
		name,
		currentSlide,
		sectionNumber,
		licenseTransactionId,
		isStartQuiz: licenses.isStartQuiz,
	};
};

const Carousel = makeCarousel(
	withRouter(
		connect(
			mapStateToProps,
			{ overviewCourse, startQuiz, getUser },
		)(withStyles(styles)(CarouselUI)),
	),
);

export default Carousel;
