const style = theme => ({
  grid: {
    padding: "0 15px !important"
  },
  searchCont: {
    display: "flex",
    alignItems: "flex-end",
    "& button": {
      marginBottom: 0,
      marginLeft: 20
    }
  },
  twoInlineCont: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 !important",
    "& div:first-of-type": {
      paddingLeft: "0 !important"
    },
    "& div:nth-child(2)": {
      paddingRight: "0 !important"
    },
    [theme.breakpoints.down(420)]: {
      width: "70% !important"
    }
  },
  cardSell: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "100%",
    flexBasis: "100%",
    padding: "0 !important"
  },
  tabContainer: {
    boxShadow: "0px 1px 4px rgba(0,0,0,0.14)",
    backgroundColor: "#fff",
    borderRadius: 6
    // padding: "11px !important",
    // marginBottom: "40px !important",
    // marginTop: "20px !important"
  },
  tabBtns: {
    paddingLeft: "0 !important",
    paddingRight: "35px !important",
    [theme.breakpoints.down(960)]: {
      paddingRight: "0 !important"
    }
  },
  smp0: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
      marginTop: 15
    }
  },
  dashCardCont: {
    display: "flex",
    padding: "0 !important",
    flexWrap: "wrap"
  },
  dashLicenses: {
    paddingLeft: 27,
    marginBottom: 15,
    [theme.breakpoints.down(576)]: {
      padding: "0 !important"
    }
  },
  tabContent: {
    paddingRight: "0 !important",
    paddingLeft: "0 !important"
  },
  payBtn: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "0 !important",
    [theme.breakpoints.down(600)]: {
      maxWidth: 85
    }
  },
  sectionItem: {
    margin: 20
  },
  imageSectCont: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down(992)]: {
      maxWidth: "100% !important",
      marginTop: 70,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around"
    }
  },
  updInpCont: {
    [theme.breakpoints.down(992)]: {
      maxWidth: "100% !important"
    }
  },
  updateCont: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down(992)]: {
      marginBottom: 7,
      marginTop: 7
    }
  },
  startLicense: {
    display: "flex",
    position: "relative",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingLeft: "0 !important"
  },
  avatrUpload: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center"
  },
  infoCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  nsItem: {
    padding: "0 !important",
    maxWidth: "100%",
    paddingRight: "0 !important",
    "& div": {
      height: "auto !important"
    }
  },
  currSlideCont: {
    padding: "0 !important",
    maxWidth: "100%",
    marginRight: 10,
    height: 70
  },
  dashWithQr: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 !important"
  },
  cancelCont: {
    display: "flex",
    alignItems: "flex-start",
    "& button": {
      marginTop: 15,
      marginBottom: 0
    }
  },
  cConfirm: {
    display: "flex",
    padding: "0 !important",
    "& div:first-of-type": {
      paddingLeft: "0 !important"
    },
    "& div:nth-child(2)": {
      paddingRight: "0 !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
    // margin: "25px 0"
  },
  cardSection: {
    display: "flex",
    // marginBottom: 13,
    padding: "0 !important",
    "& div:first-of-type": {
      paddingLeft: "0 !important"
    },
    "& div:nth-child(2)": {
      paddingRight: "0 !important"
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      marginTop: 15,
      "& div": {
        padding: "0 !important",
        marginBottom: 5
      }
    }
  },
  licenseDescCont: {
    minHeight: 40
  },
  paySection: {
    display: "flex",
    marginTop: 30,
    justifyContent: "flex-end",
    padding: "0 !important",
    "& div:first-of-type": {
      paddingLeft: "0 !important"
    },
    "& div:nth-child(2)": {
      paddingRight: "0 !important"
    },
    [theme.breakpoints.down(600)]: {
      margin: "15px 0",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 10
    }
  },
  section: {
    margin: "0 auto",
    "& h4": {
      fontSize: 26,
      color: "#3C4858"
    },
    "& h5": {
      fontSize: 18,
      color: "#3C4858"
    }
  },
  getLHeader: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 !important",
    "& div": {
      display: "flex",
      padding: "0 !important",
      justifyContent: "flex-end"
    }
  },
  actionContainer: {
    padding: "0 !important",
    display: "flex",
    alignItems: "flex-end",
    maxHeight: 30,
    justifyContent: "flex-end"
  },
  p0: {
    padding: "0 !important"
  },
  inlineFc: {
    display: "flex",
    // flexDirection: "column",
    // justifyContent: "flex-end",
    alignItems: "center",
    // maxHeight: 30,
    padding: "0 !important"
  },
  cHeader: {
    display: "flex",
    padding: "0 !important",
    "& div:first-of-type": {
      padding: "0 !important",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    "& div:nth-child(2)": {
      paddingRight: "0 !important"
    },
    alignItems: "flex-start",
    maxHeight: 30
  },
  amountS: {
    // marginTop: 40
  },
  fEnd: {
    display: "flex",
    alignItems: "flex-end"
  },
  inpB: {
    marginBottom: 15
  },
  inpT: {
    marginTop: 15
  }
});

export default style;
