import {
  container,
  cardTitle,
  blackColor,
  hexToRgb
} from "../../material-dashboard-pro-react.jsx";

const slideShowStyle = {
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3"
  },
  cardSlideShow: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    marginBottom: "100px",
    padding: "40px 0px",
    marginTop: "15vh"
  },
  cardBodySlide: {
    display: "flex",
    justifyContent: "center"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  nextContainer: {
    padding: "0.9375rem 20px",
    textAlign: "right"
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
    margin: "0 auto"
  },
  mobileStepper: {
    background: "#fff !important"
  }
};

export default slideShowStyle;