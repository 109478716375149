import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import { Provider } from "react-redux";
import setupLogRocketReact from "logrocket-react";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App.jsx";
import store from "./store/store";
import "./assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import "react-html5video/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const css = "@page { size: A4 landscape; }";
const head = document.head || document.getElementsByTagName("head")[0];
const style = document.createElement("style");
style.type = "text/css";
style.media = "print";
style.appendChild(document.createTextNode(css));
head.appendChild(style);

LogRocket.init("zfy95z/statecert");
setupLogRocketReact(LogRocket);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
