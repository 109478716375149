import { COMPLETE_REG_PROGRESS } from "../actionTypes/actionTypes";

const completeRegProgress = completeProgress => {
  return {
    type: COMPLETE_REG_PROGRESS,
    completeProgress
  };
};

export default completeRegProgress;
