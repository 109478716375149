import { START_GET_LICENSE } from "../actionTypes/actionTypes";

const startGetLicense = isStartGetLicense => {
  return {
    type: START_GET_LICENSE,
    isStartGetLicense
  };
};

export default startGetLicense;
